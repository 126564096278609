@charset 'UTF-8';

.footer-pane {
    padding-bottom: 1em;
    background: $bg--gray;

    .footer-link {
        padding: 0 1.5em;

        &.copyright {
            padding-right: 0;
            color: $dark-gray;
        }
    }

    .footer-site-name {
        padding: 1.5em 0 1em;
        font-size: 14px;
        font-weight: bold;
        text-transform: uppercase;
    }
}

.footer,
.footer-pane, {
    height: 89px;
}
