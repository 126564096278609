.SidebarHeaderDropdownButton {
    position: relative;
    display: flex;
    align-items: center;

    .header__info {
        @include flex-grow(1);

        position: relative;
        width: 100%;
        padding: 3px 15px 0 8px;
        color: $white;

        .team__name {
            overflow: hidden;
            font-size: 14px;
            font-weight: 600;
            text-decoration: none;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .user__name {
            font-weight: 400;
        }
    }

    .unread-badge {
        position: absolute;
        right: 2px;
        bottom: 2px;
        width: 8px;
        height: 8px;
        background: #f74343;
        border-radius: 50%;
    }

    .unread-badge-addon {
        position: absolute;
        top: 8px;
        right: 4px;
        width: 12px;
        height: 12px;
        background-color: var(--sidebar-header-bg);
        border-radius: 32px;
    }

    .menu-icon {
        @include opacity(0.8);

        fill: $white;
    }

    &:hover {
        .user__name,
        .menu-icon {
            @include opacity(1);
        }
    }
}
