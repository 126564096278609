@import 'utils/variables';

.contact-us {
    display: block;
    padding: 12px 20px;
    border: 1px solid var(--sys-button-bg);
    margin-top: 15px;
    margin-left: 10px;
    background-color: var(--sys-center-channel-bg);
    border-radius: 4px;
    color: var(--sys-button-bg);

    span {
        font-size: 14px;
        font-weight: 600;
        line-height: 14px;
    }
}
