@charset 'UTF-8';

span.greyed-out {
    @include opacity(0.4);
}

.row-content {
    @include opacity(0.8);
}

.groups-list-no-padding {
    div.group-row {
        height: 40px;
        padding-left: 20px;
    }

    div.groups-list--header {
        div.group-name.adjusted {
            margin-left: 20px;
        }

        div.group-description.adjusted {
            margin-right: 22px;
        }

        div.group-description.group-description-adjusted {
            margin-right: 22px;
        }

        div.group-name.group-name-adjusted {
            margin-left: 22px;
        }
    }
}

.help-text-small.help-text-no-padding {
    margin-top: 0;
}

.help-text-small.help-text-single-line {
    height: 17px;
}

.team-descr-list-column {
    max-width: 440px;
}

.large-team-image-col {
    max-width: 129px;
    padding-right: 20px;

    .TeamIcon {
        margin-right: 12px;
        background: rgba(0, 0, 0, 0.12);
    }
}

.team-desc-col,
.channel-desc-col {
    line-height: 20px;
}

.center-row > div {
    display: inline-block;
    float: none;
    vertical-align: middle;
}

.row-bottom-padding {
    padding-bottom: 12px;
}

.csvDomains {
    padding-bottom: 4px;

    span {
        font-size: 12px;
        font-weight: 600;
        line-height: 17px;
    }
}

.help-text-small {
    max-width: 668px;
    font-size: 12px;
    line-height: 17px;
    opacity: 0.6;
}

#teams,
#team_profile,
#channels,
#channel_profile {
    margin-top: 0;
}
