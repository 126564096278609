@charset 'UTF-8';

.infinite-scroll {
    width: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
}

.infinite-scroll:hover {
    overflow-y: scroll; // for Firefox and browsers that doesn't support overflow-y:overlay property
    overflow-y: overlay;
}
