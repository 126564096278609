@charset 'UTF-8';

html,
body {
    height: 100%;
}

body {
    position: relative;
    width: 100%;
    background: $bg--gray;

    &.announcement-bar--fixed {
        padding-top: $announcement-bar-height;
    }

    &.sticky {
        .container-fluid {
            overflow: auto;
            -webkit-overflow-scrolling: touch;
        }
    }
}

*:focus {
    outline: none;
}

.sticky {
    background: $white;

    > .channel-view {
        overflow: auto;
        height: 100vh;
        padding: 0 15px;
    }

    .inner-wrap {
        width: 100%;

        > .row {
            &.content {
                min-height: calc(100vh - 89px);

                @media (max-width: 768px) {
                    min-height: calc(100vh - 187px);
                }
            }
        }
    }
}

.help-page {
    overflow: hidden;
    padding: 3em 0;
}

.inner-wrap {
    height: 100%;

    &.sticky {
        overflow: auto;
    }

    > .row {
        &.main {
            position: relative;
            height: 100%;
        }
    }
}

.container-fluid {
    @include legacy-pie-clearfix;

    position: relative;
    height: 100%;
}

.channel-view {
    @include clearfix;

    position: relative;
    height: 100%;

    #root {
        display: flex;
        flex-direction: column;
    }
}

#channel_view.channel-view {
    background: var(--center-channel-bg);
}

img {
    max-width: 100%;
}
