
@charset "UTF-8";

.emojisprite-preview {
    width: 66px;
    max-width: none;
    height: 66px;
    padding: 0 10px 0 0;
    background-repeat: no-repeat;
    cursor: pointer;
    -moz-transform: scale(0.55);
    transform-origin: 0 0;
    // Using zoom for now as it results in less blurry emojis on Chrome - MM-34178
    zoom: 0.55;
}

.emojisprite {
    width: 66px;
    max-width: none;
    height: 66px;
    background-repeat: no-repeat;
    border-radius: 18px;
    cursor: pointer;
    -moz-transform: scale(0.35);
    zoom: 0.35;
}

.emojisprite-loading {
    width: 66px;
    max-width: none;
    height: 66px;
    background-image: none !important;
    background-repeat: no-repeat;
    border-radius: 18px;
    cursor: pointer;
    -moz-transform: scale(0.35);
    zoom: 0.35;
}

.emoji-category-recent { background-image: url('images/emoji-sheets/apple-sheet.png'); }
.emoji-category-smileys-emotion { background-image: url('images/emoji-sheets/apple-sheet.png'); }
.emoji-category-people-body { background-image: url('images/emoji-sheets/apple-sheet.png'); }
.emoji-category-animals-nature { background-image: url('images/emoji-sheets/apple-sheet.png'); }
.emoji-category-food-drink { background-image: url('images/emoji-sheets/apple-sheet.png'); }
.emoji-category-travel-places { background-image: url('images/emoji-sheets/apple-sheet.png'); }
.emoji-category-activities { background-image: url('images/emoji-sheets/apple-sheet.png'); }
.emoji-category-objects { background-image: url('images/emoji-sheets/apple-sheet.png'); }
.emoji-category-symbols { background-image: url('images/emoji-sheets/apple-sheet.png'); }
.emoji-category-flags { background-image: url('images/emoji-sheets/apple-sheet.png'); }
.emoji-1f600 { background-position: -1980px -2112px; }
.emoji-1f603 { background-position: -1980px -2310px; }
.emoji-1f604 { background-position: -1980px -2376px; }
.emoji-1f601 { background-position: -1980px -2178px; }
.emoji-1f606 { background-position: -1980px -2508px; }
.emoji-1f605 { background-position: -1980px -2442px; }
.emoji-1f923 { background-position: -2508px -1254px; }
.emoji-1f602 { background-position: -1980px -2244px; }
.emoji-1f642 { background-position: -2046px -2640px; }
.emoji-1f643 { background-position: -2046px -2706px; }
.emoji-1f609 { background-position: -1980px -2706px; }
.emoji-1f60a { background-position: -1980px -2772px; }
.emoji-1f607 { background-position: -1980px -2574px; }
.emoji-1f970 { background-position: -2772px -858px; }
.emoji-1f60d { background-position: -1980px -2970px; }
.emoji-1f929 { background-position: -2508px -2772px; }
.emoji-1f618 { background-position: -1980px -3696px; }
.emoji-1f617 { background-position: -1980px -3630px; }
.emoji-263a-fe0f { background-position: -3564px -1452px; }
.emoji-1f61a { background-position: -2046px -0; }
.emoji-1f619 { background-position: -1980px -3762px; }
.emoji-1f972 { background-position: -2772px -990px; }
.emoji-1f60b { background-position: -1980px -2838px; }
.emoji-1f61b { background-position: -2046px -66px; }
.emoji-1f61c { background-position: -2046px -132px; }
.emoji-1f92a { background-position: -2508px -2838px; }
.emoji-1f61d { background-position: -2046px -198px; }
.emoji-1f911 { background-position: -2442px -1584px; }
.emoji-1f917 { background-position: -2442px -1980px; }
.emoji-1f92d { background-position: -2508px -3036px; }
.emoji-1f92b { background-position: -2508px -2904px; }
.emoji-1f914 { background-position: -2442px -1782px; }
.emoji-1f910 { background-position: -2442px -1518px; }
.emoji-1f928 { background-position: -2508px -2706px; }
.emoji-1f610 { background-position: -1980px -3168px; }
.emoji-1f611 { background-position: -1980px -3234px; }
.emoji-1f636 { background-position: -2046px -1848px; }
.emoji-1f60f { background-position: -1980px -3102px; }
.emoji-1f612 { background-position: -1980px -3300px; }
.emoji-1f644 { background-position: -2046px -2772px; }
.emoji-1f62c { background-position: -2046px -1188px; }
.emoji-1f925 { background-position: -2508px -1386px; }
.emoji-1f60c { background-position: -1980px -2904px; }
.emoji-1f614 { background-position: -1980px -3432px; }
.emoji-1f62a { background-position: -2046px -1056px; }
.emoji-1f924 { background-position: -2508px -1320px; }
.emoji-1f634 { background-position: -2046px -1716px; }
.emoji-1f637 { background-position: -2046px -1914px; }
.emoji-1f912 { background-position: -2442px -1650px; }
.emoji-1f915 { background-position: -2442px -1848px; }
.emoji-1f922 { background-position: -2508px -1188px; }
.emoji-1f92e { background-position: -2508px -3102px; }
.emoji-1f927 { background-position: -2508px -2640px; }
.emoji-1f975 { background-position: -2772px -1188px; }
.emoji-1f976 { background-position: -2772px -1254px; }
.emoji-1f974 { background-position: -2772px -1122px; }
.emoji-1f635 { background-position: -2046px -1782px; }
.emoji-1f92f { background-position: -2508px -3168px; }
.emoji-1f920 { background-position: -2508px -1056px; }
.emoji-1f973 { background-position: -2772px -1056px; }
.emoji-1f978 { background-position: -2772px -1716px; }
.emoji-1f60e { background-position: -1980px -3036px; }
.emoji-1f913 { background-position: -2442px -1716px; }
.emoji-1f9d0 { background-position: -2970px -2244px; }
.emoji-1f615 { background-position: -1980px -3498px; }
.emoji-1f61f { background-position: -2046px -330px; }
.emoji-1f641 { background-position: -2046px -2574px; }
.emoji-2639-fe0f { background-position: -3564px -1386px; }
.emoji-1f62e { background-position: -2046px -1320px; }
.emoji-1f62f { background-position: -2046px -1386px; }
.emoji-1f632 { background-position: -2046px -1584px; }
.emoji-1f633 { background-position: -2046px -1650px; }
.emoji-1f97a { background-position: -2772px -1782px; }
.emoji-1f626 { background-position: -2046px -792px; }
.emoji-1f627 { background-position: -2046px -858px; }
.emoji-1f628 { background-position: -2046px -924px; }
.emoji-1f630 { background-position: -2046px -1452px; }
.emoji-1f625 { background-position: -2046px -726px; }
.emoji-1f622 { background-position: -2046px -528px; }
.emoji-1f62d { background-position: -2046px -1254px; }
.emoji-1f631 { background-position: -2046px -1518px; }
.emoji-1f616 { background-position: -1980px -3564px; }
.emoji-1f623 { background-position: -2046px -594px; }
.emoji-1f61e { background-position: -2046px -264px; }
.emoji-1f613 { background-position: -1980px -3366px; }
.emoji-1f629 { background-position: -2046px -990px; }
.emoji-1f62b { background-position: -2046px -1122px; }
.emoji-1f971 { background-position: -2772px -924px; }
.emoji-1f624 { background-position: -2046px -660px; }
.emoji-1f621 { background-position: -2046px -462px; }
.emoji-1f620 { background-position: -2046px -396px; }
.emoji-1f92c { background-position: -2508px -2970px; }
.emoji-1f608 { background-position: -1980px -2640px; }
.emoji-1f47f { background-position: -1518px -3234px; }
.emoji-1f480 { background-position: -1518px -3300px; }
.emoji-2620-fe0f { background-position: -3564px -858px; }
.emoji-1f4a9 { background-position: -1650px -3498px; }
.emoji-1f921 { background-position: -2508px -1122px; }
.emoji-1f479 { background-position: -1518px -2508px; }
.emoji-1f47a { background-position: -1518px -2574px; }
.emoji-1f47b { background-position: -1518px -2640px; }
.emoji-1f47d { background-position: -1518px -3102px; }
.emoji-1f47e { background-position: -1518px -3168px; }
.emoji-1f916 { background-position: -2442px -1914px; }
.emoji-1f63a { background-position: -2046px -2112px; }
.emoji-1f638 { background-position: -2046px -1980px; }
.emoji-1f639 { background-position: -2046px -2046px; }
.emoji-1f63b { background-position: -2046px -2178px; }
.emoji-1f63c { background-position: -2046px -2244px; }
.emoji-1f63d { background-position: -2046px -2310px; }
.emoji-1f640 { background-position: -2046px -2508px; }
.emoji-1f63f { background-position: -2046px -2442px; }
.emoji-1f63e { background-position: -2046px -2376px; }
.emoji-1f648 { background-position: -2112px -2574px; }
.emoji-1f649 { background-position: -2112px -2640px; }
.emoji-1f64a { background-position: -2112px -2706px; }
.emoji-1f48b { background-position: -1650px -1518px; }
.emoji-1f48c { background-position: -1650px -1584px; }
.emoji-1f498 { background-position: -1650px -2376px; }
.emoji-1f49d { background-position: -1650px -2706px; }
.emoji-1f496 { background-position: -1650px -2244px; }
.emoji-1f497 { background-position: -1650px -2310px; }
.emoji-1f493 { background-position: -1650px -2046px; }
.emoji-1f49e { background-position: -1650px -2772px; }
.emoji-1f495 { background-position: -1650px -2178px; }
.emoji-1f49f { background-position: -1650px -2838px; }
.emoji-2763-fe0f { background-position: -3696px -2046px; }
.emoji-1f494 { background-position: -1650px -2112px; }
.emoji-2764-fe0f { background-position: -3696px -2112px; }
.emoji-1f9e1 { background-position: -3366px -2904px; }
.emoji-1f49b { background-position: -1650px -2574px; }
.emoji-1f49a { background-position: -1650px -2508px; }
.emoji-1f499 { background-position: -1650px -2442px; }
.emoji-1f49c { background-position: -1650px -2640px; }
.emoji-1f90e { background-position: -2442px -1056px; }
.emoji-1f5a4 { background-position: -1980px -396px; }
.emoji-1f90d { background-position: -2442px -990px; }
.emoji-1f4af { background-position: -1716px -396px; }
.emoji-1f4a2 { background-position: -1650px -3036px; }
.emoji-1f4a5 { background-position: -1650px -3234px; }
.emoji-1f4ab { background-position: -1716px -132px; }
.emoji-1f4a6 { background-position: -1650px -3300px; }
.emoji-1f4a8 { background-position: -1650px -3432px; }
.emoji-1f573-fe0f { background-position: -1914px -396px; }
.emoji-1f4a3 { background-position: -1650px -3102px; }
.emoji-1f4ac { background-position: -1716px -198px; }
.emoji-1f441-fe0f-200d-1f5e8-fe0f { background-position: -792px -1848px; }
.emoji-1f5e8-fe0f { background-position: -1980px -1518px; }
.emoji-1f5ef-fe0f { background-position: -1980px -1584px; }
.emoji-1f4ad { background-position: -1716px -264px; }
.emoji-1f4a4 { background-position: -1650px -3168px; }
.emoji-1f44b { background-position: -858px -1056px; }
.emoji-1f91a { background-position: -2442px -2838px; }
.emoji-1f590-fe0f { background-position: -1914px -3036px; }
.emoji-270b { background-position: -3630px -3564px; }
.emoji-1f596 { background-position: -1980px -0; }
.emoji-1f44c { background-position: -858px -1452px; }
.emoji-1f90c { background-position: -2442px -594px; }
.emoji-1f90f { background-position: -2442px -1122px; }
.emoji-270c-fe0f { background-position: -3696px -132px; }
.emoji-1f91e { background-position: -2508px -264px; }
.emoji-1f91f { background-position: -2508px -660px; }
.emoji-1f918 { background-position: -2442px -2046px; }
.emoji-1f919 { background-position: -2442px -2442px; }
.emoji-1f448 { background-position: -792px -3696px; }
.emoji-1f449 { background-position: -858px -264px; }
.emoji-1f446 { background-position: -792px -2904px; }
.emoji-1f595 { background-position: -1914px -3432px; }
.emoji-1f447 { background-position: -792px -3300px; }
.emoji-261d-fe0f { background-position: -3564px -462px; }
.emoji-1f44d { background-position: -858px -1848px; }
.emoji-1f44e { background-position: -858px -2244px; }
.emoji-270a { background-position: -3630px -3168px; }
.emoji-1f44a { background-position: -858px -660px; }
.emoji-1f91b { background-position: -2442px -3234px; }
.emoji-1f91c { background-position: -2442px -3630px; }
.emoji-1f44f { background-position: -858px -2640px; }
.emoji-1f64c { background-position: -2178px -132px; }
.emoji-1f450 { background-position: -858px -3036px; }
.emoji-1f932 { background-position: -2574px -198px; }
.emoji-1f91d { background-position: -2508px -198px; }
.emoji-1f64f { background-position: -2178px -2904px; }
.emoji-270d-fe0f { background-position: -3696px -528px; }
.emoji-1f485 { background-position: -1584px -2376px; }
.emoji-1f933 { background-position: -2574px -594px; }
.emoji-1f4aa { background-position: -1650px -3564px; }
.emoji-1f9be { background-position: -2904px -1584px; }
.emoji-1f9bf { background-position: -2904px -1650px; }
.emoji-1f9b5 { background-position: -2838px -1584px; }
.emoji-1f9b6 { background-position: -2838px -1980px; }
.emoji-1f442 { background-position: -792px -1980px; }
.emoji-1f9bb { background-position: -2904px -1056px; }
.emoji-1f443 { background-position: -792px -2376px; }
.emoji-1f9e0 { background-position: -3366px -2838px; }
.emoji-1fac0 { background-position: -3498px -396px; }
.emoji-1fac1 { background-position: -3498px -462px; }
.emoji-1f9b7 { background-position: -2838px -2376px; }
.emoji-1f9b4 { background-position: -2838px -1518px; }
.emoji-1f440 { background-position: -792px -1782px; }
.emoji-1f441-fe0f { background-position: -792px -1914px; }
.emoji-1f445 { background-position: -792px -2838px; }
.emoji-1f444 { background-position: -792px -2772px; }
.emoji-1f476 { background-position: -1518px -528px; }
.emoji-1f9d2 { background-position: -3168px -2838px; }
.emoji-1f466 { background-position: -924px -990px; }
.emoji-1f467 { background-position: -924px -1386px; }
.emoji-1f9d1 { background-position: -3168px -2442px; }
.emoji-1f471 { background-position: -1452px -1584px; }
.emoji-1f468 { background-position: -1122px -924px; }
.emoji-1f9d4 { background-position: -3168px -3630px; }
.emoji-1f468-200d-1f9b0 { background-position: -1056px -1056px; }
.emoji-1f468-200d-1f9b1 { background-position: -1056px -1452px; }
.emoji-1f468-200d-1f9b3 { background-position: -1056px -2244px; }
.emoji-1f468-200d-1f9b2 { background-position: -1056px -1848px; }
.emoji-1f469 { background-position: -1320px -264px; }
.emoji-1f469-200d-1f9b0 { background-position: -1254px -264px; }
.emoji-1f9d1-200d-1f9b0 { background-position: -3102px -2706px; }
.emoji-1f469-200d-1f9b1 { background-position: -1254px -660px; }
.emoji-1f9d1-200d-1f9b1 { background-position: -3102px -3102px; }
.emoji-1f469-200d-1f9b3 { background-position: -1254px -1452px; }
.emoji-1f9d1-200d-1f9b3 { background-position: -3168px -66px; }
.emoji-1f469-200d-1f9b2 { background-position: -1254px -1056px; }
.emoji-1f9d1-200d-1f9b2 { background-position: -3102px -3498px; }
.emoji-1f471-200d-2640-fe0f { background-position: -1452px -792px; }
.emoji-1f471-200d-2642-fe0f { background-position: -1452px -1188px; }
.emoji-1f9d3 { background-position: -3168px -3234px; }
.emoji-1f474 { background-position: -1452px -3564px; }
.emoji-1f475 { background-position: -1518px -132px; }
.emoji-1f64d { background-position: -2178px -1320px; }
.emoji-1f64d-200d-2642-fe0f { background-position: -2178px -924px; }
.emoji-1f64d-200d-2640-fe0f { background-position: -2178px -528px; }
.emoji-1f64e { background-position: -2178px -2508px; }
.emoji-1f64e-200d-2642-fe0f { background-position: -2178px -2112px; }
.emoji-1f64e-200d-2640-fe0f { background-position: -2178px -1716px; }
.emoji-1f645 { background-position: -2046px -3630px; }
.emoji-1f645-200d-2642-fe0f { background-position: -2046px -3234px; }
.emoji-1f645-200d-2640-fe0f { background-position: -2046px -2838px; }
.emoji-1f646 { background-position: -2112px -990px; }
.emoji-1f646-200d-2642-fe0f { background-position: -2112px -594px; }
.emoji-1f646-200d-2640-fe0f { background-position: -2112px -198px; }
.emoji-1f481 { background-position: -1584px -330px; }
.emoji-1f481-200d-2642-fe0f { background-position: -1518px -3762px; }
.emoji-1f481-200d-2640-fe0f { background-position: -1518px -3366px; }
.emoji-1f64b { background-position: -2112px -3564px; }
.emoji-1f64b-200d-2642-fe0f { background-position: -2112px -3168px; }
.emoji-1f64b-200d-2640-fe0f { background-position: -2112px -2772px; }
.emoji-1f9cf { background-position: -2970px -1848px; }
.emoji-1f9cf-200d-2642-fe0f { background-position: -2970px -1452px; }
.emoji-1f9cf-200d-2640-fe0f { background-position: -2970px -1056px; }
.emoji-1f647 { background-position: -2112px -2178px; }
.emoji-1f647-200d-2642-fe0f { background-position: -2112px -1782px; }
.emoji-1f647-200d-2640-fe0f { background-position: -2112px -1386px; }
.emoji-1f926 { background-position: -2508px -2244px; }
.emoji-1f926-200d-2642-fe0f { background-position: -2508px -1848px; }
.emoji-1f926-200d-2640-fe0f { background-position: -2508px -1452px; }
.emoji-1f937 { background-position: -2574px -3762px; }
.emoji-1f937-200d-2642-fe0f { background-position: -2574px -3366px; }
.emoji-1f937-200d-2640-fe0f { background-position: -2574px -2970px; }
.emoji-1f9d1-200d-2695-fe0f { background-position: -3168px -1254px; }
.emoji-1f468-200d-2695-fe0f { background-position: -1056px -3432px; }
.emoji-1f469-200d-2695-fe0f { background-position: -1254px -2640px; }
.emoji-1f9d1-200d-1f393 { background-position: -3036px -66px; }
.emoji-1f468-200d-1f393 { background-position: -924px -2970px; }
.emoji-1f469-200d-1f393 { background-position: -1122px -2508px; }
.emoji-1f9d1-200d-1f3eb { background-position: -3036px -1254px; }
.emoji-1f468-200d-1f3eb { background-position: -990px -330px; }
.emoji-1f469-200d-1f3eb { background-position: -1122px -3696px; }
.emoji-1f9d1-200d-2696-fe0f { background-position: -3168px -1650px; }
.emoji-1f468-200d-2696-fe0f { background-position: -1122px -0; }
.emoji-1f469-200d-2696-fe0f { background-position: -1254px -3036px; }
.emoji-1f9d1-200d-1f33e { background-position: -2970px -2310px; }
.emoji-1f468-200d-1f33e { background-position: -924px -1782px; }
.emoji-1f469-200d-1f33e { background-position: -1122px -1320px; }
.emoji-1f9d1-200d-1f373 { background-position: -2970px -2706px; }
.emoji-1f468-200d-1f373 { background-position: -924px -2178px; }
.emoji-1f469-200d-1f373 { background-position: -1122px -1716px; }
.emoji-1f9d1-200d-1f527 { background-position: -3036px -2838px; }
.emoji-1f468-200d-1f527 { background-position: -990px -2904px; }
.emoji-1f469-200d-1f527 { background-position: -1188px -2112px; }
.emoji-1f9d1-200d-1f3ed { background-position: -3036px -1650px; }
.emoji-1f468-200d-1f3ed { background-position: -990px -726px; }
.emoji-1f469-200d-1f3ed { background-position: -1188px -264px; }
.emoji-1f9d1-200d-1f4bc { background-position: -3036px -2442px; }
.emoji-1f468-200d-1f4bc { background-position: -990px -2508px; }
.emoji-1f469-200d-1f4bc { background-position: -1188px -1716px; }
.emoji-1f9d1-200d-1f52c { background-position: -3036px -3234px; }
.emoji-1f468-200d-1f52c { background-position: -990px -3300px; }
.emoji-1f469-200d-1f52c { background-position: -1188px -2508px; }
.emoji-1f9d1-200d-1f4bb { background-position: -3036px -2046px; }
.emoji-1f468-200d-1f4bb { background-position: -990px -2112px; }
.emoji-1f469-200d-1f4bb { background-position: -1188px -1320px; }
.emoji-1f9d1-200d-1f3a4 { background-position: -3036px -462px; }
.emoji-1f468-200d-1f3a4 { background-position: -924px -3366px; }
.emoji-1f469-200d-1f3a4 { background-position: -1122px -2904px; }
.emoji-1f9d1-200d-1f3a8 { background-position: -3036px -858px; }
.emoji-1f468-200d-1f3a8 { background-position: -924px -3762px; }
.emoji-1f469-200d-1f3a8 { background-position: -1122px -3300px; }
.emoji-1f9d1-200d-2708-fe0f { background-position: -3168px -2046px; }
.emoji-1f468-200d-2708-fe0f { background-position: -1122px -396px; }
.emoji-1f469-200d-2708-fe0f { background-position: -1254px -3432px; }
.emoji-1f9d1-200d-1f680 { background-position: -3036px -3630px; }
.emoji-1f468-200d-1f680 { background-position: -990px -3696px; }
.emoji-1f469-200d-1f680 { background-position: -1188px -2904px; }
.emoji-1f9d1-200d-1f692 { background-position: -3102px -198px; }
.emoji-1f468-200d-1f692 { background-position: -1056px -264px; }
.emoji-1f469-200d-1f692 { background-position: -1188px -3300px; }
.emoji-1f46e { background-position: -1386px -2838px; }
.emoji-1f46e-200d-2642-fe0f { background-position: -1386px -2442px; }
.emoji-1f46e-200d-2640-fe0f { background-position: -1386px -2046px; }
.emoji-1f575-fe0f { background-position: -1914px -1650px; }
.emoji-1f575-fe0f-200d-2642-fe0f { background-position: -1914px -1254px; }
.emoji-1f575-fe0f-200d-2640-fe0f { background-position: -1914px -858px; }
.emoji-1f482 { background-position: -1584px -1518px; }
.emoji-1f482-200d-2642-fe0f { background-position: -1584px -1122px; }
.emoji-1f482-200d-2640-fe0f { background-position: -1584px -726px; }
.emoji-1f977 { background-position: -2772px -1320px; }
.emoji-1f477 { background-position: -1518px -1716px; }
.emoji-1f477-200d-2642-fe0f { background-position: -1518px -1320px; }
.emoji-1f477-200d-2640-fe0f { background-position: -1518px -924px; }
.emoji-1f934 { background-position: -2574px -990px; }
.emoji-1f478 { background-position: -1518px -2112px; }
.emoji-1f473 { background-position: -1452px -3168px; }
.emoji-1f473-200d-2642-fe0f { background-position: -1452px -2772px; }
.emoji-1f473-200d-2640-fe0f { background-position: -1452px -2376px; }
.emoji-1f472 { background-position: -1452px -1980px; }
.emoji-1f9d5 { background-position: -3234px -198px; }
.emoji-1f935 { background-position: -2574px -2178px; }
.emoji-1f935-200d-2642-fe0f { background-position: -2574px -1782px; }
.emoji-1f935-200d-2640-fe0f { background-position: -2574px -1386px; }
.emoji-1f470 { background-position: -1452px -396px; }
.emoji-1f470-200d-2642-fe0f { background-position: -1452px -0; }
.emoji-1f470-200d-2640-fe0f { background-position: -1386px -3432px; }
.emoji-1f930 { background-position: -2508px -3234px; }
.emoji-1f931 { background-position: -2508px -3630px; }
.emoji-1f469-200d-1f37c { background-position: -1122px -2112px; }
.emoji-1f468-200d-1f37c { background-position: -924px -2574px; }
.emoji-1f9d1-200d-1f37c { background-position: -2970px -3102px; }
.emoji-1f47c { background-position: -1518px -2706px; }
.emoji-1f385 { background-position: -462px -1914px; }
.emoji-1f936 { background-position: -2574px -2574px; }
.emoji-1f9d1-200d-1f384 { background-position: -2970px -3498px; }
.emoji-1f9b8 { background-position: -2838px -3234px; }
.emoji-1f9b8-200d-2642-fe0f { background-position: -2838px -2838px; }
.emoji-1f9b8-200d-2640-fe0f { background-position: -2838px -2442px; }
.emoji-1f9b9 { background-position: -2904px -594px; }
.emoji-1f9b9-200d-2642-fe0f { background-position: -2904px -198px; }
.emoji-1f9b9-200d-2640-fe0f { background-position: -2838px -3630px; }
.emoji-1f9d9 { background-position: -3300px -1122px; }
.emoji-1f9d9-200d-2642-fe0f { background-position: -3300px -726px; }
.emoji-1f9d9-200d-2640-fe0f { background-position: -3300px -330px; }
.emoji-1f9da { background-position: -3300px -2310px; }
.emoji-1f9da-200d-2642-fe0f { background-position: -3300px -1914px; }
.emoji-1f9da-200d-2640-fe0f { background-position: -3300px -1518px; }
.emoji-1f9db { background-position: -3300px -3498px; }
.emoji-1f9db-200d-2642-fe0f { background-position: -3300px -3102px; }
.emoji-1f9db-200d-2640-fe0f { background-position: -3300px -2706px; }
.emoji-1f9dc { background-position: -3366px -858px; }
.emoji-1f9dc-200d-2642-fe0f { background-position: -3366px -462px; }
.emoji-1f9dc-200d-2640-fe0f { background-position: -3366px -66px; }
.emoji-1f9dd { background-position: -3366px -2046px; }
.emoji-1f9dd-200d-2642-fe0f { background-position: -3366px -1650px; }
.emoji-1f9dd-200d-2640-fe0f { background-position: -3366px -1254px; }
.emoji-1f9de { background-position: -3366px -2574px; }
.emoji-1f9de-200d-2642-fe0f { background-position: -3366px -2508px; }
.emoji-1f9de-200d-2640-fe0f { background-position: -3366px -2442px; }
.emoji-1f9df { background-position: -3366px -2772px; }
.emoji-1f9df-200d-2642-fe0f { background-position: -3366px -2706px; }
.emoji-1f9df-200d-2640-fe0f { background-position: -3366px -2640px; }
.emoji-1f486 { background-position: -1584px -3564px; }
.emoji-1f486-200d-2642-fe0f { background-position: -1584px -3168px; }
.emoji-1f486-200d-2640-fe0f { background-position: -1584px -2772px; }
.emoji-1f487 { background-position: -1650px -924px; }
.emoji-1f487-200d-2642-fe0f { background-position: -1650px -528px; }
.emoji-1f487-200d-2640-fe0f { background-position: -1650px -132px; }
.emoji-1f6b6 { background-position: -2310px -3366px; }
.emoji-1f6b6-200d-2642-fe0f { background-position: -2310px -2970px; }
.emoji-1f6b6-200d-2640-fe0f { background-position: -2310px -2574px; }
.emoji-1f9cd { background-position: -2904px -3300px; }
.emoji-1f9cd-200d-2642-fe0f { background-position: -2904px -2904px; }
.emoji-1f9cd-200d-2640-fe0f { background-position: -2904px -2508px; }
.emoji-1f9ce { background-position: -2970px -660px; }
.emoji-1f9ce-200d-2642-fe0f { background-position: -2970px -264px; }
.emoji-1f9ce-200d-2640-fe0f { background-position: -2904px -3696px; }
.emoji-1f9d1-200d-1f9af { background-position: -3102px -2310px; }
.emoji-1f468-200d-1f9af { background-position: -1056px -660px; }
.emoji-1f469-200d-1f9af { background-position: -1188px -3696px; }
.emoji-1f9d1-200d-1f9bc { background-position: -3168px -462px; }
.emoji-1f468-200d-1f9bc { background-position: -1056px -2640px; }
.emoji-1f469-200d-1f9bc { background-position: -1254px -1848px; }
.emoji-1f9d1-200d-1f9bd { background-position: -3168px -858px; }
.emoji-1f468-200d-1f9bd { background-position: -1056px -3036px; }
.emoji-1f469-200d-1f9bd { background-position: -1254px -2244px; }
.emoji-1f3c3 { background-position: -528px -3300px; }
.emoji-1f3c3-200d-2642-fe0f { background-position: -528px -2904px; }
.emoji-1f3c3-200d-2640-fe0f { background-position: -528px -2508px; }
.emoji-1f483 { background-position: -1584px -1914px; }
.emoji-1f57a { background-position: -1914px -2310px; }
.emoji-1f574-fe0f { background-position: -1914px -462px; }
.emoji-1f46f { background-position: -1386px -3366px; }
.emoji-1f46f-200d-2642-fe0f { background-position: -1386px -3300px; }
.emoji-1f46f-200d-2640-fe0f { background-position: -1386px -3234px; }
.emoji-1f9d6 { background-position: -3234px -1386px; }
.emoji-1f9d6-200d-2642-fe0f { background-position: -3234px -990px; }
.emoji-1f9d6-200d-2640-fe0f { background-position: -3234px -594px; }
.emoji-1f9d7 { background-position: -3234px -2574px; }
.emoji-1f9d7-200d-2642-fe0f { background-position: -3234px -2178px; }
.emoji-1f9d7-200d-2640-fe0f { background-position: -3234px -1782px; }
.emoji-1f93a { background-position: -2640px -2706px; }
.emoji-1f3c7 { background-position: -594px -1188px; }
.emoji-26f7-fe0f { background-position: -3630px -1452px; }
.emoji-1f3c2 { background-position: -528px -2112px; }
.emoji-1f3cc-fe0f { background-position: -660px -1056px; }
.emoji-1f3cc-fe0f-200d-2642-fe0f { background-position: -660px -660px; }
.emoji-1f3cc-fe0f-200d-2640-fe0f { background-position: -660px -264px; }
.emoji-1f3c4 { background-position: -594px -660px; }
.emoji-1f3c4-200d-2642-fe0f { background-position: -594px -264px; }
.emoji-1f3c4-200d-2640-fe0f { background-position: -528px -3696px; }
.emoji-1f6a3 { background-position: -2244px -2574px; }
.emoji-1f6a3-200d-2642-fe0f { background-position: -2244px -2178px; }
.emoji-1f6a3-200d-2640-fe0f { background-position: -2244px -1782px; }
.emoji-1f3ca { background-position: -594px -2508px; }
.emoji-1f3ca-200d-2642-fe0f { background-position: -594px -2112px; }
.emoji-1f3ca-200d-2640-fe0f { background-position: -594px -1716px; }
.emoji-26f9-fe0f { background-position: -3630px -2376px; }
.emoji-26f9-fe0f-200d-2642-fe0f { background-position: -3630px -1980px; }
.emoji-26f9-fe0f-200d-2640-fe0f { background-position: -3630px -1584px; }
.emoji-1f3cb-fe0f { background-position: -594px -3696px; }
.emoji-1f3cb-fe0f-200d-2642-fe0f { background-position: -594px -3300px; }
.emoji-1f3cb-fe0f-200d-2640-fe0f { background-position: -594px -2904px; }
.emoji-1f6b4 { background-position: -2310px -990px; }
.emoji-1f6b4-200d-2642-fe0f { background-position: -2310px -594px; }
.emoji-1f6b4-200d-2640-fe0f { background-position: -2310px -198px; }
.emoji-1f6b5 { background-position: -2310px -2178px; }
.emoji-1f6b5-200d-2642-fe0f { background-position: -2310px -1782px; }
.emoji-1f6b5-200d-2640-fe0f { background-position: -2310px -1386px; }
.emoji-1f938 { background-position: -2640px -1122px; }
.emoji-1f938-200d-2642-fe0f { background-position: -2640px -726px; }
.emoji-1f938-200d-2640-fe0f { background-position: -2640px -330px; }
.emoji-1f93c { background-position: -2640px -2904px; }
.emoji-1f93c-200d-2642-fe0f { background-position: -2640px -2838px; }
.emoji-1f93c-200d-2640-fe0f { background-position: -2640px -2772px; }
.emoji-1f93d { background-position: -2640px -3762px; }
.emoji-1f93d-200d-2642-fe0f { background-position: -2640px -3366px; }
.emoji-1f93d-200d-2640-fe0f { background-position: -2640px -2970px; }
.emoji-1f93e { background-position: -2706px -1122px; }
.emoji-1f93e-200d-2642-fe0f { background-position: -2706px -726px; }
.emoji-1f93e-200d-2640-fe0f { background-position: -2706px -330px; }
.emoji-1f939 { background-position: -2640px -2310px; }
.emoji-1f939-200d-2642-fe0f { background-position: -2640px -1914px; }
.emoji-1f939-200d-2640-fe0f { background-position: -2640px -1518px; }
.emoji-1f9d8 { background-position: -3234px -3762px; }
.emoji-1f9d8-200d-2642-fe0f { background-position: -3234px -3366px; }
.emoji-1f9d8-200d-2640-fe0f { background-position: -3234px -2970px; }
.emoji-1f6c0 { background-position: -2376px -528px; }
.emoji-1f6cc { background-position: -2376px -1320px; }
.emoji-1f9d1-200d-1f91d-200d-1f9d1 { background-position: -3102px -594px; }
.emoji-1f46d { background-position: -1386px -330px; }
.emoji-1f46b { background-position: -1320px -726px; }
.emoji-1f46c { background-position: -1320px -2442px; }
.emoji-1f48f { background-position: -1650px -1782px; }
.emoji-1f469-200d-2764-fe0f-200d-1f48b-200d-1f468 { background-position: -1320px -132px; }
.emoji-1f468-200d-2764-fe0f-200d-1f48b-200d-1f468 { background-position: -1122px -858px; }
.emoji-1f469-200d-2764-fe0f-200d-1f48b-200d-1f469 { background-position: -1320px -198px; }
.emoji-1f491 { background-position: -1650px -1914px; }
.emoji-1f469-200d-2764-fe0f-200d-1f468 { background-position: -1320px -0; }
.emoji-1f468-200d-2764-fe0f-200d-1f468 { background-position: -1122px -792px; }
.emoji-1f469-200d-2764-fe0f-200d-1f469 { background-position: -1320px -66px; }
.emoji-1f46a { background-position: -1320px -660px; }
.emoji-1f468-200d-1f469-200d-1f466 { background-position: -990px -1782px; }
.emoji-1f468-200d-1f469-200d-1f467 { background-position: -990px -1914px; }
.emoji-1f468-200d-1f469-200d-1f467-200d-1f466 { background-position: -990px -1980px; }
.emoji-1f468-200d-1f469-200d-1f466-200d-1f466 { background-position: -990px -1848px; }
.emoji-1f468-200d-1f469-200d-1f467-200d-1f467 { background-position: -990px -2046px; }
.emoji-1f468-200d-1f468-200d-1f466 { background-position: -990px -1452px; }
.emoji-1f468-200d-1f468-200d-1f467 { background-position: -990px -1584px; }
.emoji-1f468-200d-1f468-200d-1f467-200d-1f466 { background-position: -990px -1650px; }
.emoji-1f468-200d-1f468-200d-1f466-200d-1f466 { background-position: -990px -1518px; }
.emoji-1f468-200d-1f468-200d-1f467-200d-1f467 { background-position: -990px -1716px; }
.emoji-1f469-200d-1f469-200d-1f466 { background-position: -1188px -990px; }
.emoji-1f469-200d-1f469-200d-1f467 { background-position: -1188px -1122px; }
.emoji-1f469-200d-1f469-200d-1f467-200d-1f466 { background-position: -1188px -1188px; }
.emoji-1f469-200d-1f469-200d-1f466-200d-1f466 { background-position: -1188px -1056px; }
.emoji-1f469-200d-1f469-200d-1f467-200d-1f467 { background-position: -1188px -1254px; }
.emoji-1f468-200d-1f466 { background-position: -990px -1188px; }
.emoji-1f468-200d-1f466-200d-1f466 { background-position: -990px -1122px; }
.emoji-1f468-200d-1f467 { background-position: -990px -1386px; }
.emoji-1f468-200d-1f467-200d-1f466 { background-position: -990px -1254px; }
.emoji-1f468-200d-1f467-200d-1f467 { background-position: -990px -1320px; }
.emoji-1f469-200d-1f466 { background-position: -1188px -726px; }
.emoji-1f469-200d-1f466-200d-1f466 { background-position: -1188px -660px; }
.emoji-1f469-200d-1f467 { background-position: -1188px -924px; }
.emoji-1f469-200d-1f467-200d-1f466 { background-position: -1188px -792px; }
.emoji-1f469-200d-1f467-200d-1f467 { background-position: -1188px -858px; }
.emoji-1f5e3-fe0f { background-position: -1980px -1452px; }
.emoji-1f464 { background-position: -924px -858px; }
.emoji-1f465 { background-position: -924px -924px; }
.emoji-1fac2 { background-position: -3498px -528px; }
.emoji-1f463 { background-position: -924px -792px; }
.emoji-1f3fb { background-position: -726px -858px; }
.emoji-1f3fc { background-position: -726px -924px; }
.emoji-1f3fd { background-position: -726px -990px; }
.emoji-1f3fe { background-position: -726px -1056px; }
.emoji-1f3ff { background-position: -726px -1122px; }
.emoji-1f435 { background-position: -792px -990px; }
.emoji-1f412 { background-position: -726px -2442px; }
.emoji-1f98d { background-position: -2772px -3036px; }
.emoji-1f9a7 { background-position: -2838px -924px; }
.emoji-1f436 { background-position: -792px -1056px; }
.emoji-1f415 { background-position: -726px -2706px; }
.emoji-1f9ae { background-position: -2838px -1386px; }
.emoji-1f415-200d-1f9ba { background-position: -726px -2640px; }
.emoji-1f429 { background-position: -792px -198px; }
.emoji-1f43a { background-position: -792px -1320px; }
.emoji-1f98a { background-position: -2772px -2838px; }
.emoji-1f99d { background-position: -2838px -264px; }
.emoji-1f431 { background-position: -792px -726px; }
.emoji-1f408 { background-position: -726px -1782px; }
.emoji-1f408-200d-2b1b { background-position: -726px -1716px; }
.emoji-1f981 { background-position: -2772px -2244px; }
.emoji-1f42f { background-position: -792px -594px; }
.emoji-1f405 { background-position: -726px -1518px; }
.emoji-1f406 { background-position: -726px -1584px; }
.emoji-1f434 { background-position: -792px -924px; }
.emoji-1f40e { background-position: -726px -2178px; }
.emoji-1f984 { background-position: -2772px -2442px; }
.emoji-1f993 { background-position: -2772px -3432px; }
.emoji-1f98c { background-position: -2772px -2970px; }
.emoji-1f9ac { background-position: -2838px -1254px; }
.emoji-1f42e { background-position: -792px -528px; }
.emoji-1f402 { background-position: -726px -1320px; }
.emoji-1f403 { background-position: -726px -1386px; }
.emoji-1f404 { background-position: -726px -1452px; }
.emoji-1f437 { background-position: -792px -1122px; }
.emoji-1f416 { background-position: -726px -2772px; }
.emoji-1f417 { background-position: -726px -2838px; }
.emoji-1f43d { background-position: -792px -1584px; }
.emoji-1f40f { background-position: -726px -2244px; }
.emoji-1f411 { background-position: -726px -2376px; }
.emoji-1f410 { background-position: -726px -2310px; }
.emoji-1f42a { background-position: -792px -264px; }
.emoji-1f42b { background-position: -792px -330px; }
.emoji-1f999 { background-position: -2838px -0; }
.emoji-1f992 { background-position: -2772px -3366px; }
.emoji-1f418 { background-position: -726px -2904px; }
.emoji-1f9a3 { background-position: -2838px -660px; }
.emoji-1f98f { background-position: -2772px -3168px; }
.emoji-1f99b { background-position: -2838px -132px; }
.emoji-1f42d { background-position: -792px -462px; }
.emoji-1f401 { background-position: -726px -1254px; }
.emoji-1f400 { background-position: -726px -1188px; }
.emoji-1f439 { background-position: -792px -1254px; }
.emoji-1f430 { background-position: -792px -660px; }
.emoji-1f407 { background-position: -726px -1650px; }
.emoji-1f43f-fe0f { background-position: -792px -1716px; }
.emoji-1f9ab { background-position: -2838px -1188px; }
.emoji-1f994 { background-position: -2772px -3498px; }
.emoji-1f987 { background-position: -2772px -2640px; }
.emoji-1f43b { background-position: -792px -1452px; }
.emoji-1f43b-200d-2744-fe0f { background-position: -792px -1386px; }
.emoji-1f428 { background-position: -792px -132px; }
.emoji-1f43c { background-position: -792px -1518px; }
.emoji-1f9a5 { background-position: -2838px -792px; }
.emoji-1f9a6 { background-position: -2838px -858px; }
.emoji-1f9a8 { background-position: -2838px -990px; }
.emoji-1f998 { background-position: -2772px -3762px; }
.emoji-1f9a1 { background-position: -2838px -528px; }
.emoji-1f43e { background-position: -792px -1650px; }
.emoji-1f983 { background-position: -2772px -2376px; }
.emoji-1f414 { background-position: -726px -2574px; }
.emoji-1f413 { background-position: -726px -2508px; }
.emoji-1f423 { background-position: -726px -3630px; }
.emoji-1f424 { background-position: -726px -3696px; }
.emoji-1f425 { background-position: -726px -3762px; }
.emoji-1f426 { background-position: -792px -0; }
.emoji-1f427 { background-position: -792px -66px; }
.emoji-1f54a-fe0f { background-position: -1848px -2178px; }
.emoji-1f985 { background-position: -2772px -2508px; }
.emoji-1f986 { background-position: -2772px -2574px; }
.emoji-1f9a2 { background-position: -2838px -594px; }
.emoji-1f989 { background-position: -2772px -2772px; }
.emoji-1f9a4 { background-position: -2838px -726px; }
.emoji-1fab6 { background-position: -3498px -330px; }
.emoji-1f9a9 { background-position: -2838px -1056px; }
.emoji-1f99a { background-position: -2838px -66px; }
.emoji-1f99c { background-position: -2838px -198px; }
.emoji-1f438 { background-position: -792px -1188px; }
.emoji-1f40a { background-position: -726px -1914px; }
.emoji-1f422 { background-position: -726px -3564px; }
.emoji-1f98e { background-position: -2772px -3102px; }
.emoji-1f40d { background-position: -726px -2112px; }
.emoji-1f432 { background-position: -792px -792px; }
.emoji-1f409 { background-position: -726px -1848px; }
.emoji-1f995 { background-position: -2772px -3564px; }
.emoji-1f996 { background-position: -2772px -3630px; }
.emoji-1f433 { background-position: -792px -858px; }
.emoji-1f40b { background-position: -726px -1980px; }
.emoji-1f42c { background-position: -792px -396px; }
.emoji-1f9ad { background-position: -2838px -1320px; }
.emoji-1f41f { background-position: -726px -3366px; }
.emoji-1f420 { background-position: -726px -3432px; }
.emoji-1f421 { background-position: -726px -3498px; }
.emoji-1f988 { background-position: -2772px -2706px; }
.emoji-1f419 { background-position: -726px -2970px; }
.emoji-1f41a { background-position: -726px -3036px; }
.emoji-1f40c { background-position: -726px -2046px; }
.emoji-1f98b { background-position: -2772px -2904px; }
.emoji-1f41b { background-position: -726px -3102px; }
.emoji-1f41c { background-position: -726px -3168px; }
.emoji-1f41d { background-position: -726px -3234px; }
.emoji-1fab2 { background-position: -3498px -66px; }
.emoji-1f41e { background-position: -726px -3300px; }
.emoji-1f997 { background-position: -2772px -3696px; }
.emoji-1fab3 { background-position: -3498px -132px; }
.emoji-1f577-fe0f { background-position: -1914px -2112px; }
.emoji-1f578-fe0f { background-position: -1914px -2178px; }
.emoji-1f982 { background-position: -2772px -2310px; }
.emoji-1f99f { background-position: -2838px -396px; }
.emoji-1fab0 { background-position: -3432px -3762px; }
.emoji-1fab1 { background-position: -3498px -0; }
.emoji-1f9a0 { background-position: -2838px -462px; }
.emoji-1f490 { background-position: -1650px -1848px; }
.emoji-1f338 { background-position: -396px -660px; }
.emoji-1f4ae { background-position: -1716px -330px; }
.emoji-1f3f5-fe0f { background-position: -726px -528px; }
.emoji-1f339 { background-position: -396px -726px; }
.emoji-1f940 { background-position: -2706px -1584px; }
.emoji-1f33a { background-position: -396px -792px; }
.emoji-1f33b { background-position: -396px -858px; }
.emoji-1f33c { background-position: -396px -924px; }
.emoji-1f337 { background-position: -396px -594px; }
.emoji-1f331 { background-position: -396px -198px; }
.emoji-1fab4 { background-position: -3498px -198px; }
.emoji-1f332 { background-position: -396px -264px; }
.emoji-1f333 { background-position: -396px -330px; }
.emoji-1f334 { background-position: -396px -396px; }
.emoji-1f335 { background-position: -396px -462px; }
.emoji-1f33e { background-position: -396px -1056px; }
.emoji-1f33f { background-position: -396px -1122px; }
.emoji-2618-fe0f { background-position: -3564px -396px; }
.emoji-1f340 { background-position: -396px -1188px; }
.emoji-1f341 { background-position: -396px -1254px; }
.emoji-1f342 { background-position: -396px -1320px; }
.emoji-1f343 { background-position: -396px -1386px; }
.emoji-1f347 { background-position: -396px -1650px; }
.emoji-1f348 { background-position: -396px -1716px; }
.emoji-1f349 { background-position: -396px -1782px; }
.emoji-1f34a { background-position: -396px -1848px; }
.emoji-1f34b { background-position: -396px -1914px; }
.emoji-1f34c { background-position: -396px -1980px; }
.emoji-1f34d { background-position: -396px -2046px; }
.emoji-1f96d { background-position: -2772px -660px; }
.emoji-1f34e { background-position: -396px -2112px; }
.emoji-1f34f { background-position: -396px -2178px; }
.emoji-1f350 { background-position: -396px -2244px; }
.emoji-1f351 { background-position: -396px -2310px; }
.emoji-1f352 { background-position: -396px -2376px; }
.emoji-1f353 { background-position: -396px -2442px; }
.emoji-1fad0 { background-position: -3498px -594px; }
.emoji-1f95d { background-position: -2706px -3432px; }
.emoji-1f345 { background-position: -396px -1518px; }
.emoji-1fad2 { background-position: -3498px -726px; }
.emoji-1f965 { background-position: -2772px -132px; }
.emoji-1f951 { background-position: -2706px -2640px; }
.emoji-1f346 { background-position: -396px -1584px; }
.emoji-1f954 { background-position: -2706px -2838px; }
.emoji-1f955 { background-position: -2706px -2904px; }
.emoji-1f33d { background-position: -396px -990px; }
.emoji-1f336-fe0f { background-position: -396px -528px; }
.emoji-1fad1 { background-position: -3498px -660px; }
.emoji-1f952 { background-position: -2706px -2706px; }
.emoji-1f96c { background-position: -2772px -594px; }
.emoji-1f966 { background-position: -2772px -198px; }
.emoji-1f9c4 { background-position: -2904px -1980px; }
.emoji-1f9c5 { background-position: -2904px -2046px; }
.emoji-1f344 { background-position: -396px -1452px; }
.emoji-1f95c { background-position: -2706px -3366px; }
.emoji-1f330 { background-position: -396px -132px; }
.emoji-1f35e { background-position: -396px -3168px; }
.emoji-1f950 { background-position: -2706px -2574px; }
.emoji-1f956 { background-position: -2706px -2970px; }
.emoji-1fad3 { background-position: -3498px -792px; }
.emoji-1f968 { background-position: -2772px -330px; }
.emoji-1f96f { background-position: -2772px -792px; }
.emoji-1f95e { background-position: -2706px -3498px; }
.emoji-1f9c7 { background-position: -2904px -2178px; }
.emoji-1f9c0 { background-position: -2904px -1716px; }
.emoji-1f356 { background-position: -396px -2640px; }
.emoji-1f357 { background-position: -396px -2706px; }
.emoji-1f969 { background-position: -2772px -396px; }
.emoji-1f953 { background-position: -2706px -2772px; }
.emoji-1f354 { background-position: -396px -2508px; }
.emoji-1f35f { background-position: -396px -3234px; }
.emoji-1f355 { background-position: -396px -2574px; }
.emoji-1f32d { background-position: -330px -3762px; }
.emoji-1f96a { background-position: -2772px -462px; }
.emoji-1f32e { background-position: -396px -0; }
.emoji-1f32f { background-position: -396px -66px; }
.emoji-1fad4 { background-position: -3498px -858px; }
.emoji-1f959 { background-position: -2706px -3168px; }
.emoji-1f9c6 { background-position: -2904px -2112px; }
.emoji-1f95a { background-position: -2706px -3234px; }
.emoji-1f373 { background-position: -462px -726px; }
.emoji-1f958 { background-position: -2706px -3102px; }
.emoji-1f372 { background-position: -462px -660px; }
.emoji-1fad5 { background-position: -3498px -924px; }
.emoji-1f963 { background-position: -2772px -0; }
.emoji-1f957 { background-position: -2706px -3036px; }
.emoji-1f37f { background-position: -462px -1518px; }
.emoji-1f9c8 { background-position: -2904px -2244px; }
.emoji-1f9c2 { background-position: -2904px -1848px; }
.emoji-1f96b { background-position: -2772px -528px; }
.emoji-1f371 { background-position: -462px -594px; }
.emoji-1f358 { background-position: -396px -2772px; }
.emoji-1f359 { background-position: -396px -2838px; }
.emoji-1f35a { background-position: -396px -2904px; }
.emoji-1f35b { background-position: -396px -2970px; }
.emoji-1f35c { background-position: -396px -3036px; }
.emoji-1f35d { background-position: -396px -3102px; }
.emoji-1f360 { background-position: -396px -3300px; }
.emoji-1f362 { background-position: -396px -3432px; }
.emoji-1f363 { background-position: -396px -3498px; }
.emoji-1f364 { background-position: -396px -3564px; }
.emoji-1f365 { background-position: -396px -3630px; }
.emoji-1f96e { background-position: -2772px -726px; }
.emoji-1f361 { background-position: -396px -3366px; }
.emoji-1f95f { background-position: -2706px -3564px; }
.emoji-1f960 { background-position: -2706px -3630px; }
.emoji-1f961 { background-position: -2706px -3696px; }
.emoji-1f980 { background-position: -2772px -2178px; }
.emoji-1f99e { background-position: -2838px -330px; }
.emoji-1f990 { background-position: -2772px -3234px; }
.emoji-1f991 { background-position: -2772px -3300px; }
.emoji-1f9aa { background-position: -2838px -1122px; }
.emoji-1f366 { background-position: -396px -3696px; }
.emoji-1f367 { background-position: -396px -3762px; }
.emoji-1f368 { background-position: -462px -0; }
.emoji-1f369 { background-position: -462px -66px; }
.emoji-1f36a { background-position: -462px -132px; }
.emoji-1f382 { background-position: -462px -1716px; }
.emoji-1f370 { background-position: -462px -528px; }
.emoji-1f9c1 { background-position: -2904px -1782px; }
.emoji-1f967 { background-position: -2772px -264px; }
.emoji-1f36b { background-position: -462px -198px; }
.emoji-1f36c { background-position: -462px -264px; }
.emoji-1f36d { background-position: -462px -330px; }
.emoji-1f36e { background-position: -462px -396px; }
.emoji-1f36f { background-position: -462px -462px; }
.emoji-1f37c { background-position: -462px -1320px; }
.emoji-1f95b { background-position: -2706px -3300px; }
.emoji-2615 { background-position: -3564px -330px; }
.emoji-1fad6 { background-position: -3498px -990px; }
.emoji-1f375 { background-position: -462px -858px; }
.emoji-1f376 { background-position: -462px -924px; }
.emoji-1f37e { background-position: -462px -1452px; }
.emoji-1f377 { background-position: -462px -990px; }
.emoji-1f378 { background-position: -462px -1056px; }
.emoji-1f379 { background-position: -462px -1122px; }
.emoji-1f37a { background-position: -462px -1188px; }
.emoji-1f37b { background-position: -462px -1254px; }
.emoji-1f942 { background-position: -2706px -1716px; }
.emoji-1f943 { background-position: -2706px -1782px; }
.emoji-1f964 { background-position: -2772px -66px; }
.emoji-1f9cb { background-position: -2904px -2442px; }
.emoji-1f9c3 { background-position: -2904px -1914px; }
.emoji-1f9c9 { background-position: -2904px -2310px; }
.emoji-1f9ca { background-position: -2904px -2376px; }
.emoji-1f962 { background-position: -2706px -3762px; }
.emoji-1f37d-fe0f { background-position: -462px -1386px; }
.emoji-1f374 { background-position: -462px -792px; }
.emoji-1f944 { background-position: -2706px -1848px; }
.emoji-1f52a { background-position: -1848px -792px; }
.emoji-1f3fa { background-position: -726px -792px; }
.emoji-1f30d { background-position: -330px -1782px; }
.emoji-1f30e { background-position: -330px -1848px; }
.emoji-1f30f { background-position: -330px -1914px; }
.emoji-1f310 { background-position: -330px -1980px; }
.emoji-1f5fa-fe0f { background-position: -1980px -1716px; }
.emoji-1f5fe { background-position: -1980px -1980px; }
.emoji-1f9ed { background-position: -3366px -3696px; }
.emoji-1f3d4-fe0f { background-position: -660px -1914px; }
.emoji-26f0-fe0f { background-position: -3630px -1056px; }
.emoji-1f30b { background-position: -330px -1650px; }
.emoji-1f5fb { background-position: -1980px -1782px; }
.emoji-1f3d5-fe0f { background-position: -660px -1980px; }
.emoji-1f3d6-fe0f { background-position: -660px -2046px; }
.emoji-1f3dc-fe0f { background-position: -660px -2442px; }
.emoji-1f3dd-fe0f { background-position: -660px -2508px; }
.emoji-1f3de-fe0f { background-position: -660px -2574px; }
.emoji-1f3df-fe0f { background-position: -660px -2640px; }
.emoji-1f3db-fe0f { background-position: -660px -2376px; }
.emoji-1f3d7-fe0f { background-position: -660px -2112px; }
.emoji-1f9f1 { background-position: -3432px -132px; }
.emoji-1faa8 { background-position: -3432px -3696px; }
.emoji-1fab5 { background-position: -3498px -264px; }
.emoji-1f6d6 { background-position: -2376px -2178px; }
.emoji-1f3d8-fe0f { background-position: -660px -2178px; }
.emoji-1f3da-fe0f { background-position: -660px -2310px; }
.emoji-1f3e0 { background-position: -660px -2706px; }
.emoji-1f3e1 { background-position: -660px -2772px; }
.emoji-1f3e2 { background-position: -660px -2838px; }
.emoji-1f3e3 { background-position: -660px -2904px; }
.emoji-1f3e4 { background-position: -660px -2970px; }
.emoji-1f3e5 { background-position: -660px -3036px; }
.emoji-1f3e6 { background-position: -660px -3102px; }
.emoji-1f3e8 { background-position: -660px -3234px; }
.emoji-1f3e9 { background-position: -660px -3300px; }
.emoji-1f3ea { background-position: -660px -3366px; }
.emoji-1f3eb { background-position: -660px -3432px; }
.emoji-1f3ec { background-position: -660px -3498px; }
.emoji-1f3ed { background-position: -660px -3564px; }
.emoji-1f3ef { background-position: -660px -3696px; }
.emoji-1f3f0 { background-position: -660px -3762px; }
.emoji-1f492 { background-position: -1650px -1980px; }
.emoji-1f5fc { background-position: -1980px -1848px; }
.emoji-1f5fd { background-position: -1980px -1914px; }
.emoji-26ea { background-position: -3630px -990px; }
.emoji-1f54c { background-position: -1848px -2310px; }
.emoji-1f6d5 { background-position: -2376px -2112px; }
.emoji-1f54d { background-position: -1848px -2376px; }
.emoji-26e9-fe0f { background-position: -3630px -924px; }
.emoji-1f54b { background-position: -1848px -2244px; }
.emoji-26f2 { background-position: -3630px -1188px; }
.emoji-26fa { background-position: -3630px -2772px; }
.emoji-1f301 { background-position: -330px -990px; }
.emoji-1f303 { background-position: -330px -1122px; }
.emoji-1f3d9-fe0f { background-position: -660px -2244px; }
.emoji-1f304 { background-position: -330px -1188px; }
.emoji-1f305 { background-position: -330px -1254px; }
.emoji-1f306 { background-position: -330px -1320px; }
.emoji-1f307 { background-position: -330px -1386px; }
.emoji-1f309 { background-position: -330px -1518px; }
.emoji-2668-fe0f { background-position: -3564px -2772px; }
.emoji-1f3a0 { background-position: -462px -3696px; }
.emoji-1f3a1 { background-position: -462px -3762px; }
.emoji-1f3a2 { background-position: -528px -0; }
.emoji-1f488 { background-position: -1650px -1320px; }
.emoji-1f3aa { background-position: -528px -528px; }
.emoji-1f682 { background-position: -2178px -3432px; }
.emoji-1f683 { background-position: -2178px -3498px; }
.emoji-1f684 { background-position: -2178px -3564px; }
.emoji-1f685 { background-position: -2178px -3630px; }
.emoji-1f686 { background-position: -2178px -3696px; }
.emoji-1f687 { background-position: -2178px -3762px; }
.emoji-1f688 { background-position: -2244px -0; }
.emoji-1f689 { background-position: -2244px -66px; }
.emoji-1f68a { background-position: -2244px -132px; }
.emoji-1f69d { background-position: -2244px -1386px; }
.emoji-1f69e { background-position: -2244px -1452px; }
.emoji-1f68b { background-position: -2244px -198px; }
.emoji-1f68c { background-position: -2244px -264px; }
.emoji-1f68d { background-position: -2244px -330px; }
.emoji-1f68e { background-position: -2244px -396px; }
.emoji-1f690 { background-position: -2244px -528px; }
.emoji-1f691 { background-position: -2244px -594px; }
.emoji-1f692 { background-position: -2244px -660px; }
.emoji-1f693 { background-position: -2244px -726px; }
.emoji-1f694 { background-position: -2244px -792px; }
.emoji-1f695 { background-position: -2244px -858px; }
.emoji-1f696 { background-position: -2244px -924px; }
.emoji-1f697 { background-position: -2244px -990px; }
.emoji-1f698 { background-position: -2244px -1056px; }
.emoji-1f699 { background-position: -2244px -1122px; }
.emoji-1f6fb { background-position: -2376px -3498px; }
.emoji-1f69a { background-position: -2244px -1188px; }
.emoji-1f69b { background-position: -2244px -1254px; }
.emoji-1f69c { background-position: -2244px -1320px; }
.emoji-1f3ce-fe0f { background-position: -660px -1518px; }
.emoji-1f3cd-fe0f { background-position: -660px -1452px; }
.emoji-1f6f5 { background-position: -2376px -3102px; }
.emoji-1f9bd { background-position: -2904px -1518px; }
.emoji-1f9bc { background-position: -2904px -1452px; }
.emoji-1f6fa { background-position: -2376px -3432px; }
.emoji-1f6b2 { background-position: -2310px -66px; }
.emoji-1f6f4 { background-position: -2376px -3036px; }
.emoji-1f6f9 { background-position: -2376px -3366px; }
.emoji-1f6fc { background-position: -2376px -3564px; }
.emoji-1f68f { background-position: -2244px -462px; }
.emoji-1f6e3-fe0f { background-position: -2376px -2508px; }
.emoji-1f6e4-fe0f { background-position: -2376px -2574px; }
.emoji-1f6e2-fe0f { background-position: -2376px -2442px; }
.emoji-26fd { background-position: -3630px -2838px; }
.emoji-1f6a8 { background-position: -2244px -3234px; }
.emoji-1f6a5 { background-position: -2244px -3036px; }
.emoji-1f6a6 { background-position: -2244px -3102px; }
.emoji-1f6d1 { background-position: -2376px -1980px; }
.emoji-1f6a7 { background-position: -2244px -3168px; }
.emoji-2693 { background-position: -3564px -3102px; }
.emoji-26f5 { background-position: -3630px -1386px; }
.emoji-1f6f6 { background-position: -2376px -3168px; }
.emoji-1f6a4 { background-position: -2244px -2970px; }
.emoji-1f6f3-fe0f { background-position: -2376px -2970px; }
.emoji-26f4-fe0f { background-position: -3630px -1320px; }
.emoji-1f6e5-fe0f { background-position: -2376px -2640px; }
.emoji-1f6a2 { background-position: -2244px -1716px; }
.emoji-2708-fe0f { background-position: -3630px -3036px; }
.emoji-1f6e9-fe0f { background-position: -2376px -2706px; }
.emoji-1f6eb { background-position: -2376px -2772px; }
.emoji-1f6ec { background-position: -2376px -2838px; }
.emoji-1fa82 { background-position: -3432px -1782px; }
.emoji-1f4ba { background-position: -1716px -1122px; }
.emoji-1f681 { background-position: -2178px -3366px; }
.emoji-1f69f { background-position: -2244px -1518px; }
.emoji-1f6a0 { background-position: -2244px -1584px; }
.emoji-1f6a1 { background-position: -2244px -1650px; }
.emoji-1f6f0-fe0f { background-position: -2376px -2904px; }
.emoji-1f680 { background-position: -2178px -3300px; }
.emoji-1f6f8 { background-position: -2376px -3300px; }
.emoji-1f6ce-fe0f { background-position: -2376px -1782px; }
.emoji-1f9f3 { background-position: -3432px -264px; }
.emoji-231b { background-position: -3498px -1914px; }
.emoji-23f3 { background-position: -3498px -2772px; }
.emoji-231a { background-position: -3498px -1848px; }
.emoji-23f0 { background-position: -3498px -2574px; }
.emoji-23f1-fe0f { background-position: -3498px -2640px; }
.emoji-23f2-fe0f { background-position: -3498px -2706px; }
.emoji-1f570-fe0f { background-position: -1914px -330px; }
.emoji-1f55b { background-position: -1848px -3234px; }
.emoji-1f567 { background-position: -1914px -198px; }
.emoji-1f550 { background-position: -1848px -2508px; }
.emoji-1f55c { background-position: -1848px -3300px; }
.emoji-1f551 { background-position: -1848px -2574px; }
.emoji-1f55d { background-position: -1848px -3366px; }
.emoji-1f552 { background-position: -1848px -2640px; }
.emoji-1f55e { background-position: -1848px -3432px; }
.emoji-1f553 { background-position: -1848px -2706px; }
.emoji-1f55f { background-position: -1848px -3498px; }
.emoji-1f554 { background-position: -1848px -2772px; }
.emoji-1f560 { background-position: -1848px -3564px; }
.emoji-1f555 { background-position: -1848px -2838px; }
.emoji-1f561 { background-position: -1848px -3630px; }
.emoji-1f556 { background-position: -1848px -2904px; }
.emoji-1f562 { background-position: -1848px -3696px; }
.emoji-1f557 { background-position: -1848px -2970px; }
.emoji-1f563 { background-position: -1848px -3762px; }
.emoji-1f558 { background-position: -1848px -3036px; }
.emoji-1f564 { background-position: -1914px -0; }
.emoji-1f559 { background-position: -1848px -3102px; }
.emoji-1f565 { background-position: -1914px -66px; }
.emoji-1f55a { background-position: -1848px -3168px; }
.emoji-1f566 { background-position: -1914px -132px; }
.emoji-1f311 { background-position: -330px -2046px; }
.emoji-1f312 { background-position: -330px -2112px; }
.emoji-1f313 { background-position: -330px -2178px; }
.emoji-1f314 { background-position: -330px -2244px; }
.emoji-1f315 { background-position: -330px -2310px; }
.emoji-1f316 { background-position: -330px -2376px; }
.emoji-1f317 { background-position: -330px -2442px; }
.emoji-1f318 { background-position: -330px -2508px; }
.emoji-1f319 { background-position: -330px -2574px; }
.emoji-1f31a { background-position: -330px -2640px; }
.emoji-1f31b { background-position: -330px -2706px; }
.emoji-1f31c { background-position: -330px -2772px; }
.emoji-1f321-fe0f { background-position: -330px -3102px; }
.emoji-2600-fe0f { background-position: -3498px -3630px; }
.emoji-1f31d { background-position: -330px -2838px; }
.emoji-1f31e { background-position: -330px -2904px; }
.emoji-1fa90 { background-position: -3432px -2112px; }
.emoji-2b50 { background-position: -3696px -3036px; }
.emoji-1f31f { background-position: -330px -2970px; }
.emoji-1f320 { background-position: -330px -3036px; }
.emoji-1f30c { background-position: -330px -1716px; }
.emoji-2601-fe0f { background-position: -3498px -3696px; }
.emoji-26c5 { background-position: -3630px -462px; }
.emoji-26c8-fe0f { background-position: -3630px -528px; }
.emoji-1f324-fe0f { background-position: -330px -3168px; }
.emoji-1f325-fe0f { background-position: -330px -3234px; }
.emoji-1f326-fe0f { background-position: -330px -3300px; }
.emoji-1f327-fe0f { background-position: -330px -3366px; }
.emoji-1f328-fe0f { background-position: -330px -3432px; }
.emoji-1f329-fe0f { background-position: -330px -3498px; }
.emoji-1f32a-fe0f { background-position: -330px -3564px; }
.emoji-1f32b-fe0f { background-position: -330px -3630px; }
.emoji-1f32c-fe0f { background-position: -330px -3696px; }
.emoji-1f300 { background-position: -330px -924px; }
.emoji-1f308 { background-position: -330px -1452px; }
.emoji-1f302 { background-position: -330px -1056px; }
.emoji-2602-fe0f { background-position: -3498px -3762px; }
.emoji-2614 { background-position: -3564px -264px; }
.emoji-26f1-fe0f { background-position: -3630px -1122px; }
.emoji-26a1 { background-position: -3564px -3696px; }
.emoji-2744-fe0f { background-position: -3696px -1518px; }
.emoji-2603-fe0f { background-position: -3564px -0; }
.emoji-26c4 { background-position: -3630px -396px; }
.emoji-2604-fe0f { background-position: -3564px -66px; }
.emoji-1f525 { background-position: -1848px -462px; }
.emoji-1f4a7 { background-position: -1650px -3366px; }
.emoji-1f30a { background-position: -330px -1584px; }
.emoji-1f383 { background-position: -462px -1782px; }
.emoji-1f384 { background-position: -462px -1848px; }
.emoji-1f386 { background-position: -462px -2310px; }
.emoji-1f387 { background-position: -462px -2376px; }
.emoji-1f9e8 { background-position: -3366px -3366px; }
.emoji-2728 { background-position: -3696px -1320px; }
.emoji-1f388 { background-position: -462px -2442px; }
.emoji-1f389 { background-position: -462px -2508px; }
.emoji-1f38a { background-position: -462px -2574px; }
.emoji-1f38b { background-position: -462px -2640px; }
.emoji-1f38d { background-position: -462px -2772px; }
.emoji-1f38e { background-position: -462px -2838px; }
.emoji-1f38f { background-position: -462px -2904px; }
.emoji-1f390 { background-position: -462px -2970px; }
.emoji-1f391 { background-position: -462px -3036px; }
.emoji-1f9e7 { background-position: -3366px -3300px; }
.emoji-1f380 { background-position: -462px -1584px; }
.emoji-1f381 { background-position: -462px -1650px; }
.emoji-1f397-fe0f { background-position: -462px -3300px; }
.emoji-1f39f-fe0f { background-position: -462px -3630px; }
.emoji-1f3ab { background-position: -528px -594px; }
.emoji-1f396-fe0f { background-position: -462px -3234px; }
.emoji-1f3c6 { background-position: -594px -1122px; }
.emoji-1f3c5 { background-position: -594px -1056px; }
.emoji-1f947 { background-position: -2706px -1980px; }
.emoji-1f948 { background-position: -2706px -2046px; }
.emoji-1f949 { background-position: -2706px -2112px; }
.emoji-26bd { background-position: -3630px -264px; }
.emoji-26be { background-position: -3630px -330px; }
.emoji-1f94e { background-position: -2706px -2442px; }
.emoji-1f3c0 { background-position: -528px -1980px; }
.emoji-1f3d0 { background-position: -660px -1650px; }
.emoji-1f3c8 { background-position: -594px -1584px; }
.emoji-1f3c9 { background-position: -594px -1650px; }
.emoji-1f3be { background-position: -528px -1848px; }
.emoji-1f94f { background-position: -2706px -2508px; }
.emoji-1f3b3 { background-position: -528px -1122px; }
.emoji-1f3cf { background-position: -660px -1584px; }
.emoji-1f3d1 { background-position: -660px -1716px; }
.emoji-1f3d2 { background-position: -660px -1782px; }
.emoji-1f94d { background-position: -2706px -2376px; }
.emoji-1f3d3 { background-position: -660px -1848px; }
.emoji-1f3f8 { background-position: -726px -660px; }
.emoji-1f94a { background-position: -2706px -2178px; }
.emoji-1f94b { background-position: -2706px -2244px; }
.emoji-1f945 { background-position: -2706px -1914px; }
.emoji-26f3 { background-position: -3630px -1254px; }
.emoji-26f8-fe0f { background-position: -3630px -1518px; }
.emoji-1f3a3 { background-position: -528px -66px; }
.emoji-1f93f { background-position: -2706px -1518px; }
.emoji-1f3bd { background-position: -528px -1782px; }
.emoji-1f3bf { background-position: -528px -1914px; }
.emoji-1f6f7 { background-position: -2376px -3234px; }
.emoji-1f94c { background-position: -2706px -2310px; }
.emoji-1f3af { background-position: -528px -858px; }
.emoji-1fa80 { background-position: -3432px -1650px; }
.emoji-1fa81 { background-position: -3432px -1716px; }
.emoji-1f3b1 { background-position: -528px -990px; }
.emoji-1f52e { background-position: -1848px -1056px; }
.emoji-1fa84 { background-position: -3432px -1914px; }
.emoji-1f9ff { background-position: -3432px -1056px; }
.emoji-1f3ae { background-position: -528px -792px; }
.emoji-1f579-fe0f { background-position: -1914px -2244px; }
.emoji-1f3b0 { background-position: -528px -924px; }
.emoji-1f3b2 { background-position: -528px -1056px; }
.emoji-1f9e9 { background-position: -3366px -3432px; }
.emoji-1f9f8 { background-position: -3432px -594px; }
.emoji-1fa85 { background-position: -3432px -1980px; }
.emoji-1fa86 { background-position: -3432px -2046px; }
.emoji-2660-fe0f { background-position: -3564px -2508px; }
.emoji-2665-fe0f { background-position: -3564px -2640px; }
.emoji-2666-fe0f { background-position: -3564px -2706px; }
.emoji-2663-fe0f { background-position: -3564px -2574px; }
.emoji-265f-fe0f { background-position: -3564px -2442px; }
.emoji-1f0cf { background-position: -0 -990px; }
.emoji-1f004 { background-position: -0 -924px; }
.emoji-1f3b4 { background-position: -528px -1188px; }
.emoji-1f3ad { background-position: -528px -726px; }
.emoji-1f5bc-fe0f { background-position: -1980px -726px; }
.emoji-1f3a8 { background-position: -528px -396px; }
.emoji-1f9f5 { background-position: -3432px -396px; }
.emoji-1faa1 { background-position: -3432px -3234px; }
.emoji-1f9f6 { background-position: -3432px -462px; }
.emoji-1faa2 { background-position: -3432px -3300px; }
.emoji-1f453 { background-position: -858px -3564px; }
.emoji-1f576-fe0f { background-position: -1914px -2046px; }
.emoji-1f97d { background-position: -2772px -1980px; }
.emoji-1f97c { background-position: -2772px -1914px; }
.emoji-1f9ba { background-position: -2904px -990px; }
.emoji-1f454 { background-position: -858px -3630px; }
.emoji-1f455 { background-position: -858px -3696px; }
.emoji-1f456 { background-position: -858px -3762px; }
.emoji-1f9e3 { background-position: -3366px -3036px; }
.emoji-1f9e4 { background-position: -3366px -3102px; }
.emoji-1f9e5 { background-position: -3366px -3168px; }
.emoji-1f9e6 { background-position: -3366px -3234px; }
.emoji-1f457 { background-position: -924px -0; }
.emoji-1f458 { background-position: -924px -66px; }
.emoji-1f97b { background-position: -2772px -1848px; }
.emoji-1fa71 { background-position: -3432px -1188px; }
.emoji-1fa72 { background-position: -3432px -1254px; }
.emoji-1fa73 { background-position: -3432px -1320px; }
.emoji-1f459 { background-position: -924px -132px; }
.emoji-1f45a { background-position: -924px -198px; }
.emoji-1f45b { background-position: -924px -264px; }
.emoji-1f45c { background-position: -924px -330px; }
.emoji-1f45d { background-position: -924px -396px; }
.emoji-1f6cd-fe0f { background-position: -2376px -1716px; }
.emoji-1f392 { background-position: -462px -3102px; }
.emoji-1fa74 { background-position: -3432px -1386px; }
.emoji-1f45e { background-position: -924px -462px; }
.emoji-1f45f { background-position: -924px -528px; }
.emoji-1f97e { background-position: -2772px -2046px; }
.emoji-1f97f { background-position: -2772px -2112px; }
.emoji-1f460 { background-position: -924px -594px; }
.emoji-1f461 { background-position: -924px -660px; }
.emoji-1fa70 { background-position: -3432px -1122px; }
.emoji-1f462 { background-position: -924px -726px; }
.emoji-1f451 { background-position: -858px -3432px; }
.emoji-1f452 { background-position: -858px -3498px; }
.emoji-1f3a9 { background-position: -528px -462px; }
.emoji-1f393 { background-position: -462px -3168px; }
.emoji-1f9e2 { background-position: -3366px -2970px; }
.emoji-1fa96 { background-position: -3432px -2508px; }
.emoji-26d1-fe0f { background-position: -3630px -726px; }
.emoji-1f4ff { background-position: -1782px -1782px; }
.emoji-1f484 { background-position: -1584px -2310px; }
.emoji-1f48d { background-position: -1650px -1650px; }
.emoji-1f48e { background-position: -1650px -1716px; }
.emoji-1f507 { background-position: -1782px -2310px; }
.emoji-1f508 { background-position: -1782px -2376px; }
.emoji-1f509 { background-position: -1782px -2442px; }
.emoji-1f50a { background-position: -1782px -2508px; }
.emoji-1f4e2 { background-position: -1716px -3762px; }
.emoji-1f4e3 { background-position: -1782px -0; }
.emoji-1f4ef { background-position: -1782px -792px; }
.emoji-1f514 { background-position: -1782px -3168px; }
.emoji-1f515 { background-position: -1782px -3234px; }
.emoji-1f3bc { background-position: -528px -1716px; }
.emoji-1f3b5 { background-position: -528px -1254px; }
.emoji-1f3b6 { background-position: -528px -1320px; }
.emoji-1f399-fe0f { background-position: -462px -3366px; }
.emoji-1f39a-fe0f { background-position: -462px -3432px; }
.emoji-1f39b-fe0f { background-position: -462px -3498px; }
.emoji-1f3a4 { background-position: -528px -132px; }
.emoji-1f3a7 { background-position: -528px -330px; }
.emoji-1f4fb { background-position: -1782px -1584px; }
.emoji-1f3b7 { background-position: -528px -1386px; }
.emoji-1fa97 { background-position: -3432px -2574px; }
.emoji-1f3b8 { background-position: -528px -1452px; }
.emoji-1f3b9 { background-position: -528px -1518px; }
.emoji-1f3ba { background-position: -528px -1584px; }
.emoji-1f3bb { background-position: -528px -1650px; }
.emoji-1fa95 { background-position: -3432px -2442px; }
.emoji-1f941 { background-position: -2706px -1650px; }
.emoji-1fa98 { background-position: -3432px -2640px; }
.emoji-1f4f1 { background-position: -1782px -924px; }
.emoji-1f4f2 { background-position: -1782px -990px; }
.emoji-260e-fe0f { background-position: -3564px -132px; }
.emoji-1f4de { background-position: -1716px -3498px; }
.emoji-1f4df { background-position: -1716px -3564px; }
.emoji-1f4e0 { background-position: -1716px -3630px; }
.emoji-1f50b { background-position: -1782px -2574px; }
.emoji-1f50c { background-position: -1782px -2640px; }
.emoji-1f4bb { background-position: -1716px -1188px; }
.emoji-1f5a5-fe0f { background-position: -1980px -462px; }
.emoji-1f5a8-fe0f { background-position: -1980px -528px; }
.emoji-2328-fe0f { background-position: -3498px -1980px; }
.emoji-1f5b1-fe0f { background-position: -1980px -594px; }
.emoji-1f5b2-fe0f { background-position: -1980px -660px; }
.emoji-1f4bd { background-position: -1716px -1320px; }
.emoji-1f4be { background-position: -1716px -1386px; }
.emoji-1f4bf { background-position: -1716px -1452px; }
.emoji-1f4c0 { background-position: -1716px -1518px; }
.emoji-1f9ee { background-position: -3366px -3762px; }
.emoji-1f3a5 { background-position: -528px -198px; }
.emoji-1f39e-fe0f { background-position: -462px -3564px; }
.emoji-1f4fd-fe0f { background-position: -1782px -1716px; }
.emoji-1f3ac { background-position: -528px -660px; }
.emoji-1f4fa { background-position: -1782px -1518px; }
.emoji-1f4f7 { background-position: -1782px -1320px; }
.emoji-1f4f8 { background-position: -1782px -1386px; }
.emoji-1f4f9 { background-position: -1782px -1452px; }
.emoji-1f4fc { background-position: -1782px -1650px; }
.emoji-1f50d { background-position: -1782px -2706px; }
.emoji-1f50e { background-position: -1782px -2772px; }
.emoji-1f56f-fe0f { background-position: -1914px -264px; }
.emoji-1f4a1 { background-position: -1650px -2970px; }
.emoji-1f526 { background-position: -1848px -528px; }
.emoji-1f3ee { background-position: -660px -3630px; }
.emoji-1fa94 { background-position: -3432px -2376px; }
.emoji-1f4d4 { background-position: -1716px -2838px; }
.emoji-1f4d5 { background-position: -1716px -2904px; }
.emoji-1f4d6 { background-position: -1716px -2970px; }
.emoji-1f4d7 { background-position: -1716px -3036px; }
.emoji-1f4d8 { background-position: -1716px -3102px; }
.emoji-1f4d9 { background-position: -1716px -3168px; }
.emoji-1f4da { background-position: -1716px -3234px; }
.emoji-1f4d3 { background-position: -1716px -2772px; }
.emoji-1f4d2 { background-position: -1716px -2706px; }
.emoji-1f4c3 { background-position: -1716px -1716px; }
.emoji-1f4dc { background-position: -1716px -3366px; }
.emoji-1f4c4 { background-position: -1716px -1782px; }
.emoji-1f4f0 { background-position: -1782px -858px; }
.emoji-1f5de-fe0f { background-position: -1980px -1320px; }
.emoji-1f4d1 { background-position: -1716px -2640px; }
.emoji-1f516 { background-position: -1782px -3300px; }
.emoji-1f3f7-fe0f { background-position: -726px -594px; }
.emoji-1f4b0 { background-position: -1716px -462px; }
.emoji-1fa99 { background-position: -3432px -2706px; }
.emoji-1f4b4 { background-position: -1716px -726px; }
.emoji-1f4b5 { background-position: -1716px -792px; }
.emoji-1f4b6 { background-position: -1716px -858px; }
.emoji-1f4b7 { background-position: -1716px -924px; }
.emoji-1f4b8 { background-position: -1716px -990px; }
.emoji-1f4b3 { background-position: -1716px -660px; }
.emoji-1f9fe { background-position: -3432px -990px; }
.emoji-1f4b9 { background-position: -1716px -1056px; }
.emoji-2709-fe0f { background-position: -3630px -3102px; }
.emoji-1f4e7 { background-position: -1782px -264px; }
.emoji-1f4e8 { background-position: -1782px -330px; }
.emoji-1f4e9 { background-position: -1782px -396px; }
.emoji-1f4e4 { background-position: -1782px -66px; }
.emoji-1f4e5 { background-position: -1782px -132px; }
.emoji-1f4e6 { background-position: -1782px -198px; }
.emoji-1f4eb { background-position: -1782px -528px; }
.emoji-1f4ea { background-position: -1782px -462px; }
.emoji-1f4ec { background-position: -1782px -594px; }
.emoji-1f4ed { background-position: -1782px -660px; }
.emoji-1f4ee { background-position: -1782px -726px; }
.emoji-1f5f3-fe0f { background-position: -1980px -1650px; }
.emoji-270f-fe0f { background-position: -3696px -924px; }
.emoji-2712-fe0f { background-position: -3696px -990px; }
.emoji-1f58b-fe0f { background-position: -1914px -2838px; }
.emoji-1f58a-fe0f { background-position: -1914px -2772px; }
.emoji-1f58c-fe0f { background-position: -1914px -2904px; }
.emoji-1f58d-fe0f { background-position: -1914px -2970px; }
.emoji-1f4dd { background-position: -1716px -3432px; }
.emoji-1f4bc { background-position: -1716px -1254px; }
.emoji-1f4c1 { background-position: -1716px -1584px; }
.emoji-1f4c2 { background-position: -1716px -1650px; }
.emoji-1f5c2-fe0f { background-position: -1980px -792px; }
.emoji-1f4c5 { background-position: -1716px -1848px; }
.emoji-1f4c6 { background-position: -1716px -1914px; }
.emoji-1f5d2-fe0f { background-position: -1980px -1056px; }
.emoji-1f5d3-fe0f { background-position: -1980px -1122px; }
.emoji-1f4c7 { background-position: -1716px -1980px; }
.emoji-1f4c8 { background-position: -1716px -2046px; }
.emoji-1f4c9 { background-position: -1716px -2112px; }
.emoji-1f4ca { background-position: -1716px -2178px; }
.emoji-1f4cb { background-position: -1716px -2244px; }
.emoji-1f4cc { background-position: -1716px -2310px; }
.emoji-1f4cd { background-position: -1716px -2376px; }
.emoji-1f4ce { background-position: -1716px -2442px; }
.emoji-1f587-fe0f { background-position: -1914px -2706px; }
.emoji-1f4cf { background-position: -1716px -2508px; }
.emoji-1f4d0 { background-position: -1716px -2574px; }
.emoji-2702-fe0f { background-position: -3630px -2904px; }
.emoji-1f5c3-fe0f { background-position: -1980px -858px; }
.emoji-1f5c4-fe0f { background-position: -1980px -924px; }
.emoji-1f5d1-fe0f { background-position: -1980px -990px; }
.emoji-1f512 { background-position: -1782px -3036px; }
.emoji-1f513 { background-position: -1782px -3102px; }
.emoji-1f50f { background-position: -1782px -2838px; }
.emoji-1f510 { background-position: -1782px -2904px; }
.emoji-1f511 { background-position: -1782px -2970px; }
.emoji-1f5dd-fe0f { background-position: -1980px -1254px; }
.emoji-1f528 { background-position: -1848px -660px; }
.emoji-1fa93 { background-position: -3432px -2310px; }
.emoji-26cf-fe0f { background-position: -3630px -660px; }
.emoji-2692-fe0f { background-position: -3564px -3036px; }
.emoji-1f6e0-fe0f { background-position: -2376px -2310px; }
.emoji-1f5e1-fe0f { background-position: -1980px -1386px; }
.emoji-2694-fe0f { background-position: -3564px -3168px; }
.emoji-1f52b { background-position: -1848px -858px; }
.emoji-1fa83 { background-position: -3432px -1848px; }
.emoji-1f3f9 { background-position: -726px -726px; }
.emoji-1f6e1-fe0f { background-position: -2376px -2376px; }
.emoji-1fa9a { background-position: -3432px -2772px; }
.emoji-1f527 { background-position: -1848px -594px; }
.emoji-1fa9b { background-position: -3432px -2838px; }
.emoji-1f529 { background-position: -1848px -726px; }
.emoji-2699-fe0f { background-position: -3564px -3432px; }
.emoji-1f5dc-fe0f { background-position: -1980px -1188px; }
.emoji-2696-fe0f { background-position: -3564px -3300px; }
.emoji-1f9af { background-position: -2838px -1452px; }
.emoji-1f517 { background-position: -1782px -3366px; }
.emoji-26d3-fe0f { background-position: -3630px -792px; }
.emoji-1fa9d { background-position: -3432px -2970px; }
.emoji-1f9f0 { background-position: -3432px -66px; }
.emoji-1f9f2 { background-position: -3432px -198px; }
.emoji-1fa9c { background-position: -3432px -2904px; }
.emoji-2697-fe0f { background-position: -3564px -3366px; }
.emoji-1f9ea { background-position: -3366px -3498px; }
.emoji-1f9eb { background-position: -3366px -3564px; }
.emoji-1f9ec { background-position: -3366px -3630px; }
.emoji-1f52c { background-position: -1848px -924px; }
.emoji-1f52d { background-position: -1848px -990px; }
.emoji-1f4e1 { background-position: -1716px -3696px; }
.emoji-1f489 { background-position: -1650px -1386px; }
.emoji-1fa78 { background-position: -3432px -1452px; }
.emoji-1f48a { background-position: -1650px -1452px; }
.emoji-1fa79 { background-position: -3432px -1518px; }
.emoji-1fa7a { background-position: -3432px -1584px; }
.emoji-1f6aa { background-position: -2244px -3366px; }
.emoji-1f6d7 { background-position: -2376px -2244px; }
.emoji-1fa9e { background-position: -3432px -3036px; }
.emoji-1fa9f { background-position: -3432px -3102px; }
.emoji-1f6cf-fe0f { background-position: -2376px -1848px; }
.emoji-1f6cb-fe0f { background-position: -2376px -1254px; }
.emoji-1fa91 { background-position: -3432px -2178px; }
.emoji-1f6bd { background-position: -2376px -330px; }
.emoji-1faa0 { background-position: -3432px -3168px; }
.emoji-1f6bf { background-position: -2376px -462px; }
.emoji-1f6c1 { background-position: -2376px -924px; }
.emoji-1faa4 { background-position: -3432px -3432px; }
.emoji-1fa92 { background-position: -3432px -2244px; }
.emoji-1f9f4 { background-position: -3432px -330px; }
.emoji-1f9f7 { background-position: -3432px -528px; }
.emoji-1f9f9 { background-position: -3432px -660px; }
.emoji-1f9fa { background-position: -3432px -726px; }
.emoji-1f9fb { background-position: -3432px -792px; }
.emoji-1faa3 { background-position: -3432px -3366px; }
.emoji-1f9fc { background-position: -3432px -858px; }
.emoji-1faa5 { background-position: -3432px -3498px; }
.emoji-1f9fd { background-position: -3432px -924px; }
.emoji-1f9ef { background-position: -3432px -0; }
.emoji-1f6d2 { background-position: -2376px -2046px; }
.emoji-1f6ac { background-position: -2244px -3498px; }
.emoji-26b0-fe0f { background-position: -3630px -132px; }
.emoji-1faa6 { background-position: -3432px -3564px; }
.emoji-26b1-fe0f { background-position: -3630px -198px; }
.emoji-1f5ff { background-position: -1980px -2046px; }
.emoji-1faa7 { background-position: -3432px -3630px; }
.emoji-1f3e7 { background-position: -660px -3168px; }
.emoji-1f6ae { background-position: -2244px -3630px; }
.emoji-1f6b0 { background-position: -2244px -3762px; }
.emoji-267f { background-position: -3564px -2970px; }
.emoji-1f6b9 { background-position: -2376px -66px; }
.emoji-1f6ba { background-position: -2376px -132px; }
.emoji-1f6bb { background-position: -2376px -198px; }
.emoji-1f6bc { background-position: -2376px -264px; }
.emoji-1f6be { background-position: -2376px -396px; }
.emoji-1f6c2 { background-position: -2376px -990px; }
.emoji-1f6c3 { background-position: -2376px -1056px; }
.emoji-1f6c4 { background-position: -2376px -1122px; }
.emoji-1f6c5 { background-position: -2376px -1188px; }
.emoji-26a0-fe0f { background-position: -3564px -3630px; }
.emoji-1f6b8 { background-position: -2376px -0; }
.emoji-26d4 { background-position: -3630px -858px; }
.emoji-1f6ab { background-position: -2244px -3432px; }
.emoji-1f6b3 { background-position: -2310px -132px; }
.emoji-1f6ad { background-position: -2244px -3564px; }
.emoji-1f6af { background-position: -2244px -3696px; }
.emoji-1f6b1 { background-position: -2310px -0; }
.emoji-1f6b7 { background-position: -2310px -3762px; }
.emoji-1f4f5 { background-position: -1782px -1188px; }
.emoji-1f51e { background-position: -1848px -0; }
.emoji-2622-fe0f { background-position: -3564px -924px; }
.emoji-2623-fe0f { background-position: -3564px -990px; }
.emoji-2b06-fe0f { background-position: -3696px -2772px; }
.emoji-2197-fe0f { background-position: -3498px -1518px; }
.emoji-27a1-fe0f { background-position: -3696px -2376px; }
.emoji-2198-fe0f { background-position: -3498px -1584px; }
.emoji-2b07-fe0f { background-position: -3696px -2838px; }
.emoji-2199-fe0f { background-position: -3498px -1650px; }
.emoji-2b05-fe0f { background-position: -3696px -2706px; }
.emoji-2196-fe0f { background-position: -3498px -1452px; }
.emoji-2195-fe0f { background-position: -3498px -1386px; }
.emoji-2194-fe0f { background-position: -3498px -1320px; }
.emoji-21a9-fe0f { background-position: -3498px -1716px; }
.emoji-21aa-fe0f { background-position: -3498px -1782px; }
.emoji-2934-fe0f { background-position: -3696px -2574px; }
.emoji-2935-fe0f { background-position: -3696px -2640px; }
.emoji-1f503 { background-position: -1782px -2046px; }
.emoji-1f504 { background-position: -1782px -2112px; }
.emoji-1f519 { background-position: -1782px -3498px; }
.emoji-1f51a { background-position: -1782px -3564px; }
.emoji-1f51b { background-position: -1782px -3630px; }
.emoji-1f51c { background-position: -1782px -3696px; }
.emoji-1f51d { background-position: -1782px -3762px; }
.emoji-1f6d0 { background-position: -2376px -1914px; }
.emoji-269b-fe0f { background-position: -3564px -3498px; }
.emoji-1f549-fe0f { background-position: -1848px -2112px; }
.emoji-2721-fe0f { background-position: -3696px -1254px; }
.emoji-2638-fe0f { background-position: -3564px -1320px; }
.emoji-262f-fe0f { background-position: -3564px -1254px; }
.emoji-271d-fe0f { background-position: -3696px -1188px; }
.emoji-2626-fe0f { background-position: -3564px -1056px; }
.emoji-262a-fe0f { background-position: -3564px -1122px; }
.emoji-262e-fe0f { background-position: -3564px -1188px; }
.emoji-1f54e { background-position: -1848px -2442px; }
.emoji-1f52f { background-position: -1848px -1122px; }
.emoji-2648 { background-position: -3564px -1650px; }
.emoji-2649 { background-position: -3564px -1716px; }
.emoji-264a { background-position: -3564px -1782px; }
.emoji-264b { background-position: -3564px -1848px; }
.emoji-264c { background-position: -3564px -1914px; }
.emoji-264d { background-position: -3564px -1980px; }
.emoji-264e { background-position: -3564px -2046px; }
.emoji-264f { background-position: -3564px -2112px; }
.emoji-2650 { background-position: -3564px -2178px; }
.emoji-2651 { background-position: -3564px -2244px; }
.emoji-2652 { background-position: -3564px -2310px; }
.emoji-2653 { background-position: -3564px -2376px; }
.emoji-26ce { background-position: -3630px -594px; }
.emoji-1f500 { background-position: -1782px -1848px; }
.emoji-1f501 { background-position: -1782px -1914px; }
.emoji-1f502 { background-position: -1782px -1980px; }
.emoji-25b6-fe0f { background-position: -3498px -3234px; }
.emoji-23e9 { background-position: -3498px -2112px; }
.emoji-23ed-fe0f { background-position: -3498px -2376px; }
.emoji-23ef-fe0f { background-position: -3498px -2508px; }
.emoji-25c0-fe0f { background-position: -3498px -3300px; }
.emoji-23ea { background-position: -3498px -2178px; }
.emoji-23ee-fe0f { background-position: -3498px -2442px; }
.emoji-1f53c { background-position: -1848px -1980px; }
.emoji-23eb { background-position: -3498px -2244px; }
.emoji-1f53d { background-position: -1848px -2046px; }
.emoji-23ec { background-position: -3498px -2310px; }
.emoji-23f8-fe0f { background-position: -3498px -2838px; }
.emoji-23f9-fe0f { background-position: -3498px -2904px; }
.emoji-23fa-fe0f { background-position: -3498px -2970px; }
.emoji-23cf-fe0f { background-position: -3498px -2046px; }
.emoji-1f3a6 { background-position: -528px -264px; }
.emoji-1f505 { background-position: -1782px -2178px; }
.emoji-1f506 { background-position: -1782px -2244px; }
.emoji-1f4f6 { background-position: -1782px -1254px; }
.emoji-1f4f3 { background-position: -1782px -1056px; }
.emoji-1f4f4 { background-position: -1782px -1122px; }
.emoji-2640-fe0f { background-position: -3564px -1518px; }
.emoji-2642-fe0f { background-position: -3564px -1584px; }
.emoji-26a7-fe0f { background-position: -3564px -3762px; }
.emoji-2716-fe0f { background-position: -3696px -1122px; }
.emoji-2795 { background-position: -3696px -2178px; }
.emoji-2796 { background-position: -3696px -2244px; }
.emoji-2797 { background-position: -3696px -2310px; }
.emoji-267e-fe0f { background-position: -3564px -2904px; }
.emoji-203c-fe0f { background-position: -3498px -1056px; }
.emoji-2049-fe0f { background-position: -3498px -1122px; }
.emoji-2753 { background-position: -3696px -1782px; }
.emoji-2754 { background-position: -3696px -1848px; }
.emoji-2755 { background-position: -3696px -1914px; }
.emoji-2757 { background-position: -3696px -1980px; }
.emoji-3030-fe0f { background-position: -3696px -3168px; }
.emoji-1f4b1 { background-position: -1716px -528px; }
.emoji-1f4b2 { background-position: -1716px -594px; }
.emoji-2695-fe0f { background-position: -3564px -3234px; }
.emoji-267b-fe0f { background-position: -3564px -2838px; }
.emoji-269c-fe0f { background-position: -3564px -3564px; }
.emoji-1f531 { background-position: -1848px -1254px; }
.emoji-1f4db { background-position: -1716px -3300px; }
.emoji-1f530 { background-position: -1848px -1188px; }
.emoji-2b55 { background-position: -3696px -3102px; }
.emoji-2705 { background-position: -3630px -2970px; }
.emoji-2611-fe0f { background-position: -3564px -198px; }
.emoji-2714-fe0f { background-position: -3696px -1056px; }
.emoji-274c { background-position: -3696px -1650px; }
.emoji-274e { background-position: -3696px -1716px; }
.emoji-27b0 { background-position: -3696px -2442px; }
.emoji-27bf { background-position: -3696px -2508px; }
.emoji-303d-fe0f { background-position: -3696px -3234px; }
.emoji-2733-fe0f { background-position: -3696px -1386px; }
.emoji-2734-fe0f { background-position: -3696px -1452px; }
.emoji-2747-fe0f { background-position: -3696px -1584px; }
.emoji-00a9-fe0f { background-position: -0 -792px; }
.emoji-00ae-fe0f { background-position: -0 -858px; }
.emoji-2122-fe0f { background-position: -3498px -1188px; }
.emoji-0023-fe0f-20e3 { background-position: -0 -0; }
.emoji-002a-fe0f-20e3 { background-position: -0 -66px; }
.emoji-0030-fe0f-20e3 { background-position: -0 -132px; }
.emoji-0031-fe0f-20e3 { background-position: -0 -198px; }
.emoji-0032-fe0f-20e3 { background-position: -0 -264px; }
.emoji-0033-fe0f-20e3 { background-position: -0 -330px; }
.emoji-0034-fe0f-20e3 { background-position: -0 -396px; }
.emoji-0035-fe0f-20e3 { background-position: -0 -462px; }
.emoji-0036-fe0f-20e3 { background-position: -0 -528px; }
.emoji-0037-fe0f-20e3 { background-position: -0 -594px; }
.emoji-0038-fe0f-20e3 { background-position: -0 -660px; }
.emoji-0039-fe0f-20e3 { background-position: -0 -726px; }
.emoji-1f51f { background-position: -1848px -66px; }
.emoji-1f520 { background-position: -1848px -132px; }
.emoji-1f521 { background-position: -1848px -198px; }
.emoji-1f522 { background-position: -1848px -264px; }
.emoji-1f523 { background-position: -1848px -330px; }
.emoji-1f524 { background-position: -1848px -396px; }
.emoji-1f170-fe0f { background-position: -0 -1056px; }
.emoji-1f18e { background-position: -0 -1320px; }
.emoji-1f171-fe0f { background-position: -0 -1122px; }
.emoji-1f191 { background-position: -0 -1386px; }
.emoji-1f192 { background-position: -0 -1452px; }
.emoji-1f193 { background-position: -0 -1518px; }
.emoji-2139-fe0f { background-position: -3498px -1254px; }
.emoji-1f194 { background-position: -0 -1584px; }
.emoji-24c2-fe0f { background-position: -3498px -3036px; }
.emoji-1f195 { background-position: -0 -1650px; }
.emoji-1f196 { background-position: -0 -1716px; }
.emoji-1f17e-fe0f { background-position: -0 -1188px; }
.emoji-1f197 { background-position: -0 -1782px; }
.emoji-1f17f-fe0f { background-position: -0 -1254px; }
.emoji-1f198 { background-position: -0 -1848px; }
.emoji-1f199 { background-position: -0 -1914px; }
.emoji-1f19a { background-position: -0 -1980px; }
.emoji-1f201 { background-position: -264px -3762px; }
.emoji-1f202-fe0f { background-position: -330px -0; }
.emoji-1f237-fe0f { background-position: -330px -528px; }
.emoji-1f236 { background-position: -330px -462px; }
.emoji-1f22f { background-position: -330px -132px; }
.emoji-1f250 { background-position: -330px -792px; }
.emoji-1f239 { background-position: -330px -660px; }
.emoji-1f21a { background-position: -330px -66px; }
.emoji-1f232 { background-position: -330px -198px; }
.emoji-1f251 { background-position: -330px -858px; }
.emoji-1f238 { background-position: -330px -594px; }
.emoji-1f234 { background-position: -330px -330px; }
.emoji-1f233 { background-position: -330px -264px; }
.emoji-3297-fe0f { background-position: -3696px -3300px; }
.emoji-3299-fe0f { background-position: -3696px -3366px; }
.emoji-1f23a { background-position: -330px -726px; }
.emoji-1f235 { background-position: -330px -396px; }
.emoji-1f534 { background-position: -1848px -1452px; }
.emoji-1f7e0 { background-position: -2376px -3630px; }
.emoji-1f7e1 { background-position: -2376px -3696px; }
.emoji-1f7e2 { background-position: -2376px -3762px; }
.emoji-1f535 { background-position: -1848px -1518px; }
.emoji-1f7e3 { background-position: -2442px -0; }
.emoji-1f7e4 { background-position: -2442px -66px; }
.emoji-26ab { background-position: -3630px -66px; }
.emoji-26aa { background-position: -3630px -0; }
.emoji-1f7e5 { background-position: -2442px -132px; }
.emoji-1f7e7 { background-position: -2442px -264px; }
.emoji-1f7e8 { background-position: -2442px -330px; }
.emoji-1f7e9 { background-position: -2442px -396px; }
.emoji-1f7e6 { background-position: -2442px -198px; }
.emoji-1f7ea { background-position: -2442px -462px; }
.emoji-1f7eb { background-position: -2442px -528px; }
.emoji-2b1b { background-position: -3696px -2904px; }
.emoji-2b1c { background-position: -3696px -2970px; }
.emoji-25fc-fe0f { background-position: -3498px -3432px; }
.emoji-25fb-fe0f { background-position: -3498px -3366px; }
.emoji-25fe { background-position: -3498px -3564px; }
.emoji-25fd { background-position: -3498px -3498px; }
.emoji-25aa-fe0f { background-position: -3498px -3102px; }
.emoji-25ab-fe0f { background-position: -3498px -3168px; }
.emoji-1f536 { background-position: -1848px -1584px; }
.emoji-1f537 { background-position: -1848px -1650px; }
.emoji-1f538 { background-position: -1848px -1716px; }
.emoji-1f539 { background-position: -1848px -1782px; }
.emoji-1f53a { background-position: -1848px -1848px; }
.emoji-1f53b { background-position: -1848px -1914px; }
.emoji-1f4a0 { background-position: -1650px -2904px; }
.emoji-1f518 { background-position: -1782px -3432px; }
.emoji-1f533 { background-position: -1848px -1386px; }
.emoji-1f532 { background-position: -1848px -1320px; }
.emoji-1f3c1 { background-position: -528px -2046px; }
.emoji-1f6a9 { background-position: -2244px -3300px; }
.emoji-1f38c { background-position: -462px -2706px; }
.emoji-1f3f4 { background-position: -726px -462px; }
.emoji-1f3f3-fe0f { background-position: -726px -132px; }
.emoji-1f3f3-fe0f-200d-1f308 { background-position: -726px -0; }
.emoji-1f3f3-fe0f-200d-26a7-fe0f { background-position: -726px -66px; }
.emoji-1f3f4-200d-2620-fe0f { background-position: -726px -198px; }
.emoji-1f1e6-1f1e8 { background-position: -0 -2046px; }
.emoji-1f1e6-1f1e9 { background-position: -0 -2112px; }
.emoji-1f1e6-1f1ea { background-position: -0 -2178px; }
.emoji-1f1e6-1f1eb { background-position: -0 -2244px; }
.emoji-1f1e6-1f1ec { background-position: -0 -2310px; }
.emoji-1f1e6-1f1ee { background-position: -0 -2376px; }
.emoji-1f1e6-1f1f1 { background-position: -0 -2442px; }
.emoji-1f1e6-1f1f2 { background-position: -0 -2508px; }
.emoji-1f1e6-1f1f4 { background-position: -0 -2574px; }
.emoji-1f1e6-1f1f6 { background-position: -0 -2640px; }
.emoji-1f1e6-1f1f7 { background-position: -0 -2706px; }
.emoji-1f1e6-1f1f8 { background-position: -0 -2772px; }
.emoji-1f1e6-1f1f9 { background-position: -0 -2838px; }
.emoji-1f1e6-1f1fa { background-position: -0 -2904px; }
.emoji-1f1e6-1f1fc { background-position: -0 -2970px; }
.emoji-1f1e6-1f1fd { background-position: -0 -3036px; }
.emoji-1f1e6-1f1ff { background-position: -0 -3102px; }
.emoji-1f1e7-1f1e6 { background-position: -0 -3168px; }
.emoji-1f1e7-1f1e7 { background-position: -0 -3234px; }
.emoji-1f1e7-1f1e9 { background-position: -0 -3300px; }
.emoji-1f1e7-1f1ea { background-position: -0 -3366px; }
.emoji-1f1e7-1f1eb { background-position: -0 -3432px; }
.emoji-1f1e7-1f1ec { background-position: -0 -3498px; }
.emoji-1f1e7-1f1ed { background-position: -0 -3564px; }
.emoji-1f1e7-1f1ee { background-position: -0 -3630px; }
.emoji-1f1e7-1f1ef { background-position: -0 -3696px; }
.emoji-1f1e7-1f1f1 { background-position: -0 -3762px; }
.emoji-1f1e7-1f1f2 { background-position: -66px -0; }
.emoji-1f1e7-1f1f3 { background-position: -66px -66px; }
.emoji-1f1e7-1f1f4 { background-position: -66px -132px; }
.emoji-1f1e7-1f1f6 { background-position: -66px -198px; }
.emoji-1f1e7-1f1f7 { background-position: -66px -264px; }
.emoji-1f1e7-1f1f8 { background-position: -66px -330px; }
.emoji-1f1e7-1f1f9 { background-position: -66px -396px; }
.emoji-1f1e7-1f1fb { background-position: -66px -462px; }
.emoji-1f1e7-1f1fc { background-position: -66px -528px; }
.emoji-1f1e7-1f1fe { background-position: -66px -594px; }
.emoji-1f1e7-1f1ff { background-position: -66px -660px; }
.emoji-1f1e8-1f1e6 { background-position: -66px -726px; }
.emoji-1f1e8-1f1e8 { background-position: -66px -792px; }
.emoji-1f1e8-1f1e9 { background-position: -66px -858px; }
.emoji-1f1e8-1f1eb { background-position: -66px -924px; }
.emoji-1f1e8-1f1ec { background-position: -66px -990px; }
.emoji-1f1e8-1f1ed { background-position: -66px -1056px; }
.emoji-1f1e8-1f1ee { background-position: -66px -1122px; }
.emoji-1f1e8-1f1f0 { background-position: -66px -1188px; }
.emoji-1f1e8-1f1f1 { background-position: -66px -1254px; }
.emoji-1f1e8-1f1f2 { background-position: -66px -1320px; }
.emoji-1f1e8-1f1f3 { background-position: -66px -1386px; }
.emoji-1f1e8-1f1f4 { background-position: -66px -1452px; }
.emoji-1f1e8-1f1f5 { background-position: -66px -1518px; }
.emoji-1f1e8-1f1f7 { background-position: -66px -1584px; }
.emoji-1f1e8-1f1fa { background-position: -66px -1650px; }
.emoji-1f1e8-1f1fb { background-position: -66px -1716px; }
.emoji-1f1e8-1f1fc { background-position: -66px -1782px; }
.emoji-1f1e8-1f1fd { background-position: -66px -1848px; }
.emoji-1f1e8-1f1fe { background-position: -66px -1914px; }
.emoji-1f1e8-1f1ff { background-position: -66px -1980px; }
.emoji-1f1e9-1f1ea { background-position: -66px -2046px; }
.emoji-1f1e9-1f1ec { background-position: -66px -2112px; }
.emoji-1f1e9-1f1ef { background-position: -66px -2178px; }
.emoji-1f1e9-1f1f0 { background-position: -66px -2244px; }
.emoji-1f1e9-1f1f2 { background-position: -66px -2310px; }
.emoji-1f1e9-1f1f4 { background-position: -66px -2376px; }
.emoji-1f1e9-1f1ff { background-position: -66px -2442px; }
.emoji-1f1ea-1f1e6 { background-position: -66px -2508px; }
.emoji-1f1ea-1f1e8 { background-position: -66px -2574px; }
.emoji-1f1ea-1f1ea { background-position: -66px -2640px; }
.emoji-1f1ea-1f1ec { background-position: -66px -2706px; }
.emoji-1f1ea-1f1ed { background-position: -66px -2772px; }
.emoji-1f1ea-1f1f7 { background-position: -66px -2838px; }
.emoji-1f1ea-1f1f8 { background-position: -66px -2904px; }
.emoji-1f1ea-1f1f9 { background-position: -66px -2970px; }
.emoji-1f1ea-1f1fa { background-position: -66px -3036px; }
.emoji-1f1eb-1f1ee { background-position: -66px -3102px; }
.emoji-1f1eb-1f1ef { background-position: -66px -3168px; }
.emoji-1f1eb-1f1f0 { background-position: -66px -3234px; }
.emoji-1f1eb-1f1f2 { background-position: -66px -3300px; }
.emoji-1f1eb-1f1f4 { background-position: -66px -3366px; }
.emoji-1f1eb-1f1f7 { background-position: -66px -3432px; }
.emoji-1f1ec-1f1e6 { background-position: -66px -3498px; }
.emoji-1f1ec-1f1e7 { background-position: -66px -3564px; }
.emoji-1f1ec-1f1e9 { background-position: -66px -3630px; }
.emoji-1f1ec-1f1ea { background-position: -66px -3696px; }
.emoji-1f1ec-1f1eb { background-position: -66px -3762px; }
.emoji-1f1ec-1f1ec { background-position: -132px -0; }
.emoji-1f1ec-1f1ed { background-position: -132px -66px; }
.emoji-1f1ec-1f1ee { background-position: -132px -132px; }
.emoji-1f1ec-1f1f1 { background-position: -132px -198px; }
.emoji-1f1ec-1f1f2 { background-position: -132px -264px; }
.emoji-1f1ec-1f1f3 { background-position: -132px -330px; }
.emoji-1f1ec-1f1f5 { background-position: -132px -396px; }
.emoji-1f1ec-1f1f6 { background-position: -132px -462px; }
.emoji-1f1ec-1f1f7 { background-position: -132px -528px; }
.emoji-1f1ec-1f1f8 { background-position: -132px -594px; }
.emoji-1f1ec-1f1f9 { background-position: -132px -660px; }
.emoji-1f1ec-1f1fa { background-position: -132px -726px; }
.emoji-1f1ec-1f1fc { background-position: -132px -792px; }
.emoji-1f1ec-1f1fe { background-position: -132px -858px; }
.emoji-1f1ed-1f1f0 { background-position: -132px -924px; }
.emoji-1f1ed-1f1f2 { background-position: -132px -990px; }
.emoji-1f1ed-1f1f3 { background-position: -132px -1056px; }
.emoji-1f1ed-1f1f7 { background-position: -132px -1122px; }
.emoji-1f1ed-1f1f9 { background-position: -132px -1188px; }
.emoji-1f1ed-1f1fa { background-position: -132px -1254px; }
.emoji-1f1ee-1f1e8 { background-position: -132px -1320px; }
.emoji-1f1ee-1f1e9 { background-position: -132px -1386px; }
.emoji-1f1ee-1f1ea { background-position: -132px -1452px; }
.emoji-1f1ee-1f1f1 { background-position: -132px -1518px; }
.emoji-1f1ee-1f1f2 { background-position: -132px -1584px; }
.emoji-1f1ee-1f1f3 { background-position: -132px -1650px; }
.emoji-1f1ee-1f1f4 { background-position: -132px -1716px; }
.emoji-1f1ee-1f1f6 { background-position: -132px -1782px; }
.emoji-1f1ee-1f1f7 { background-position: -132px -1848px; }
.emoji-1f1ee-1f1f8 { background-position: -132px -1914px; }
.emoji-1f1ee-1f1f9 { background-position: -132px -1980px; }
.emoji-1f1ef-1f1ea { background-position: -132px -2046px; }
.emoji-1f1ef-1f1f2 { background-position: -132px -2112px; }
.emoji-1f1ef-1f1f4 { background-position: -132px -2178px; }
.emoji-1f1ef-1f1f5 { background-position: -132px -2244px; }
.emoji-1f1f0-1f1ea { background-position: -132px -2310px; }
.emoji-1f1f0-1f1ec { background-position: -132px -2376px; }
.emoji-1f1f0-1f1ed { background-position: -132px -2442px; }
.emoji-1f1f0-1f1ee { background-position: -132px -2508px; }
.emoji-1f1f0-1f1f2 { background-position: -132px -2574px; }
.emoji-1f1f0-1f1f3 { background-position: -132px -2640px; }
.emoji-1f1f0-1f1f5 { background-position: -132px -2706px; }
.emoji-1f1f0-1f1f7 { background-position: -132px -2772px; }
.emoji-1f1f0-1f1fc { background-position: -132px -2838px; }
.emoji-1f1f0-1f1fe { background-position: -132px -2904px; }
.emoji-1f1f0-1f1ff { background-position: -132px -2970px; }
.emoji-1f1f1-1f1e6 { background-position: -132px -3036px; }
.emoji-1f1f1-1f1e7 { background-position: -132px -3102px; }
.emoji-1f1f1-1f1e8 { background-position: -132px -3168px; }
.emoji-1f1f1-1f1ee { background-position: -132px -3234px; }
.emoji-1f1f1-1f1f0 { background-position: -132px -3300px; }
.emoji-1f1f1-1f1f7 { background-position: -132px -3366px; }
.emoji-1f1f1-1f1f8 { background-position: -132px -3432px; }
.emoji-1f1f1-1f1f9 { background-position: -132px -3498px; }
.emoji-1f1f1-1f1fa { background-position: -132px -3564px; }
.emoji-1f1f1-1f1fb { background-position: -132px -3630px; }
.emoji-1f1f1-1f1fe { background-position: -132px -3696px; }
.emoji-1f1f2-1f1e6 { background-position: -132px -3762px; }
.emoji-1f1f2-1f1e8 { background-position: -198px -0; }
.emoji-1f1f2-1f1e9 { background-position: -198px -66px; }
.emoji-1f1f2-1f1ea { background-position: -198px -132px; }
.emoji-1f1f2-1f1eb { background-position: -198px -198px; }
.emoji-1f1f2-1f1ec { background-position: -198px -264px; }
.emoji-1f1f2-1f1ed { background-position: -198px -330px; }
.emoji-1f1f2-1f1f0 { background-position: -198px -396px; }
.emoji-1f1f2-1f1f1 { background-position: -198px -462px; }
.emoji-1f1f2-1f1f2 { background-position: -198px -528px; }
.emoji-1f1f2-1f1f3 { background-position: -198px -594px; }
.emoji-1f1f2-1f1f4 { background-position: -198px -660px; }
.emoji-1f1f2-1f1f5 { background-position: -198px -726px; }
.emoji-1f1f2-1f1f6 { background-position: -198px -792px; }
.emoji-1f1f2-1f1f7 { background-position: -198px -858px; }
.emoji-1f1f2-1f1f8 { background-position: -198px -924px; }
.emoji-1f1f2-1f1f9 { background-position: -198px -990px; }
.emoji-1f1f2-1f1fa { background-position: -198px -1056px; }
.emoji-1f1f2-1f1fb { background-position: -198px -1122px; }
.emoji-1f1f2-1f1fc { background-position: -198px -1188px; }
.emoji-1f1f2-1f1fd { background-position: -198px -1254px; }
.emoji-1f1f2-1f1fe { background-position: -198px -1320px; }
.emoji-1f1f2-1f1ff { background-position: -198px -1386px; }
.emoji-1f1f3-1f1e6 { background-position: -198px -1452px; }
.emoji-1f1f3-1f1e8 { background-position: -198px -1518px; }
.emoji-1f1f3-1f1ea { background-position: -198px -1584px; }
.emoji-1f1f3-1f1eb { background-position: -198px -1650px; }
.emoji-1f1f3-1f1ec { background-position: -198px -1716px; }
.emoji-1f1f3-1f1ee { background-position: -198px -1782px; }
.emoji-1f1f3-1f1f1 { background-position: -198px -1848px; }
.emoji-1f1f3-1f1f4 { background-position: -198px -1914px; }
.emoji-1f1f3-1f1f5 { background-position: -198px -1980px; }
.emoji-1f1f3-1f1f7 { background-position: -198px -2046px; }
.emoji-1f1f3-1f1fa { background-position: -198px -2112px; }
.emoji-1f1f3-1f1ff { background-position: -198px -2178px; }
.emoji-1f1f4-1f1f2 { background-position: -198px -2244px; }
.emoji-1f1f5-1f1e6 { background-position: -198px -2310px; }
.emoji-1f1f5-1f1ea { background-position: -198px -2376px; }
.emoji-1f1f5-1f1eb { background-position: -198px -2442px; }
.emoji-1f1f5-1f1ec { background-position: -198px -2508px; }
.emoji-1f1f5-1f1ed { background-position: -198px -2574px; }
.emoji-1f1f5-1f1f0 { background-position: -198px -2640px; }
.emoji-1f1f5-1f1f1 { background-position: -198px -2706px; }
.emoji-1f1f5-1f1f2 { background-position: -198px -2772px; }
.emoji-1f1f5-1f1f3 { background-position: -198px -2838px; }
.emoji-1f1f5-1f1f7 { background-position: -198px -2904px; }
.emoji-1f1f5-1f1f8 { background-position: -198px -2970px; }
.emoji-1f1f5-1f1f9 { background-position: -198px -3036px; }
.emoji-1f1f5-1f1fc { background-position: -198px -3102px; }
.emoji-1f1f5-1f1fe { background-position: -198px -3168px; }
.emoji-1f1f6-1f1e6 { background-position: -198px -3234px; }
.emoji-1f1f7-1f1ea { background-position: -198px -3300px; }
.emoji-1f1f7-1f1f4 { background-position: -198px -3366px; }
.emoji-1f1f7-1f1f8 { background-position: -198px -3432px; }
.emoji-1f1f7-1f1fa { background-position: -198px -3498px; }
.emoji-1f1f7-1f1fc { background-position: -198px -3564px; }
.emoji-1f1f8-1f1e6 { background-position: -198px -3630px; }
.emoji-1f1f8-1f1e7 { background-position: -198px -3696px; }
.emoji-1f1f8-1f1e8 { background-position: -198px -3762px; }
.emoji-1f1f8-1f1e9 { background-position: -264px -0; }
.emoji-1f1f8-1f1ea { background-position: -264px -66px; }
.emoji-1f1f8-1f1ec { background-position: -264px -132px; }
.emoji-1f1f8-1f1ed { background-position: -264px -198px; }
.emoji-1f1f8-1f1ee { background-position: -264px -264px; }
.emoji-1f1f8-1f1ef { background-position: -264px -330px; }
.emoji-1f1f8-1f1f0 { background-position: -264px -396px; }
.emoji-1f1f8-1f1f1 { background-position: -264px -462px; }
.emoji-1f1f8-1f1f2 { background-position: -264px -528px; }
.emoji-1f1f8-1f1f3 { background-position: -264px -594px; }
.emoji-1f1f8-1f1f4 { background-position: -264px -660px; }
.emoji-1f1f8-1f1f7 { background-position: -264px -726px; }
.emoji-1f1f8-1f1f8 { background-position: -264px -792px; }
.emoji-1f1f8-1f1f9 { background-position: -264px -858px; }
.emoji-1f1f8-1f1fb { background-position: -264px -924px; }
.emoji-1f1f8-1f1fd { background-position: -264px -990px; }
.emoji-1f1f8-1f1fe { background-position: -264px -1056px; }
.emoji-1f1f8-1f1ff { background-position: -264px -1122px; }
.emoji-1f1f9-1f1e6 { background-position: -264px -1188px; }
.emoji-1f1f9-1f1e8 { background-position: -264px -1254px; }
.emoji-1f1f9-1f1e9 { background-position: -264px -1320px; }
.emoji-1f1f9-1f1eb { background-position: -264px -1386px; }
.emoji-1f1f9-1f1ec { background-position: -264px -1452px; }
.emoji-1f1f9-1f1ed { background-position: -264px -1518px; }
.emoji-1f1f9-1f1ef { background-position: -264px -1584px; }
.emoji-1f1f9-1f1f0 { background-position: -264px -1650px; }
.emoji-1f1f9-1f1f1 { background-position: -264px -1716px; }
.emoji-1f1f9-1f1f2 { background-position: -264px -1782px; }
.emoji-1f1f9-1f1f3 { background-position: -264px -1848px; }
.emoji-1f1f9-1f1f4 { background-position: -264px -1914px; }
.emoji-1f1f9-1f1f7 { background-position: -264px -1980px; }
.emoji-1f1f9-1f1f9 { background-position: -264px -2046px; }
.emoji-1f1f9-1f1fb { background-position: -264px -2112px; }
.emoji-1f1f9-1f1fc { background-position: -264px -2178px; }
.emoji-1f1f9-1f1ff { background-position: -264px -2244px; }
.emoji-1f1fa-1f1e6 { background-position: -264px -2310px; }
.emoji-1f1fa-1f1ec { background-position: -264px -2376px; }
.emoji-1f1fa-1f1f2 { background-position: -264px -2442px; }
.emoji-1f1fa-1f1f3 { background-position: -264px -2508px; }
.emoji-1f1fa-1f1f8 { background-position: -264px -2574px; }
.emoji-1f1fa-1f1fe { background-position: -264px -2640px; }
.emoji-1f1fa-1f1ff { background-position: -264px -2706px; }
.emoji-1f1fb-1f1e6 { background-position: -264px -2772px; }
.emoji-1f1fb-1f1e8 { background-position: -264px -2838px; }
.emoji-1f1fb-1f1ea { background-position: -264px -2904px; }
.emoji-1f1fb-1f1ec { background-position: -264px -2970px; }
.emoji-1f1fb-1f1ee { background-position: -264px -3036px; }
.emoji-1f1fb-1f1f3 { background-position: -264px -3102px; }
.emoji-1f1fb-1f1fa { background-position: -264px -3168px; }
.emoji-1f1fc-1f1eb { background-position: -264px -3234px; }
.emoji-1f1fc-1f1f8 { background-position: -264px -3300px; }
.emoji-1f1fd-1f1f0 { background-position: -264px -3366px; }
.emoji-1f1fe-1f1ea { background-position: -264px -3432px; }
.emoji-1f1fe-1f1f9 { background-position: -264px -3498px; }
.emoji-1f1ff-1f1e6 { background-position: -264px -3564px; }
.emoji-1f1ff-1f1f2 { background-position: -264px -3630px; }
.emoji-1f1ff-1f1fc { background-position: -264px -3696px; }
.emoji-1f3f4-e0067-e0062-e0065-e006e-e0067-e007f { background-position: -726px -264px; }
.emoji-1f3f4-e0067-e0062-e0073-e0063-e0074-e007f { background-position: -726px -330px; }
.emoji-1f3f4-e0067-e0062-e0077-e006c-e0073-e007f { background-position: -726px -396px; }
.emoji-1f385-1f3fb { background-position: -462px -1980px; }
.emoji-1f385-1f3fc { background-position: -462px -2046px; }
.emoji-1f385-1f3fd { background-position: -462px -2112px; }
.emoji-1f385-1f3fe { background-position: -462px -2178px; }
.emoji-1f385-1f3ff { background-position: -462px -2244px; }
.emoji-1f3c2-1f3fb { background-position: -528px -2178px; }
.emoji-1f3c2-1f3fc { background-position: -528px -2244px; }
.emoji-1f3c2-1f3fd { background-position: -528px -2310px; }
.emoji-1f3c2-1f3fe { background-position: -528px -2376px; }
.emoji-1f3c2-1f3ff { background-position: -528px -2442px; }
.emoji-1f3c3-1f3fb-200d-2640-fe0f { background-position: -528px -2574px; }
.emoji-1f3c3-1f3fc-200d-2640-fe0f { background-position: -528px -2640px; }
.emoji-1f3c3-1f3fd-200d-2640-fe0f { background-position: -528px -2706px; }
.emoji-1f3c3-1f3fe-200d-2640-fe0f { background-position: -528px -2772px; }
.emoji-1f3c3-1f3ff-200d-2640-fe0f { background-position: -528px -2838px; }
.emoji-1f3c3-1f3fb-200d-2642-fe0f { background-position: -528px -2970px; }
.emoji-1f3c3-1f3fc-200d-2642-fe0f { background-position: -528px -3036px; }
.emoji-1f3c3-1f3fd-200d-2642-fe0f { background-position: -528px -3102px; }
.emoji-1f3c3-1f3fe-200d-2642-fe0f { background-position: -528px -3168px; }
.emoji-1f3c3-1f3ff-200d-2642-fe0f { background-position: -528px -3234px; }
.emoji-1f3c3-1f3fb { background-position: -528px -3366px; }
.emoji-1f3c3-1f3fc { background-position: -528px -3432px; }
.emoji-1f3c3-1f3fd { background-position: -528px -3498px; }
.emoji-1f3c3-1f3fe { background-position: -528px -3564px; }
.emoji-1f3c3-1f3ff { background-position: -528px -3630px; }
.emoji-1f3c4-1f3fb-200d-2640-fe0f { background-position: -528px -3762px; }
.emoji-1f3c4-1f3fc-200d-2640-fe0f { background-position: -594px -0; }
.emoji-1f3c4-1f3fd-200d-2640-fe0f { background-position: -594px -66px; }
.emoji-1f3c4-1f3fe-200d-2640-fe0f { background-position: -594px -132px; }
.emoji-1f3c4-1f3ff-200d-2640-fe0f { background-position: -594px -198px; }
.emoji-1f3c4-1f3fb-200d-2642-fe0f { background-position: -594px -330px; }
.emoji-1f3c4-1f3fc-200d-2642-fe0f { background-position: -594px -396px; }
.emoji-1f3c4-1f3fd-200d-2642-fe0f { background-position: -594px -462px; }
.emoji-1f3c4-1f3fe-200d-2642-fe0f { background-position: -594px -528px; }
.emoji-1f3c4-1f3ff-200d-2642-fe0f { background-position: -594px -594px; }
.emoji-1f3c4-1f3fb { background-position: -594px -726px; }
.emoji-1f3c4-1f3fc { background-position: -594px -792px; }
.emoji-1f3c4-1f3fd { background-position: -594px -858px; }
.emoji-1f3c4-1f3fe { background-position: -594px -924px; }
.emoji-1f3c4-1f3ff { background-position: -594px -990px; }
.emoji-1f3c7-1f3fb { background-position: -594px -1254px; }
.emoji-1f3c7-1f3fc { background-position: -594px -1320px; }
.emoji-1f3c7-1f3fd { background-position: -594px -1386px; }
.emoji-1f3c7-1f3fe { background-position: -594px -1452px; }
.emoji-1f3c7-1f3ff { background-position: -594px -1518px; }
.emoji-1f3ca-1f3fb-200d-2640-fe0f { background-position: -594px -1782px; }
.emoji-1f3ca-1f3fc-200d-2640-fe0f { background-position: -594px -1848px; }
.emoji-1f3ca-1f3fd-200d-2640-fe0f { background-position: -594px -1914px; }
.emoji-1f3ca-1f3fe-200d-2640-fe0f { background-position: -594px -1980px; }
.emoji-1f3ca-1f3ff-200d-2640-fe0f { background-position: -594px -2046px; }
.emoji-1f3ca-1f3fb-200d-2642-fe0f { background-position: -594px -2178px; }
.emoji-1f3ca-1f3fc-200d-2642-fe0f { background-position: -594px -2244px; }
.emoji-1f3ca-1f3fd-200d-2642-fe0f { background-position: -594px -2310px; }
.emoji-1f3ca-1f3fe-200d-2642-fe0f { background-position: -594px -2376px; }
.emoji-1f3ca-1f3ff-200d-2642-fe0f { background-position: -594px -2442px; }
.emoji-1f3ca-1f3fb { background-position: -594px -2574px; }
.emoji-1f3ca-1f3fc { background-position: -594px -2640px; }
.emoji-1f3ca-1f3fd { background-position: -594px -2706px; }
.emoji-1f3ca-1f3fe { background-position: -594px -2772px; }
.emoji-1f3ca-1f3ff { background-position: -594px -2838px; }
.emoji-1f3cb-1f3fb-200d-2640-fe0f { background-position: -594px -2970px; }
.emoji-1f3cb-1f3fc-200d-2640-fe0f { background-position: -594px -3036px; }
.emoji-1f3cb-1f3fd-200d-2640-fe0f { background-position: -594px -3102px; }
.emoji-1f3cb-1f3fe-200d-2640-fe0f { background-position: -594px -3168px; }
.emoji-1f3cb-1f3ff-200d-2640-fe0f { background-position: -594px -3234px; }
.emoji-1f3cb-1f3fb-200d-2642-fe0f { background-position: -594px -3366px; }
.emoji-1f3cb-1f3fc-200d-2642-fe0f { background-position: -594px -3432px; }
.emoji-1f3cb-1f3fd-200d-2642-fe0f { background-position: -594px -3498px; }
.emoji-1f3cb-1f3fe-200d-2642-fe0f { background-position: -594px -3564px; }
.emoji-1f3cb-1f3ff-200d-2642-fe0f { background-position: -594px -3630px; }
.emoji-1f3cb-1f3fb { background-position: -594px -3762px; }
.emoji-1f3cb-1f3fc { background-position: -660px -0; }
.emoji-1f3cb-1f3fd { background-position: -660px -66px; }
.emoji-1f3cb-1f3fe { background-position: -660px -132px; }
.emoji-1f3cb-1f3ff { background-position: -660px -198px; }
.emoji-1f3cc-1f3fb-200d-2640-fe0f { background-position: -660px -330px; }
.emoji-1f3cc-1f3fc-200d-2640-fe0f { background-position: -660px -396px; }
.emoji-1f3cc-1f3fd-200d-2640-fe0f { background-position: -660px -462px; }
.emoji-1f3cc-1f3fe-200d-2640-fe0f { background-position: -660px -528px; }
.emoji-1f3cc-1f3ff-200d-2640-fe0f { background-position: -660px -594px; }
.emoji-1f3cc-1f3fb-200d-2642-fe0f { background-position: -660px -726px; }
.emoji-1f3cc-1f3fc-200d-2642-fe0f { background-position: -660px -792px; }
.emoji-1f3cc-1f3fd-200d-2642-fe0f { background-position: -660px -858px; }
.emoji-1f3cc-1f3fe-200d-2642-fe0f { background-position: -660px -924px; }
.emoji-1f3cc-1f3ff-200d-2642-fe0f { background-position: -660px -990px; }
.emoji-1f3cc-1f3fb { background-position: -660px -1122px; }
.emoji-1f3cc-1f3fc { background-position: -660px -1188px; }
.emoji-1f3cc-1f3fd { background-position: -660px -1254px; }
.emoji-1f3cc-1f3fe { background-position: -660px -1320px; }
.emoji-1f3cc-1f3ff { background-position: -660px -1386px; }
.emoji-1f442-1f3fb { background-position: -792px -2046px; }
.emoji-1f442-1f3fc { background-position: -792px -2112px; }
.emoji-1f442-1f3fd { background-position: -792px -2178px; }
.emoji-1f442-1f3fe { background-position: -792px -2244px; }
.emoji-1f442-1f3ff { background-position: -792px -2310px; }
.emoji-1f443-1f3fb { background-position: -792px -2442px; }
.emoji-1f443-1f3fc { background-position: -792px -2508px; }
.emoji-1f443-1f3fd { background-position: -792px -2574px; }
.emoji-1f443-1f3fe { background-position: -792px -2640px; }
.emoji-1f443-1f3ff { background-position: -792px -2706px; }
.emoji-1f446-1f3fb { background-position: -792px -2970px; }
.emoji-1f446-1f3fc { background-position: -792px -3036px; }
.emoji-1f446-1f3fd { background-position: -792px -3102px; }
.emoji-1f446-1f3fe { background-position: -792px -3168px; }
.emoji-1f446-1f3ff { background-position: -792px -3234px; }
.emoji-1f447-1f3fb { background-position: -792px -3366px; }
.emoji-1f447-1f3fc { background-position: -792px -3432px; }
.emoji-1f447-1f3fd { background-position: -792px -3498px; }
.emoji-1f447-1f3fe { background-position: -792px -3564px; }
.emoji-1f447-1f3ff { background-position: -792px -3630px; }
.emoji-1f448-1f3fb { background-position: -792px -3762px; }
.emoji-1f448-1f3fc { background-position: -858px -0; }
.emoji-1f448-1f3fd { background-position: -858px -66px; }
.emoji-1f448-1f3fe { background-position: -858px -132px; }
.emoji-1f448-1f3ff { background-position: -858px -198px; }
.emoji-1f449-1f3fb { background-position: -858px -330px; }
.emoji-1f449-1f3fc { background-position: -858px -396px; }
.emoji-1f449-1f3fd { background-position: -858px -462px; }
.emoji-1f449-1f3fe { background-position: -858px -528px; }
.emoji-1f449-1f3ff { background-position: -858px -594px; }
.emoji-1f44a-1f3fb { background-position: -858px -726px; }
.emoji-1f44a-1f3fc { background-position: -858px -792px; }
.emoji-1f44a-1f3fd { background-position: -858px -858px; }
.emoji-1f44a-1f3fe { background-position: -858px -924px; }
.emoji-1f44a-1f3ff { background-position: -858px -990px; }
.emoji-1f44b-1f3fb { background-position: -858px -1122px; }
.emoji-1f44b-1f3fc { background-position: -858px -1188px; }
.emoji-1f44b-1f3fd { background-position: -858px -1254px; }
.emoji-1f44b-1f3fe { background-position: -858px -1320px; }
.emoji-1f44b-1f3ff { background-position: -858px -1386px; }
.emoji-1f44c-1f3fb { background-position: -858px -1518px; }
.emoji-1f44c-1f3fc { background-position: -858px -1584px; }
.emoji-1f44c-1f3fd { background-position: -858px -1650px; }
.emoji-1f44c-1f3fe { background-position: -858px -1716px; }
.emoji-1f44c-1f3ff { background-position: -858px -1782px; }
.emoji-1f44d-1f3fb { background-position: -858px -1914px; }
.emoji-1f44d-1f3fc { background-position: -858px -1980px; }
.emoji-1f44d-1f3fd { background-position: -858px -2046px; }
.emoji-1f44d-1f3fe { background-position: -858px -2112px; }
.emoji-1f44d-1f3ff { background-position: -858px -2178px; }
.emoji-1f44e-1f3fb { background-position: -858px -2310px; }
.emoji-1f44e-1f3fc { background-position: -858px -2376px; }
.emoji-1f44e-1f3fd { background-position: -858px -2442px; }
.emoji-1f44e-1f3fe { background-position: -858px -2508px; }
.emoji-1f44e-1f3ff { background-position: -858px -2574px; }
.emoji-1f44f-1f3fb { background-position: -858px -2706px; }
.emoji-1f44f-1f3fc { background-position: -858px -2772px; }
.emoji-1f44f-1f3fd { background-position: -858px -2838px; }
.emoji-1f44f-1f3fe { background-position: -858px -2904px; }
.emoji-1f44f-1f3ff { background-position: -858px -2970px; }
.emoji-1f450-1f3fb { background-position: -858px -3102px; }
.emoji-1f450-1f3fc { background-position: -858px -3168px; }
.emoji-1f450-1f3fd { background-position: -858px -3234px; }
.emoji-1f450-1f3fe { background-position: -858px -3300px; }
.emoji-1f450-1f3ff { background-position: -858px -3366px; }
.emoji-1f466-1f3fb { background-position: -924px -1056px; }
.emoji-1f466-1f3fc { background-position: -924px -1122px; }
.emoji-1f466-1f3fd { background-position: -924px -1188px; }
.emoji-1f466-1f3fe { background-position: -924px -1254px; }
.emoji-1f466-1f3ff { background-position: -924px -1320px; }
.emoji-1f467-1f3fb { background-position: -924px -1452px; }
.emoji-1f467-1f3fc { background-position: -924px -1518px; }
.emoji-1f467-1f3fd { background-position: -924px -1584px; }
.emoji-1f467-1f3fe { background-position: -924px -1650px; }
.emoji-1f467-1f3ff { background-position: -924px -1716px; }
.emoji-1f468-1f3fb-200d-1f33e { background-position: -924px -1848px; }
.emoji-1f468-1f3fc-200d-1f33e { background-position: -924px -1914px; }
.emoji-1f468-1f3fd-200d-1f33e { background-position: -924px -1980px; }
.emoji-1f468-1f3fe-200d-1f33e { background-position: -924px -2046px; }
.emoji-1f468-1f3ff-200d-1f33e { background-position: -924px -2112px; }
.emoji-1f468-1f3fb-200d-1f373 { background-position: -924px -2244px; }
.emoji-1f468-1f3fc-200d-1f373 { background-position: -924px -2310px; }
.emoji-1f468-1f3fd-200d-1f373 { background-position: -924px -2376px; }
.emoji-1f468-1f3fe-200d-1f373 { background-position: -924px -2442px; }
.emoji-1f468-1f3ff-200d-1f373 { background-position: -924px -2508px; }
.emoji-1f468-1f3fb-200d-1f37c { background-position: -924px -2640px; }
.emoji-1f468-1f3fc-200d-1f37c { background-position: -924px -2706px; }
.emoji-1f468-1f3fd-200d-1f37c { background-position: -924px -2772px; }
.emoji-1f468-1f3fe-200d-1f37c { background-position: -924px -2838px; }
.emoji-1f468-1f3ff-200d-1f37c { background-position: -924px -2904px; }
.emoji-1f468-1f3fb-200d-1f393 { background-position: -924px -3036px; }
.emoji-1f468-1f3fc-200d-1f393 { background-position: -924px -3102px; }
.emoji-1f468-1f3fd-200d-1f393 { background-position: -924px -3168px; }
.emoji-1f468-1f3fe-200d-1f393 { background-position: -924px -3234px; }
.emoji-1f468-1f3ff-200d-1f393 { background-position: -924px -3300px; }
.emoji-1f468-1f3fb-200d-1f3a4 { background-position: -924px -3432px; }
.emoji-1f468-1f3fc-200d-1f3a4 { background-position: -924px -3498px; }
.emoji-1f468-1f3fd-200d-1f3a4 { background-position: -924px -3564px; }
.emoji-1f468-1f3fe-200d-1f3a4 { background-position: -924px -3630px; }
.emoji-1f468-1f3ff-200d-1f3a4 { background-position: -924px -3696px; }
.emoji-1f468-1f3fb-200d-1f3a8 { background-position: -990px -0; }
.emoji-1f468-1f3fc-200d-1f3a8 { background-position: -990px -66px; }
.emoji-1f468-1f3fd-200d-1f3a8 { background-position: -990px -132px; }
.emoji-1f468-1f3fe-200d-1f3a8 { background-position: -990px -198px; }
.emoji-1f468-1f3ff-200d-1f3a8 { background-position: -990px -264px; }
.emoji-1f468-1f3fb-200d-1f3eb { background-position: -990px -396px; }
.emoji-1f468-1f3fc-200d-1f3eb { background-position: -990px -462px; }
.emoji-1f468-1f3fd-200d-1f3eb { background-position: -990px -528px; }
.emoji-1f468-1f3fe-200d-1f3eb { background-position: -990px -594px; }
.emoji-1f468-1f3ff-200d-1f3eb { background-position: -990px -660px; }
.emoji-1f468-1f3fb-200d-1f3ed { background-position: -990px -792px; }
.emoji-1f468-1f3fc-200d-1f3ed { background-position: -990px -858px; }
.emoji-1f468-1f3fd-200d-1f3ed { background-position: -990px -924px; }
.emoji-1f468-1f3fe-200d-1f3ed { background-position: -990px -990px; }
.emoji-1f468-1f3ff-200d-1f3ed { background-position: -990px -1056px; }
.emoji-1f468-1f3fb-200d-1f4bb { background-position: -990px -2178px; }
.emoji-1f468-1f3fc-200d-1f4bb { background-position: -990px -2244px; }
.emoji-1f468-1f3fd-200d-1f4bb { background-position: -990px -2310px; }
.emoji-1f468-1f3fe-200d-1f4bb { background-position: -990px -2376px; }
.emoji-1f468-1f3ff-200d-1f4bb { background-position: -990px -2442px; }
.emoji-1f468-1f3fb-200d-1f4bc { background-position: -990px -2574px; }
.emoji-1f468-1f3fc-200d-1f4bc { background-position: -990px -2640px; }
.emoji-1f468-1f3fd-200d-1f4bc { background-position: -990px -2706px; }
.emoji-1f468-1f3fe-200d-1f4bc { background-position: -990px -2772px; }
.emoji-1f468-1f3ff-200d-1f4bc { background-position: -990px -2838px; }
.emoji-1f468-1f3fb-200d-1f527 { background-position: -990px -2970px; }
.emoji-1f468-1f3fc-200d-1f527 { background-position: -990px -3036px; }
.emoji-1f468-1f3fd-200d-1f527 { background-position: -990px -3102px; }
.emoji-1f468-1f3fe-200d-1f527 { background-position: -990px -3168px; }
.emoji-1f468-1f3ff-200d-1f527 { background-position: -990px -3234px; }
.emoji-1f468-1f3fb-200d-1f52c { background-position: -990px -3366px; }
.emoji-1f468-1f3fc-200d-1f52c { background-position: -990px -3432px; }
.emoji-1f468-1f3fd-200d-1f52c { background-position: -990px -3498px; }
.emoji-1f468-1f3fe-200d-1f52c { background-position: -990px -3564px; }
.emoji-1f468-1f3ff-200d-1f52c { background-position: -990px -3630px; }
.emoji-1f468-1f3fb-200d-1f680 { background-position: -990px -3762px; }
.emoji-1f468-1f3fc-200d-1f680 { background-position: -1056px -0; }
.emoji-1f468-1f3fd-200d-1f680 { background-position: -1056px -66px; }
.emoji-1f468-1f3fe-200d-1f680 { background-position: -1056px -132px; }
.emoji-1f468-1f3ff-200d-1f680 { background-position: -1056px -198px; }
.emoji-1f468-1f3fb-200d-1f692 { background-position: -1056px -330px; }
.emoji-1f468-1f3fc-200d-1f692 { background-position: -1056px -396px; }
.emoji-1f468-1f3fd-200d-1f692 { background-position: -1056px -462px; }
.emoji-1f468-1f3fe-200d-1f692 { background-position: -1056px -528px; }
.emoji-1f468-1f3ff-200d-1f692 { background-position: -1056px -594px; }
.emoji-1f468-1f3fb-200d-1f9af { background-position: -1056px -726px; }
.emoji-1f468-1f3fc-200d-1f9af { background-position: -1056px -792px; }
.emoji-1f468-1f3fd-200d-1f9af { background-position: -1056px -858px; }
.emoji-1f468-1f3fe-200d-1f9af { background-position: -1056px -924px; }
.emoji-1f468-1f3ff-200d-1f9af { background-position: -1056px -990px; }
.emoji-1f468-1f3fb-200d-1f9b0 { background-position: -1056px -1122px; }
.emoji-1f468-1f3fc-200d-1f9b0 { background-position: -1056px -1188px; }
.emoji-1f468-1f3fd-200d-1f9b0 { background-position: -1056px -1254px; }
.emoji-1f468-1f3fe-200d-1f9b0 { background-position: -1056px -1320px; }
.emoji-1f468-1f3ff-200d-1f9b0 { background-position: -1056px -1386px; }
.emoji-1f468-1f3fb-200d-1f9b1 { background-position: -1056px -1518px; }
.emoji-1f468-1f3fc-200d-1f9b1 { background-position: -1056px -1584px; }
.emoji-1f468-1f3fd-200d-1f9b1 { background-position: -1056px -1650px; }
.emoji-1f468-1f3fe-200d-1f9b1 { background-position: -1056px -1716px; }
.emoji-1f468-1f3ff-200d-1f9b1 { background-position: -1056px -1782px; }
.emoji-1f468-1f3fb-200d-1f9b2 { background-position: -1056px -1914px; }
.emoji-1f468-1f3fc-200d-1f9b2 { background-position: -1056px -1980px; }
.emoji-1f468-1f3fd-200d-1f9b2 { background-position: -1056px -2046px; }
.emoji-1f468-1f3fe-200d-1f9b2 { background-position: -1056px -2112px; }
.emoji-1f468-1f3ff-200d-1f9b2 { background-position: -1056px -2178px; }
.emoji-1f468-1f3fb-200d-1f9b3 { background-position: -1056px -2310px; }
.emoji-1f468-1f3fc-200d-1f9b3 { background-position: -1056px -2376px; }
.emoji-1f468-1f3fd-200d-1f9b3 { background-position: -1056px -2442px; }
.emoji-1f468-1f3fe-200d-1f9b3 { background-position: -1056px -2508px; }
.emoji-1f468-1f3ff-200d-1f9b3 { background-position: -1056px -2574px; }
.emoji-1f468-1f3fb-200d-1f9bc { background-position: -1056px -2706px; }
.emoji-1f468-1f3fc-200d-1f9bc { background-position: -1056px -2772px; }
.emoji-1f468-1f3fd-200d-1f9bc { background-position: -1056px -2838px; }
.emoji-1f468-1f3fe-200d-1f9bc { background-position: -1056px -2904px; }
.emoji-1f468-1f3ff-200d-1f9bc { background-position: -1056px -2970px; }
.emoji-1f468-1f3fb-200d-1f9bd { background-position: -1056px -3102px; }
.emoji-1f468-1f3fc-200d-1f9bd { background-position: -1056px -3168px; }
.emoji-1f468-1f3fd-200d-1f9bd { background-position: -1056px -3234px; }
.emoji-1f468-1f3fe-200d-1f9bd { background-position: -1056px -3300px; }
.emoji-1f468-1f3ff-200d-1f9bd { background-position: -1056px -3366px; }
.emoji-1f468-1f3fb-200d-2695-fe0f { background-position: -1056px -3498px; }
.emoji-1f468-1f3fc-200d-2695-fe0f { background-position: -1056px -3564px; }
.emoji-1f468-1f3fd-200d-2695-fe0f { background-position: -1056px -3630px; }
.emoji-1f468-1f3fe-200d-2695-fe0f { background-position: -1056px -3696px; }
.emoji-1f468-1f3ff-200d-2695-fe0f { background-position: -1056px -3762px; }
.emoji-1f468-1f3fb-200d-2696-fe0f { background-position: -1122px -66px; }
.emoji-1f468-1f3fc-200d-2696-fe0f { background-position: -1122px -132px; }
.emoji-1f468-1f3fd-200d-2696-fe0f { background-position: -1122px -198px; }
.emoji-1f468-1f3fe-200d-2696-fe0f { background-position: -1122px -264px; }
.emoji-1f468-1f3ff-200d-2696-fe0f { background-position: -1122px -330px; }
.emoji-1f468-1f3fb-200d-2708-fe0f { background-position: -1122px -462px; }
.emoji-1f468-1f3fc-200d-2708-fe0f { background-position: -1122px -528px; }
.emoji-1f468-1f3fd-200d-2708-fe0f { background-position: -1122px -594px; }
.emoji-1f468-1f3fe-200d-2708-fe0f { background-position: -1122px -660px; }
.emoji-1f468-1f3ff-200d-2708-fe0f { background-position: -1122px -726px; }
.emoji-1f468-1f3fb { background-position: -1122px -990px; }
.emoji-1f468-1f3fc { background-position: -1122px -1056px; }
.emoji-1f468-1f3fd { background-position: -1122px -1122px; }
.emoji-1f468-1f3fe { background-position: -1122px -1188px; }
.emoji-1f468-1f3ff { background-position: -1122px -1254px; }
.emoji-1f469-1f3fb-200d-1f33e { background-position: -1122px -1386px; }
.emoji-1f469-1f3fc-200d-1f33e { background-position: -1122px -1452px; }
.emoji-1f469-1f3fd-200d-1f33e { background-position: -1122px -1518px; }
.emoji-1f469-1f3fe-200d-1f33e { background-position: -1122px -1584px; }
.emoji-1f469-1f3ff-200d-1f33e { background-position: -1122px -1650px; }
.emoji-1f469-1f3fb-200d-1f373 { background-position: -1122px -1782px; }
.emoji-1f469-1f3fc-200d-1f373 { background-position: -1122px -1848px; }
.emoji-1f469-1f3fd-200d-1f373 { background-position: -1122px -1914px; }
.emoji-1f469-1f3fe-200d-1f373 { background-position: -1122px -1980px; }
.emoji-1f469-1f3ff-200d-1f373 { background-position: -1122px -2046px; }
.emoji-1f469-1f3fb-200d-1f37c { background-position: -1122px -2178px; }
.emoji-1f469-1f3fc-200d-1f37c { background-position: -1122px -2244px; }
.emoji-1f469-1f3fd-200d-1f37c { background-position: -1122px -2310px; }
.emoji-1f469-1f3fe-200d-1f37c { background-position: -1122px -2376px; }
.emoji-1f469-1f3ff-200d-1f37c { background-position: -1122px -2442px; }
.emoji-1f469-1f3fb-200d-1f393 { background-position: -1122px -2574px; }
.emoji-1f469-1f3fc-200d-1f393 { background-position: -1122px -2640px; }
.emoji-1f469-1f3fd-200d-1f393 { background-position: -1122px -2706px; }
.emoji-1f469-1f3fe-200d-1f393 { background-position: -1122px -2772px; }
.emoji-1f469-1f3ff-200d-1f393 { background-position: -1122px -2838px; }
.emoji-1f469-1f3fb-200d-1f3a4 { background-position: -1122px -2970px; }
.emoji-1f469-1f3fc-200d-1f3a4 { background-position: -1122px -3036px; }
.emoji-1f469-1f3fd-200d-1f3a4 { background-position: -1122px -3102px; }
.emoji-1f469-1f3fe-200d-1f3a4 { background-position: -1122px -3168px; }
.emoji-1f469-1f3ff-200d-1f3a4 { background-position: -1122px -3234px; }
.emoji-1f469-1f3fb-200d-1f3a8 { background-position: -1122px -3366px; }
.emoji-1f469-1f3fc-200d-1f3a8 { background-position: -1122px -3432px; }
.emoji-1f469-1f3fd-200d-1f3a8 { background-position: -1122px -3498px; }
.emoji-1f469-1f3fe-200d-1f3a8 { background-position: -1122px -3564px; }
.emoji-1f469-1f3ff-200d-1f3a8 { background-position: -1122px -3630px; }
.emoji-1f469-1f3fb-200d-1f3eb { background-position: -1122px -3762px; }
.emoji-1f469-1f3fc-200d-1f3eb { background-position: -1188px -0; }
.emoji-1f469-1f3fd-200d-1f3eb { background-position: -1188px -66px; }
.emoji-1f469-1f3fe-200d-1f3eb { background-position: -1188px -132px; }
.emoji-1f469-1f3ff-200d-1f3eb { background-position: -1188px -198px; }
.emoji-1f469-1f3fb-200d-1f3ed { background-position: -1188px -330px; }
.emoji-1f469-1f3fc-200d-1f3ed { background-position: -1188px -396px; }
.emoji-1f469-1f3fd-200d-1f3ed { background-position: -1188px -462px; }
.emoji-1f469-1f3fe-200d-1f3ed { background-position: -1188px -528px; }
.emoji-1f469-1f3ff-200d-1f3ed { background-position: -1188px -594px; }
.emoji-1f469-1f3fb-200d-1f4bb { background-position: -1188px -1386px; }
.emoji-1f469-1f3fc-200d-1f4bb { background-position: -1188px -1452px; }
.emoji-1f469-1f3fd-200d-1f4bb { background-position: -1188px -1518px; }
.emoji-1f469-1f3fe-200d-1f4bb { background-position: -1188px -1584px; }
.emoji-1f469-1f3ff-200d-1f4bb { background-position: -1188px -1650px; }
.emoji-1f469-1f3fb-200d-1f4bc { background-position: -1188px -1782px; }
.emoji-1f469-1f3fc-200d-1f4bc { background-position: -1188px -1848px; }
.emoji-1f469-1f3fd-200d-1f4bc { background-position: -1188px -1914px; }
.emoji-1f469-1f3fe-200d-1f4bc { background-position: -1188px -1980px; }
.emoji-1f469-1f3ff-200d-1f4bc { background-position: -1188px -2046px; }
.emoji-1f469-1f3fb-200d-1f527 { background-position: -1188px -2178px; }
.emoji-1f469-1f3fc-200d-1f527 { background-position: -1188px -2244px; }
.emoji-1f469-1f3fd-200d-1f527 { background-position: -1188px -2310px; }
.emoji-1f469-1f3fe-200d-1f527 { background-position: -1188px -2376px; }
.emoji-1f469-1f3ff-200d-1f527 { background-position: -1188px -2442px; }
.emoji-1f469-1f3fb-200d-1f52c { background-position: -1188px -2574px; }
.emoji-1f469-1f3fc-200d-1f52c { background-position: -1188px -2640px; }
.emoji-1f469-1f3fd-200d-1f52c { background-position: -1188px -2706px; }
.emoji-1f469-1f3fe-200d-1f52c { background-position: -1188px -2772px; }
.emoji-1f469-1f3ff-200d-1f52c { background-position: -1188px -2838px; }
.emoji-1f469-1f3fb-200d-1f680 { background-position: -1188px -2970px; }
.emoji-1f469-1f3fc-200d-1f680 { background-position: -1188px -3036px; }
.emoji-1f469-1f3fd-200d-1f680 { background-position: -1188px -3102px; }
.emoji-1f469-1f3fe-200d-1f680 { background-position: -1188px -3168px; }
.emoji-1f469-1f3ff-200d-1f680 { background-position: -1188px -3234px; }
.emoji-1f469-1f3fb-200d-1f692 { background-position: -1188px -3366px; }
.emoji-1f469-1f3fc-200d-1f692 { background-position: -1188px -3432px; }
.emoji-1f469-1f3fd-200d-1f692 { background-position: -1188px -3498px; }
.emoji-1f469-1f3fe-200d-1f692 { background-position: -1188px -3564px; }
.emoji-1f469-1f3ff-200d-1f692 { background-position: -1188px -3630px; }
.emoji-1f469-1f3fb-200d-1f9af { background-position: -1188px -3762px; }
.emoji-1f469-1f3fc-200d-1f9af { background-position: -1254px -0; }
.emoji-1f469-1f3fd-200d-1f9af { background-position: -1254px -66px; }
.emoji-1f469-1f3fe-200d-1f9af { background-position: -1254px -132px; }
.emoji-1f469-1f3ff-200d-1f9af { background-position: -1254px -198px; }
.emoji-1f469-1f3fb-200d-1f9b0 { background-position: -1254px -330px; }
.emoji-1f469-1f3fc-200d-1f9b0 { background-position: -1254px -396px; }
.emoji-1f469-1f3fd-200d-1f9b0 { background-position: -1254px -462px; }
.emoji-1f469-1f3fe-200d-1f9b0 { background-position: -1254px -528px; }
.emoji-1f469-1f3ff-200d-1f9b0 { background-position: -1254px -594px; }
.emoji-1f469-1f3fb-200d-1f9b1 { background-position: -1254px -726px; }
.emoji-1f469-1f3fc-200d-1f9b1 { background-position: -1254px -792px; }
.emoji-1f469-1f3fd-200d-1f9b1 { background-position: -1254px -858px; }
.emoji-1f469-1f3fe-200d-1f9b1 { background-position: -1254px -924px; }
.emoji-1f469-1f3ff-200d-1f9b1 { background-position: -1254px -990px; }
.emoji-1f469-1f3fb-200d-1f9b2 { background-position: -1254px -1122px; }
.emoji-1f469-1f3fc-200d-1f9b2 { background-position: -1254px -1188px; }
.emoji-1f469-1f3fd-200d-1f9b2 { background-position: -1254px -1254px; }
.emoji-1f469-1f3fe-200d-1f9b2 { background-position: -1254px -1320px; }
.emoji-1f469-1f3ff-200d-1f9b2 { background-position: -1254px -1386px; }
.emoji-1f469-1f3fb-200d-1f9b3 { background-position: -1254px -1518px; }
.emoji-1f469-1f3fc-200d-1f9b3 { background-position: -1254px -1584px; }
.emoji-1f469-1f3fd-200d-1f9b3 { background-position: -1254px -1650px; }
.emoji-1f469-1f3fe-200d-1f9b3 { background-position: -1254px -1716px; }
.emoji-1f469-1f3ff-200d-1f9b3 { background-position: -1254px -1782px; }
.emoji-1f469-1f3fb-200d-1f9bc { background-position: -1254px -1914px; }
.emoji-1f469-1f3fc-200d-1f9bc { background-position: -1254px -1980px; }
.emoji-1f469-1f3fd-200d-1f9bc { background-position: -1254px -2046px; }
.emoji-1f469-1f3fe-200d-1f9bc { background-position: -1254px -2112px; }
.emoji-1f469-1f3ff-200d-1f9bc { background-position: -1254px -2178px; }
.emoji-1f469-1f3fb-200d-1f9bd { background-position: -1254px -2310px; }
.emoji-1f469-1f3fc-200d-1f9bd { background-position: -1254px -2376px; }
.emoji-1f469-1f3fd-200d-1f9bd { background-position: -1254px -2442px; }
.emoji-1f469-1f3fe-200d-1f9bd { background-position: -1254px -2508px; }
.emoji-1f469-1f3ff-200d-1f9bd { background-position: -1254px -2574px; }
.emoji-1f469-1f3fb-200d-2695-fe0f { background-position: -1254px -2706px; }
.emoji-1f469-1f3fc-200d-2695-fe0f { background-position: -1254px -2772px; }
.emoji-1f469-1f3fd-200d-2695-fe0f { background-position: -1254px -2838px; }
.emoji-1f469-1f3fe-200d-2695-fe0f { background-position: -1254px -2904px; }
.emoji-1f469-1f3ff-200d-2695-fe0f { background-position: -1254px -2970px; }
.emoji-1f469-1f3fb-200d-2696-fe0f { background-position: -1254px -3102px; }
.emoji-1f469-1f3fc-200d-2696-fe0f { background-position: -1254px -3168px; }
.emoji-1f469-1f3fd-200d-2696-fe0f { background-position: -1254px -3234px; }
.emoji-1f469-1f3fe-200d-2696-fe0f { background-position: -1254px -3300px; }
.emoji-1f469-1f3ff-200d-2696-fe0f { background-position: -1254px -3366px; }
.emoji-1f469-1f3fb-200d-2708-fe0f { background-position: -1254px -3498px; }
.emoji-1f469-1f3fc-200d-2708-fe0f { background-position: -1254px -3564px; }
.emoji-1f469-1f3fd-200d-2708-fe0f { background-position: -1254px -3630px; }
.emoji-1f469-1f3fe-200d-2708-fe0f { background-position: -1254px -3696px; }
.emoji-1f469-1f3ff-200d-2708-fe0f { background-position: -1254px -3762px; }
.emoji-1f469-1f3fb { background-position: -1320px -330px; }
.emoji-1f469-1f3fc { background-position: -1320px -396px; }
.emoji-1f469-1f3fd { background-position: -1320px -462px; }
.emoji-1f469-1f3fe { background-position: -1320px -528px; }
.emoji-1f469-1f3ff { background-position: -1320px -594px; }
.emoji-1f46b-1f3fb { background-position: -1320px -792px; }
.emoji-1f46b-1f3fc { background-position: -1320px -858px; }
.emoji-1f46b-1f3fd { background-position: -1320px -924px; }
.emoji-1f46b-1f3fe { background-position: -1320px -990px; }
.emoji-1f46b-1f3ff { background-position: -1320px -1056px; }
.emoji-1f469-1f3fb-200d-1f91d-200d-1f468-1f3fc { background-position: -1320px -1122px; }
.emoji-1f469-1f3fb-200d-1f91d-200d-1f468-1f3fd { background-position: -1320px -1188px; }
.emoji-1f469-1f3fb-200d-1f91d-200d-1f468-1f3fe { background-position: -1320px -1254px; }
.emoji-1f469-1f3fb-200d-1f91d-200d-1f468-1f3ff { background-position: -1320px -1320px; }
.emoji-1f469-1f3fc-200d-1f91d-200d-1f468-1f3fb { background-position: -1320px -1386px; }
.emoji-1f469-1f3fc-200d-1f91d-200d-1f468-1f3fd { background-position: -1320px -1452px; }
.emoji-1f469-1f3fc-200d-1f91d-200d-1f468-1f3fe { background-position: -1320px -1518px; }
.emoji-1f469-1f3fc-200d-1f91d-200d-1f468-1f3ff { background-position: -1320px -1584px; }
.emoji-1f469-1f3fd-200d-1f91d-200d-1f468-1f3fb { background-position: -1320px -1650px; }
.emoji-1f469-1f3fd-200d-1f91d-200d-1f468-1f3fc { background-position: -1320px -1716px; }
.emoji-1f469-1f3fd-200d-1f91d-200d-1f468-1f3fe { background-position: -1320px -1782px; }
.emoji-1f469-1f3fd-200d-1f91d-200d-1f468-1f3ff { background-position: -1320px -1848px; }
.emoji-1f469-1f3fe-200d-1f91d-200d-1f468-1f3fb { background-position: -1320px -1914px; }
.emoji-1f469-1f3fe-200d-1f91d-200d-1f468-1f3fc { background-position: -1320px -1980px; }
.emoji-1f469-1f3fe-200d-1f91d-200d-1f468-1f3fd { background-position: -1320px -2046px; }
.emoji-1f469-1f3fe-200d-1f91d-200d-1f468-1f3ff { background-position: -1320px -2112px; }
.emoji-1f469-1f3ff-200d-1f91d-200d-1f468-1f3fb { background-position: -1320px -2178px; }
.emoji-1f469-1f3ff-200d-1f91d-200d-1f468-1f3fc { background-position: -1320px -2244px; }
.emoji-1f469-1f3ff-200d-1f91d-200d-1f468-1f3fd { background-position: -1320px -2310px; }
.emoji-1f469-1f3ff-200d-1f91d-200d-1f468-1f3fe { background-position: -1320px -2376px; }
.emoji-1f46c-1f3fb { background-position: -1320px -2508px; }
.emoji-1f46c-1f3fc { background-position: -1320px -2574px; }
.emoji-1f46c-1f3fd { background-position: -1320px -2640px; }
.emoji-1f46c-1f3fe { background-position: -1320px -2706px; }
.emoji-1f46c-1f3ff { background-position: -1320px -2772px; }
.emoji-1f468-1f3fb-200d-1f91d-200d-1f468-1f3fc { background-position: -1320px -2838px; }
.emoji-1f468-1f3fb-200d-1f91d-200d-1f468-1f3fd { background-position: -1320px -2904px; }
.emoji-1f468-1f3fb-200d-1f91d-200d-1f468-1f3fe { background-position: -1320px -2970px; }
.emoji-1f468-1f3fb-200d-1f91d-200d-1f468-1f3ff { background-position: -1320px -3036px; }
.emoji-1f468-1f3fc-200d-1f91d-200d-1f468-1f3fb { background-position: -1320px -3102px; }
.emoji-1f468-1f3fc-200d-1f91d-200d-1f468-1f3fd { background-position: -1320px -3168px; }
.emoji-1f468-1f3fc-200d-1f91d-200d-1f468-1f3fe { background-position: -1320px -3234px; }
.emoji-1f468-1f3fc-200d-1f91d-200d-1f468-1f3ff { background-position: -1320px -3300px; }
.emoji-1f468-1f3fd-200d-1f91d-200d-1f468-1f3fb { background-position: -1320px -3366px; }
.emoji-1f468-1f3fd-200d-1f91d-200d-1f468-1f3fc { background-position: -1320px -3432px; }
.emoji-1f468-1f3fd-200d-1f91d-200d-1f468-1f3fe { background-position: -1320px -3498px; }
.emoji-1f468-1f3fd-200d-1f91d-200d-1f468-1f3ff { background-position: -1320px -3564px; }
.emoji-1f468-1f3fe-200d-1f91d-200d-1f468-1f3fb { background-position: -1320px -3630px; }
.emoji-1f468-1f3fe-200d-1f91d-200d-1f468-1f3fc { background-position: -1320px -3696px; }
.emoji-1f468-1f3fe-200d-1f91d-200d-1f468-1f3fd { background-position: -1320px -3762px; }
.emoji-1f468-1f3fe-200d-1f91d-200d-1f468-1f3ff { background-position: -1386px -0; }
.emoji-1f468-1f3ff-200d-1f91d-200d-1f468-1f3fb { background-position: -1386px -66px; }
.emoji-1f468-1f3ff-200d-1f91d-200d-1f468-1f3fc { background-position: -1386px -132px; }
.emoji-1f468-1f3ff-200d-1f91d-200d-1f468-1f3fd { background-position: -1386px -198px; }
.emoji-1f468-1f3ff-200d-1f91d-200d-1f468-1f3fe { background-position: -1386px -264px; }
.emoji-1f46d-1f3fb { background-position: -1386px -396px; }
.emoji-1f46d-1f3fc { background-position: -1386px -462px; }
.emoji-1f46d-1f3fd { background-position: -1386px -528px; }
.emoji-1f46d-1f3fe { background-position: -1386px -594px; }
.emoji-1f46d-1f3ff { background-position: -1386px -660px; }
.emoji-1f469-1f3fb-200d-1f91d-200d-1f469-1f3fc { background-position: -1386px -726px; }
.emoji-1f469-1f3fb-200d-1f91d-200d-1f469-1f3fd { background-position: -1386px -792px; }
.emoji-1f469-1f3fb-200d-1f91d-200d-1f469-1f3fe { background-position: -1386px -858px; }
.emoji-1f469-1f3fb-200d-1f91d-200d-1f469-1f3ff { background-position: -1386px -924px; }
.emoji-1f469-1f3fc-200d-1f91d-200d-1f469-1f3fb { background-position: -1386px -990px; }
.emoji-1f469-1f3fc-200d-1f91d-200d-1f469-1f3fd { background-position: -1386px -1056px; }
.emoji-1f469-1f3fc-200d-1f91d-200d-1f469-1f3fe { background-position: -1386px -1122px; }
.emoji-1f469-1f3fc-200d-1f91d-200d-1f469-1f3ff { background-position: -1386px -1188px; }
.emoji-1f469-1f3fd-200d-1f91d-200d-1f469-1f3fb { background-position: -1386px -1254px; }
.emoji-1f469-1f3fd-200d-1f91d-200d-1f469-1f3fc { background-position: -1386px -1320px; }
.emoji-1f469-1f3fd-200d-1f91d-200d-1f469-1f3fe { background-position: -1386px -1386px; }
.emoji-1f469-1f3fd-200d-1f91d-200d-1f469-1f3ff { background-position: -1386px -1452px; }
.emoji-1f469-1f3fe-200d-1f91d-200d-1f469-1f3fb { background-position: -1386px -1518px; }
.emoji-1f469-1f3fe-200d-1f91d-200d-1f469-1f3fc { background-position: -1386px -1584px; }
.emoji-1f469-1f3fe-200d-1f91d-200d-1f469-1f3fd { background-position: -1386px -1650px; }
.emoji-1f469-1f3fe-200d-1f91d-200d-1f469-1f3ff { background-position: -1386px -1716px; }
.emoji-1f469-1f3ff-200d-1f91d-200d-1f469-1f3fb { background-position: -1386px -1782px; }
.emoji-1f469-1f3ff-200d-1f91d-200d-1f469-1f3fc { background-position: -1386px -1848px; }
.emoji-1f469-1f3ff-200d-1f91d-200d-1f469-1f3fd { background-position: -1386px -1914px; }
.emoji-1f469-1f3ff-200d-1f91d-200d-1f469-1f3fe { background-position: -1386px -1980px; }
.emoji-1f46e-1f3fb-200d-2640-fe0f { background-position: -1386px -2112px; }
.emoji-1f46e-1f3fc-200d-2640-fe0f { background-position: -1386px -2178px; }
.emoji-1f46e-1f3fd-200d-2640-fe0f { background-position: -1386px -2244px; }
.emoji-1f46e-1f3fe-200d-2640-fe0f { background-position: -1386px -2310px; }
.emoji-1f46e-1f3ff-200d-2640-fe0f { background-position: -1386px -2376px; }
.emoji-1f46e-1f3fb-200d-2642-fe0f { background-position: -1386px -2508px; }
.emoji-1f46e-1f3fc-200d-2642-fe0f { background-position: -1386px -2574px; }
.emoji-1f46e-1f3fd-200d-2642-fe0f { background-position: -1386px -2640px; }
.emoji-1f46e-1f3fe-200d-2642-fe0f { background-position: -1386px -2706px; }
.emoji-1f46e-1f3ff-200d-2642-fe0f { background-position: -1386px -2772px; }
.emoji-1f46e-1f3fb { background-position: -1386px -2904px; }
.emoji-1f46e-1f3fc { background-position: -1386px -2970px; }
.emoji-1f46e-1f3fd { background-position: -1386px -3036px; }
.emoji-1f46e-1f3fe { background-position: -1386px -3102px; }
.emoji-1f46e-1f3ff { background-position: -1386px -3168px; }
.emoji-1f470-1f3fb-200d-2640-fe0f { background-position: -1386px -3498px; }
.emoji-1f470-1f3fc-200d-2640-fe0f { background-position: -1386px -3564px; }
.emoji-1f470-1f3fd-200d-2640-fe0f { background-position: -1386px -3630px; }
.emoji-1f470-1f3fe-200d-2640-fe0f { background-position: -1386px -3696px; }
.emoji-1f470-1f3ff-200d-2640-fe0f { background-position: -1386px -3762px; }
.emoji-1f470-1f3fb-200d-2642-fe0f { background-position: -1452px -66px; }
.emoji-1f470-1f3fc-200d-2642-fe0f { background-position: -1452px -132px; }
.emoji-1f470-1f3fd-200d-2642-fe0f { background-position: -1452px -198px; }
.emoji-1f470-1f3fe-200d-2642-fe0f { background-position: -1452px -264px; }
.emoji-1f470-1f3ff-200d-2642-fe0f { background-position: -1452px -330px; }
.emoji-1f470-1f3fb { background-position: -1452px -462px; }
.emoji-1f470-1f3fc { background-position: -1452px -528px; }
.emoji-1f470-1f3fd { background-position: -1452px -594px; }
.emoji-1f470-1f3fe { background-position: -1452px -660px; }
.emoji-1f470-1f3ff { background-position: -1452px -726px; }
.emoji-1f471-1f3fb-200d-2640-fe0f { background-position: -1452px -858px; }
.emoji-1f471-1f3fc-200d-2640-fe0f { background-position: -1452px -924px; }
.emoji-1f471-1f3fd-200d-2640-fe0f { background-position: -1452px -990px; }
.emoji-1f471-1f3fe-200d-2640-fe0f { background-position: -1452px -1056px; }
.emoji-1f471-1f3ff-200d-2640-fe0f { background-position: -1452px -1122px; }
.emoji-1f471-1f3fb-200d-2642-fe0f { background-position: -1452px -1254px; }
.emoji-1f471-1f3fc-200d-2642-fe0f { background-position: -1452px -1320px; }
.emoji-1f471-1f3fd-200d-2642-fe0f { background-position: -1452px -1386px; }
.emoji-1f471-1f3fe-200d-2642-fe0f { background-position: -1452px -1452px; }
.emoji-1f471-1f3ff-200d-2642-fe0f { background-position: -1452px -1518px; }
.emoji-1f471-1f3fb { background-position: -1452px -1650px; }
.emoji-1f471-1f3fc { background-position: -1452px -1716px; }
.emoji-1f471-1f3fd { background-position: -1452px -1782px; }
.emoji-1f471-1f3fe { background-position: -1452px -1848px; }
.emoji-1f471-1f3ff { background-position: -1452px -1914px; }
.emoji-1f472-1f3fb { background-position: -1452px -2046px; }
.emoji-1f472-1f3fc { background-position: -1452px -2112px; }
.emoji-1f472-1f3fd { background-position: -1452px -2178px; }
.emoji-1f472-1f3fe { background-position: -1452px -2244px; }
.emoji-1f472-1f3ff { background-position: -1452px -2310px; }
.emoji-1f473-1f3fb-200d-2640-fe0f { background-position: -1452px -2442px; }
.emoji-1f473-1f3fc-200d-2640-fe0f { background-position: -1452px -2508px; }
.emoji-1f473-1f3fd-200d-2640-fe0f { background-position: -1452px -2574px; }
.emoji-1f473-1f3fe-200d-2640-fe0f { background-position: -1452px -2640px; }
.emoji-1f473-1f3ff-200d-2640-fe0f { background-position: -1452px -2706px; }
.emoji-1f473-1f3fb-200d-2642-fe0f { background-position: -1452px -2838px; }
.emoji-1f473-1f3fc-200d-2642-fe0f { background-position: -1452px -2904px; }
.emoji-1f473-1f3fd-200d-2642-fe0f { background-position: -1452px -2970px; }
.emoji-1f473-1f3fe-200d-2642-fe0f { background-position: -1452px -3036px; }
.emoji-1f473-1f3ff-200d-2642-fe0f { background-position: -1452px -3102px; }
.emoji-1f473-1f3fb { background-position: -1452px -3234px; }
.emoji-1f473-1f3fc { background-position: -1452px -3300px; }
.emoji-1f473-1f3fd { background-position: -1452px -3366px; }
.emoji-1f473-1f3fe { background-position: -1452px -3432px; }
.emoji-1f473-1f3ff { background-position: -1452px -3498px; }
.emoji-1f474-1f3fb { background-position: -1452px -3630px; }
.emoji-1f474-1f3fc { background-position: -1452px -3696px; }
.emoji-1f474-1f3fd { background-position: -1452px -3762px; }
.emoji-1f474-1f3fe { background-position: -1518px -0; }
.emoji-1f474-1f3ff { background-position: -1518px -66px; }
.emoji-1f475-1f3fb { background-position: -1518px -198px; }
.emoji-1f475-1f3fc { background-position: -1518px -264px; }
.emoji-1f475-1f3fd { background-position: -1518px -330px; }
.emoji-1f475-1f3fe { background-position: -1518px -396px; }
.emoji-1f475-1f3ff { background-position: -1518px -462px; }
.emoji-1f476-1f3fb { background-position: -1518px -594px; }
.emoji-1f476-1f3fc { background-position: -1518px -660px; }
.emoji-1f476-1f3fd { background-position: -1518px -726px; }
.emoji-1f476-1f3fe { background-position: -1518px -792px; }
.emoji-1f476-1f3ff { background-position: -1518px -858px; }
.emoji-1f477-1f3fb-200d-2640-fe0f { background-position: -1518px -990px; }
.emoji-1f477-1f3fc-200d-2640-fe0f { background-position: -1518px -1056px; }
.emoji-1f477-1f3fd-200d-2640-fe0f { background-position: -1518px -1122px; }
.emoji-1f477-1f3fe-200d-2640-fe0f { background-position: -1518px -1188px; }
.emoji-1f477-1f3ff-200d-2640-fe0f { background-position: -1518px -1254px; }
.emoji-1f477-1f3fb-200d-2642-fe0f { background-position: -1518px -1386px; }
.emoji-1f477-1f3fc-200d-2642-fe0f { background-position: -1518px -1452px; }
.emoji-1f477-1f3fd-200d-2642-fe0f { background-position: -1518px -1518px; }
.emoji-1f477-1f3fe-200d-2642-fe0f { background-position: -1518px -1584px; }
.emoji-1f477-1f3ff-200d-2642-fe0f { background-position: -1518px -1650px; }
.emoji-1f477-1f3fb { background-position: -1518px -1782px; }
.emoji-1f477-1f3fc { background-position: -1518px -1848px; }
.emoji-1f477-1f3fd { background-position: -1518px -1914px; }
.emoji-1f477-1f3fe { background-position: -1518px -1980px; }
.emoji-1f477-1f3ff { background-position: -1518px -2046px; }
.emoji-1f478-1f3fb { background-position: -1518px -2178px; }
.emoji-1f478-1f3fc { background-position: -1518px -2244px; }
.emoji-1f478-1f3fd { background-position: -1518px -2310px; }
.emoji-1f478-1f3fe { background-position: -1518px -2376px; }
.emoji-1f478-1f3ff { background-position: -1518px -2442px; }
.emoji-1f47c-1f3fb { background-position: -1518px -2772px; }
.emoji-1f47c-1f3fc { background-position: -1518px -2838px; }
.emoji-1f47c-1f3fd { background-position: -1518px -2904px; }
.emoji-1f47c-1f3fe { background-position: -1518px -2970px; }
.emoji-1f47c-1f3ff { background-position: -1518px -3036px; }
.emoji-1f481-1f3fb-200d-2640-fe0f { background-position: -1518px -3432px; }
.emoji-1f481-1f3fc-200d-2640-fe0f { background-position: -1518px -3498px; }
.emoji-1f481-1f3fd-200d-2640-fe0f { background-position: -1518px -3564px; }
.emoji-1f481-1f3fe-200d-2640-fe0f { background-position: -1518px -3630px; }
.emoji-1f481-1f3ff-200d-2640-fe0f { background-position: -1518px -3696px; }
.emoji-1f481-1f3fb-200d-2642-fe0f { background-position: -1584px -0; }
.emoji-1f481-1f3fc-200d-2642-fe0f { background-position: -1584px -66px; }
.emoji-1f481-1f3fd-200d-2642-fe0f { background-position: -1584px -132px; }
.emoji-1f481-1f3fe-200d-2642-fe0f { background-position: -1584px -198px; }
.emoji-1f481-1f3ff-200d-2642-fe0f { background-position: -1584px -264px; }
.emoji-1f481-1f3fb { background-position: -1584px -396px; }
.emoji-1f481-1f3fc { background-position: -1584px -462px; }
.emoji-1f481-1f3fd { background-position: -1584px -528px; }
.emoji-1f481-1f3fe { background-position: -1584px -594px; }
.emoji-1f481-1f3ff { background-position: -1584px -660px; }
.emoji-1f482-1f3fb-200d-2640-fe0f { background-position: -1584px -792px; }
.emoji-1f482-1f3fc-200d-2640-fe0f { background-position: -1584px -858px; }
.emoji-1f482-1f3fd-200d-2640-fe0f { background-position: -1584px -924px; }
.emoji-1f482-1f3fe-200d-2640-fe0f { background-position: -1584px -990px; }
.emoji-1f482-1f3ff-200d-2640-fe0f { background-position: -1584px -1056px; }
.emoji-1f482-1f3fb-200d-2642-fe0f { background-position: -1584px -1188px; }
.emoji-1f482-1f3fc-200d-2642-fe0f { background-position: -1584px -1254px; }
.emoji-1f482-1f3fd-200d-2642-fe0f { background-position: -1584px -1320px; }
.emoji-1f482-1f3fe-200d-2642-fe0f { background-position: -1584px -1386px; }
.emoji-1f482-1f3ff-200d-2642-fe0f { background-position: -1584px -1452px; }
.emoji-1f482-1f3fb { background-position: -1584px -1584px; }
.emoji-1f482-1f3fc { background-position: -1584px -1650px; }
.emoji-1f482-1f3fd { background-position: -1584px -1716px; }
.emoji-1f482-1f3fe { background-position: -1584px -1782px; }
.emoji-1f482-1f3ff { background-position: -1584px -1848px; }
.emoji-1f483-1f3fb { background-position: -1584px -1980px; }
.emoji-1f483-1f3fc { background-position: -1584px -2046px; }
.emoji-1f483-1f3fd { background-position: -1584px -2112px; }
.emoji-1f483-1f3fe { background-position: -1584px -2178px; }
.emoji-1f483-1f3ff { background-position: -1584px -2244px; }
.emoji-1f485-1f3fb { background-position: -1584px -2442px; }
.emoji-1f485-1f3fc { background-position: -1584px -2508px; }
.emoji-1f485-1f3fd { background-position: -1584px -2574px; }
.emoji-1f485-1f3fe { background-position: -1584px -2640px; }
.emoji-1f485-1f3ff { background-position: -1584px -2706px; }
.emoji-1f486-1f3fb-200d-2640-fe0f { background-position: -1584px -2838px; }
.emoji-1f486-1f3fc-200d-2640-fe0f { background-position: -1584px -2904px; }
.emoji-1f486-1f3fd-200d-2640-fe0f { background-position: -1584px -2970px; }
.emoji-1f486-1f3fe-200d-2640-fe0f { background-position: -1584px -3036px; }
.emoji-1f486-1f3ff-200d-2640-fe0f { background-position: -1584px -3102px; }
.emoji-1f486-1f3fb-200d-2642-fe0f { background-position: -1584px -3234px; }
.emoji-1f486-1f3fc-200d-2642-fe0f { background-position: -1584px -3300px; }
.emoji-1f486-1f3fd-200d-2642-fe0f { background-position: -1584px -3366px; }
.emoji-1f486-1f3fe-200d-2642-fe0f { background-position: -1584px -3432px; }
.emoji-1f486-1f3ff-200d-2642-fe0f { background-position: -1584px -3498px; }
.emoji-1f486-1f3fb { background-position: -1584px -3630px; }
.emoji-1f486-1f3fc { background-position: -1584px -3696px; }
.emoji-1f486-1f3fd { background-position: -1584px -3762px; }
.emoji-1f486-1f3fe { background-position: -1650px -0; }
.emoji-1f486-1f3ff { background-position: -1650px -66px; }
.emoji-1f487-1f3fb-200d-2640-fe0f { background-position: -1650px -198px; }
.emoji-1f487-1f3fc-200d-2640-fe0f { background-position: -1650px -264px; }
.emoji-1f487-1f3fd-200d-2640-fe0f { background-position: -1650px -330px; }
.emoji-1f487-1f3fe-200d-2640-fe0f { background-position: -1650px -396px; }
.emoji-1f487-1f3ff-200d-2640-fe0f { background-position: -1650px -462px; }
.emoji-1f487-1f3fb-200d-2642-fe0f { background-position: -1650px -594px; }
.emoji-1f487-1f3fc-200d-2642-fe0f { background-position: -1650px -660px; }
.emoji-1f487-1f3fd-200d-2642-fe0f { background-position: -1650px -726px; }
.emoji-1f487-1f3fe-200d-2642-fe0f { background-position: -1650px -792px; }
.emoji-1f487-1f3ff-200d-2642-fe0f { background-position: -1650px -858px; }
.emoji-1f487-1f3fb { background-position: -1650px -990px; }
.emoji-1f487-1f3fc { background-position: -1650px -1056px; }
.emoji-1f487-1f3fd { background-position: -1650px -1122px; }
.emoji-1f487-1f3fe { background-position: -1650px -1188px; }
.emoji-1f487-1f3ff { background-position: -1650px -1254px; }
.emoji-1f4aa-1f3fb { background-position: -1650px -3630px; }
.emoji-1f4aa-1f3fc { background-position: -1650px -3696px; }
.emoji-1f4aa-1f3fd { background-position: -1650px -3762px; }
.emoji-1f4aa-1f3fe { background-position: -1716px -0; }
.emoji-1f4aa-1f3ff { background-position: -1716px -66px; }
.emoji-1f574-1f3fb { background-position: -1914px -528px; }
.emoji-1f574-1f3fc { background-position: -1914px -594px; }
.emoji-1f574-1f3fd { background-position: -1914px -660px; }
.emoji-1f574-1f3fe { background-position: -1914px -726px; }
.emoji-1f574-1f3ff { background-position: -1914px -792px; }
.emoji-1f575-1f3fb-200d-2640-fe0f { background-position: -1914px -924px; }
.emoji-1f575-1f3fc-200d-2640-fe0f { background-position: -1914px -990px; }
.emoji-1f575-1f3fd-200d-2640-fe0f { background-position: -1914px -1056px; }
.emoji-1f575-1f3fe-200d-2640-fe0f { background-position: -1914px -1122px; }
.emoji-1f575-1f3ff-200d-2640-fe0f { background-position: -1914px -1188px; }
.emoji-1f575-1f3fb-200d-2642-fe0f { background-position: -1914px -1320px; }
.emoji-1f575-1f3fc-200d-2642-fe0f { background-position: -1914px -1386px; }
.emoji-1f575-1f3fd-200d-2642-fe0f { background-position: -1914px -1452px; }
.emoji-1f575-1f3fe-200d-2642-fe0f { background-position: -1914px -1518px; }
.emoji-1f575-1f3ff-200d-2642-fe0f { background-position: -1914px -1584px; }
.emoji-1f575-1f3fb { background-position: -1914px -1716px; }
.emoji-1f575-1f3fc { background-position: -1914px -1782px; }
.emoji-1f575-1f3fd { background-position: -1914px -1848px; }
.emoji-1f575-1f3fe { background-position: -1914px -1914px; }
.emoji-1f575-1f3ff { background-position: -1914px -1980px; }
.emoji-1f57a-1f3fb { background-position: -1914px -2376px; }
.emoji-1f57a-1f3fc { background-position: -1914px -2442px; }
.emoji-1f57a-1f3fd { background-position: -1914px -2508px; }
.emoji-1f57a-1f3fe { background-position: -1914px -2574px; }
.emoji-1f57a-1f3ff { background-position: -1914px -2640px; }
.emoji-1f590-1f3fb { background-position: -1914px -3102px; }
.emoji-1f590-1f3fc { background-position: -1914px -3168px; }
.emoji-1f590-1f3fd { background-position: -1914px -3234px; }
.emoji-1f590-1f3fe { background-position: -1914px -3300px; }
.emoji-1f590-1f3ff { background-position: -1914px -3366px; }
.emoji-1f595-1f3fb { background-position: -1914px -3498px; }
.emoji-1f595-1f3fc { background-position: -1914px -3564px; }
.emoji-1f595-1f3fd { background-position: -1914px -3630px; }
.emoji-1f595-1f3fe { background-position: -1914px -3696px; }
.emoji-1f595-1f3ff { background-position: -1914px -3762px; }
.emoji-1f596-1f3fb { background-position: -1980px -66px; }
.emoji-1f596-1f3fc { background-position: -1980px -132px; }
.emoji-1f596-1f3fd { background-position: -1980px -198px; }
.emoji-1f596-1f3fe { background-position: -1980px -264px; }
.emoji-1f596-1f3ff { background-position: -1980px -330px; }
.emoji-1f645-1f3fb-200d-2640-fe0f { background-position: -2046px -2904px; }
.emoji-1f645-1f3fc-200d-2640-fe0f { background-position: -2046px -2970px; }
.emoji-1f645-1f3fd-200d-2640-fe0f { background-position: -2046px -3036px; }
.emoji-1f645-1f3fe-200d-2640-fe0f { background-position: -2046px -3102px; }
.emoji-1f645-1f3ff-200d-2640-fe0f { background-position: -2046px -3168px; }
.emoji-1f645-1f3fb-200d-2642-fe0f { background-position: -2046px -3300px; }
.emoji-1f645-1f3fc-200d-2642-fe0f { background-position: -2046px -3366px; }
.emoji-1f645-1f3fd-200d-2642-fe0f { background-position: -2046px -3432px; }
.emoji-1f645-1f3fe-200d-2642-fe0f { background-position: -2046px -3498px; }
.emoji-1f645-1f3ff-200d-2642-fe0f { background-position: -2046px -3564px; }
.emoji-1f645-1f3fb { background-position: -2046px -3696px; }
.emoji-1f645-1f3fc { background-position: -2046px -3762px; }
.emoji-1f645-1f3fd { background-position: -2112px -0; }
.emoji-1f645-1f3fe { background-position: -2112px -66px; }
.emoji-1f645-1f3ff { background-position: -2112px -132px; }
.emoji-1f646-1f3fb-200d-2640-fe0f { background-position: -2112px -264px; }
.emoji-1f646-1f3fc-200d-2640-fe0f { background-position: -2112px -330px; }
.emoji-1f646-1f3fd-200d-2640-fe0f { background-position: -2112px -396px; }
.emoji-1f646-1f3fe-200d-2640-fe0f { background-position: -2112px -462px; }
.emoji-1f646-1f3ff-200d-2640-fe0f { background-position: -2112px -528px; }
.emoji-1f646-1f3fb-200d-2642-fe0f { background-position: -2112px -660px; }
.emoji-1f646-1f3fc-200d-2642-fe0f { background-position: -2112px -726px; }
.emoji-1f646-1f3fd-200d-2642-fe0f { background-position: -2112px -792px; }
.emoji-1f646-1f3fe-200d-2642-fe0f { background-position: -2112px -858px; }
.emoji-1f646-1f3ff-200d-2642-fe0f { background-position: -2112px -924px; }
.emoji-1f646-1f3fb { background-position: -2112px -1056px; }
.emoji-1f646-1f3fc { background-position: -2112px -1122px; }
.emoji-1f646-1f3fd { background-position: -2112px -1188px; }
.emoji-1f646-1f3fe { background-position: -2112px -1254px; }
.emoji-1f646-1f3ff { background-position: -2112px -1320px; }
.emoji-1f647-1f3fb-200d-2640-fe0f { background-position: -2112px -1452px; }
.emoji-1f647-1f3fc-200d-2640-fe0f { background-position: -2112px -1518px; }
.emoji-1f647-1f3fd-200d-2640-fe0f { background-position: -2112px -1584px; }
.emoji-1f647-1f3fe-200d-2640-fe0f { background-position: -2112px -1650px; }
.emoji-1f647-1f3ff-200d-2640-fe0f { background-position: -2112px -1716px; }
.emoji-1f647-1f3fb-200d-2642-fe0f { background-position: -2112px -1848px; }
.emoji-1f647-1f3fc-200d-2642-fe0f { background-position: -2112px -1914px; }
.emoji-1f647-1f3fd-200d-2642-fe0f { background-position: -2112px -1980px; }
.emoji-1f647-1f3fe-200d-2642-fe0f { background-position: -2112px -2046px; }
.emoji-1f647-1f3ff-200d-2642-fe0f { background-position: -2112px -2112px; }
.emoji-1f647-1f3fb { background-position: -2112px -2244px; }
.emoji-1f647-1f3fc { background-position: -2112px -2310px; }
.emoji-1f647-1f3fd { background-position: -2112px -2376px; }
.emoji-1f647-1f3fe { background-position: -2112px -2442px; }
.emoji-1f647-1f3ff { background-position: -2112px -2508px; }
.emoji-1f64b-1f3fb-200d-2640-fe0f { background-position: -2112px -2838px; }
.emoji-1f64b-1f3fc-200d-2640-fe0f { background-position: -2112px -2904px; }
.emoji-1f64b-1f3fd-200d-2640-fe0f { background-position: -2112px -2970px; }
.emoji-1f64b-1f3fe-200d-2640-fe0f { background-position: -2112px -3036px; }
.emoji-1f64b-1f3ff-200d-2640-fe0f { background-position: -2112px -3102px; }
.emoji-1f64b-1f3fb-200d-2642-fe0f { background-position: -2112px -3234px; }
.emoji-1f64b-1f3fc-200d-2642-fe0f { background-position: -2112px -3300px; }
.emoji-1f64b-1f3fd-200d-2642-fe0f { background-position: -2112px -3366px; }
.emoji-1f64b-1f3fe-200d-2642-fe0f { background-position: -2112px -3432px; }
.emoji-1f64b-1f3ff-200d-2642-fe0f { background-position: -2112px -3498px; }
.emoji-1f64b-1f3fb { background-position: -2112px -3630px; }
.emoji-1f64b-1f3fc { background-position: -2112px -3696px; }
.emoji-1f64b-1f3fd { background-position: -2112px -3762px; }
.emoji-1f64b-1f3fe { background-position: -2178px -0; }
.emoji-1f64b-1f3ff { background-position: -2178px -66px; }
.emoji-1f64c-1f3fb { background-position: -2178px -198px; }
.emoji-1f64c-1f3fc { background-position: -2178px -264px; }
.emoji-1f64c-1f3fd { background-position: -2178px -330px; }
.emoji-1f64c-1f3fe { background-position: -2178px -396px; }
.emoji-1f64c-1f3ff { background-position: -2178px -462px; }
.emoji-1f64d-1f3fb-200d-2640-fe0f { background-position: -2178px -594px; }
.emoji-1f64d-1f3fc-200d-2640-fe0f { background-position: -2178px -660px; }
.emoji-1f64d-1f3fd-200d-2640-fe0f { background-position: -2178px -726px; }
.emoji-1f64d-1f3fe-200d-2640-fe0f { background-position: -2178px -792px; }
.emoji-1f64d-1f3ff-200d-2640-fe0f { background-position: -2178px -858px; }
.emoji-1f64d-1f3fb-200d-2642-fe0f { background-position: -2178px -990px; }
.emoji-1f64d-1f3fc-200d-2642-fe0f { background-position: -2178px -1056px; }
.emoji-1f64d-1f3fd-200d-2642-fe0f { background-position: -2178px -1122px; }
.emoji-1f64d-1f3fe-200d-2642-fe0f { background-position: -2178px -1188px; }
.emoji-1f64d-1f3ff-200d-2642-fe0f { background-position: -2178px -1254px; }
.emoji-1f64d-1f3fb { background-position: -2178px -1386px; }
.emoji-1f64d-1f3fc { background-position: -2178px -1452px; }
.emoji-1f64d-1f3fd { background-position: -2178px -1518px; }
.emoji-1f64d-1f3fe { background-position: -2178px -1584px; }
.emoji-1f64d-1f3ff { background-position: -2178px -1650px; }
.emoji-1f64e-1f3fb-200d-2640-fe0f { background-position: -2178px -1782px; }
.emoji-1f64e-1f3fc-200d-2640-fe0f { background-position: -2178px -1848px; }
.emoji-1f64e-1f3fd-200d-2640-fe0f { background-position: -2178px -1914px; }
.emoji-1f64e-1f3fe-200d-2640-fe0f { background-position: -2178px -1980px; }
.emoji-1f64e-1f3ff-200d-2640-fe0f { background-position: -2178px -2046px; }
.emoji-1f64e-1f3fb-200d-2642-fe0f { background-position: -2178px -2178px; }
.emoji-1f64e-1f3fc-200d-2642-fe0f { background-position: -2178px -2244px; }
.emoji-1f64e-1f3fd-200d-2642-fe0f { background-position: -2178px -2310px; }
.emoji-1f64e-1f3fe-200d-2642-fe0f { background-position: -2178px -2376px; }
.emoji-1f64e-1f3ff-200d-2642-fe0f { background-position: -2178px -2442px; }
.emoji-1f64e-1f3fb { background-position: -2178px -2574px; }
.emoji-1f64e-1f3fc { background-position: -2178px -2640px; }
.emoji-1f64e-1f3fd { background-position: -2178px -2706px; }
.emoji-1f64e-1f3fe { background-position: -2178px -2772px; }
.emoji-1f64e-1f3ff { background-position: -2178px -2838px; }
.emoji-1f64f-1f3fb { background-position: -2178px -2970px; }
.emoji-1f64f-1f3fc { background-position: -2178px -3036px; }
.emoji-1f64f-1f3fd { background-position: -2178px -3102px; }
.emoji-1f64f-1f3fe { background-position: -2178px -3168px; }
.emoji-1f64f-1f3ff { background-position: -2178px -3234px; }
.emoji-1f6a3-1f3fb-200d-2640-fe0f { background-position: -2244px -1848px; }
.emoji-1f6a3-1f3fc-200d-2640-fe0f { background-position: -2244px -1914px; }
.emoji-1f6a3-1f3fd-200d-2640-fe0f { background-position: -2244px -1980px; }
.emoji-1f6a3-1f3fe-200d-2640-fe0f { background-position: -2244px -2046px; }
.emoji-1f6a3-1f3ff-200d-2640-fe0f { background-position: -2244px -2112px; }
.emoji-1f6a3-1f3fb-200d-2642-fe0f { background-position: -2244px -2244px; }
.emoji-1f6a3-1f3fc-200d-2642-fe0f { background-position: -2244px -2310px; }
.emoji-1f6a3-1f3fd-200d-2642-fe0f { background-position: -2244px -2376px; }
.emoji-1f6a3-1f3fe-200d-2642-fe0f { background-position: -2244px -2442px; }
.emoji-1f6a3-1f3ff-200d-2642-fe0f { background-position: -2244px -2508px; }
.emoji-1f6a3-1f3fb { background-position: -2244px -2640px; }
.emoji-1f6a3-1f3fc { background-position: -2244px -2706px; }
.emoji-1f6a3-1f3fd { background-position: -2244px -2772px; }
.emoji-1f6a3-1f3fe { background-position: -2244px -2838px; }
.emoji-1f6a3-1f3ff { background-position: -2244px -2904px; }
.emoji-1f6b4-1f3fb-200d-2640-fe0f { background-position: -2310px -264px; }
.emoji-1f6b4-1f3fc-200d-2640-fe0f { background-position: -2310px -330px; }
.emoji-1f6b4-1f3fd-200d-2640-fe0f { background-position: -2310px -396px; }
.emoji-1f6b4-1f3fe-200d-2640-fe0f { background-position: -2310px -462px; }
.emoji-1f6b4-1f3ff-200d-2640-fe0f { background-position: -2310px -528px; }
.emoji-1f6b4-1f3fb-200d-2642-fe0f { background-position: -2310px -660px; }
.emoji-1f6b4-1f3fc-200d-2642-fe0f { background-position: -2310px -726px; }
.emoji-1f6b4-1f3fd-200d-2642-fe0f { background-position: -2310px -792px; }
.emoji-1f6b4-1f3fe-200d-2642-fe0f { background-position: -2310px -858px; }
.emoji-1f6b4-1f3ff-200d-2642-fe0f { background-position: -2310px -924px; }
.emoji-1f6b4-1f3fb { background-position: -2310px -1056px; }
.emoji-1f6b4-1f3fc { background-position: -2310px -1122px; }
.emoji-1f6b4-1f3fd { background-position: -2310px -1188px; }
.emoji-1f6b4-1f3fe { background-position: -2310px -1254px; }
.emoji-1f6b4-1f3ff { background-position: -2310px -1320px; }
.emoji-1f6b5-1f3fb-200d-2640-fe0f { background-position: -2310px -1452px; }
.emoji-1f6b5-1f3fc-200d-2640-fe0f { background-position: -2310px -1518px; }
.emoji-1f6b5-1f3fd-200d-2640-fe0f { background-position: -2310px -1584px; }
.emoji-1f6b5-1f3fe-200d-2640-fe0f { background-position: -2310px -1650px; }
.emoji-1f6b5-1f3ff-200d-2640-fe0f { background-position: -2310px -1716px; }
.emoji-1f6b5-1f3fb-200d-2642-fe0f { background-position: -2310px -1848px; }
.emoji-1f6b5-1f3fc-200d-2642-fe0f { background-position: -2310px -1914px; }
.emoji-1f6b5-1f3fd-200d-2642-fe0f { background-position: -2310px -1980px; }
.emoji-1f6b5-1f3fe-200d-2642-fe0f { background-position: -2310px -2046px; }
.emoji-1f6b5-1f3ff-200d-2642-fe0f { background-position: -2310px -2112px; }
.emoji-1f6b5-1f3fb { background-position: -2310px -2244px; }
.emoji-1f6b5-1f3fc { background-position: -2310px -2310px; }
.emoji-1f6b5-1f3fd { background-position: -2310px -2376px; }
.emoji-1f6b5-1f3fe { background-position: -2310px -2442px; }
.emoji-1f6b5-1f3ff { background-position: -2310px -2508px; }
.emoji-1f6b6-1f3fb-200d-2640-fe0f { background-position: -2310px -2640px; }
.emoji-1f6b6-1f3fc-200d-2640-fe0f { background-position: -2310px -2706px; }
.emoji-1f6b6-1f3fd-200d-2640-fe0f { background-position: -2310px -2772px; }
.emoji-1f6b6-1f3fe-200d-2640-fe0f { background-position: -2310px -2838px; }
.emoji-1f6b6-1f3ff-200d-2640-fe0f { background-position: -2310px -2904px; }
.emoji-1f6b6-1f3fb-200d-2642-fe0f { background-position: -2310px -3036px; }
.emoji-1f6b6-1f3fc-200d-2642-fe0f { background-position: -2310px -3102px; }
.emoji-1f6b6-1f3fd-200d-2642-fe0f { background-position: -2310px -3168px; }
.emoji-1f6b6-1f3fe-200d-2642-fe0f { background-position: -2310px -3234px; }
.emoji-1f6b6-1f3ff-200d-2642-fe0f { background-position: -2310px -3300px; }
.emoji-1f6b6-1f3fb { background-position: -2310px -3432px; }
.emoji-1f6b6-1f3fc { background-position: -2310px -3498px; }
.emoji-1f6b6-1f3fd { background-position: -2310px -3564px; }
.emoji-1f6b6-1f3fe { background-position: -2310px -3630px; }
.emoji-1f6b6-1f3ff { background-position: -2310px -3696px; }
.emoji-1f6c0-1f3fb { background-position: -2376px -594px; }
.emoji-1f6c0-1f3fc { background-position: -2376px -660px; }
.emoji-1f6c0-1f3fd { background-position: -2376px -726px; }
.emoji-1f6c0-1f3fe { background-position: -2376px -792px; }
.emoji-1f6c0-1f3ff { background-position: -2376px -858px; }
.emoji-1f6cc-1f3fb { background-position: -2376px -1386px; }
.emoji-1f6cc-1f3fc { background-position: -2376px -1452px; }
.emoji-1f6cc-1f3fd { background-position: -2376px -1518px; }
.emoji-1f6cc-1f3fe { background-position: -2376px -1584px; }
.emoji-1f6cc-1f3ff { background-position: -2376px -1650px; }
.emoji-1f90c-1f3fb { background-position: -2442px -660px; }
.emoji-1f90c-1f3fc { background-position: -2442px -726px; }
.emoji-1f90c-1f3fd { background-position: -2442px -792px; }
.emoji-1f90c-1f3fe { background-position: -2442px -858px; }
.emoji-1f90c-1f3ff { background-position: -2442px -924px; }
.emoji-1f90f-1f3fb { background-position: -2442px -1188px; }
.emoji-1f90f-1f3fc { background-position: -2442px -1254px; }
.emoji-1f90f-1f3fd { background-position: -2442px -1320px; }
.emoji-1f90f-1f3fe { background-position: -2442px -1386px; }
.emoji-1f90f-1f3ff { background-position: -2442px -1452px; }
.emoji-1f918-1f3fb { background-position: -2442px -2112px; }
.emoji-1f918-1f3fc { background-position: -2442px -2178px; }
.emoji-1f918-1f3fd { background-position: -2442px -2244px; }
.emoji-1f918-1f3fe { background-position: -2442px -2310px; }
.emoji-1f918-1f3ff { background-position: -2442px -2376px; }
.emoji-1f919-1f3fb { background-position: -2442px -2508px; }
.emoji-1f919-1f3fc { background-position: -2442px -2574px; }
.emoji-1f919-1f3fd { background-position: -2442px -2640px; }
.emoji-1f919-1f3fe { background-position: -2442px -2706px; }
.emoji-1f919-1f3ff { background-position: -2442px -2772px; }
.emoji-1f91a-1f3fb { background-position: -2442px -2904px; }
.emoji-1f91a-1f3fc { background-position: -2442px -2970px; }
.emoji-1f91a-1f3fd { background-position: -2442px -3036px; }
.emoji-1f91a-1f3fe { background-position: -2442px -3102px; }
.emoji-1f91a-1f3ff { background-position: -2442px -3168px; }
.emoji-1f91b-1f3fb { background-position: -2442px -3300px; }
.emoji-1f91b-1f3fc { background-position: -2442px -3366px; }
.emoji-1f91b-1f3fd { background-position: -2442px -3432px; }
.emoji-1f91b-1f3fe { background-position: -2442px -3498px; }
.emoji-1f91b-1f3ff { background-position: -2442px -3564px; }
.emoji-1f91c-1f3fb { background-position: -2442px -3696px; }
.emoji-1f91c-1f3fc { background-position: -2442px -3762px; }
.emoji-1f91c-1f3fd { background-position: -2508px -0; }
.emoji-1f91c-1f3fe { background-position: -2508px -66px; }
.emoji-1f91c-1f3ff { background-position: -2508px -132px; }
.emoji-1f91e-1f3fb { background-position: -2508px -330px; }
.emoji-1f91e-1f3fc { background-position: -2508px -396px; }
.emoji-1f91e-1f3fd { background-position: -2508px -462px; }
.emoji-1f91e-1f3fe { background-position: -2508px -528px; }
.emoji-1f91e-1f3ff { background-position: -2508px -594px; }
.emoji-1f91f-1f3fb { background-position: -2508px -726px; }
.emoji-1f91f-1f3fc { background-position: -2508px -792px; }
.emoji-1f91f-1f3fd { background-position: -2508px -858px; }
.emoji-1f91f-1f3fe { background-position: -2508px -924px; }
.emoji-1f91f-1f3ff { background-position: -2508px -990px; }
.emoji-1f926-1f3fb-200d-2640-fe0f { background-position: -2508px -1518px; }
.emoji-1f926-1f3fc-200d-2640-fe0f { background-position: -2508px -1584px; }
.emoji-1f926-1f3fd-200d-2640-fe0f { background-position: -2508px -1650px; }
.emoji-1f926-1f3fe-200d-2640-fe0f { background-position: -2508px -1716px; }
.emoji-1f926-1f3ff-200d-2640-fe0f { background-position: -2508px -1782px; }
.emoji-1f926-1f3fb-200d-2642-fe0f { background-position: -2508px -1914px; }
.emoji-1f926-1f3fc-200d-2642-fe0f { background-position: -2508px -1980px; }
.emoji-1f926-1f3fd-200d-2642-fe0f { background-position: -2508px -2046px; }
.emoji-1f926-1f3fe-200d-2642-fe0f { background-position: -2508px -2112px; }
.emoji-1f926-1f3ff-200d-2642-fe0f { background-position: -2508px -2178px; }
.emoji-1f926-1f3fb { background-position: -2508px -2310px; }
.emoji-1f926-1f3fc { background-position: -2508px -2376px; }
.emoji-1f926-1f3fd { background-position: -2508px -2442px; }
.emoji-1f926-1f3fe { background-position: -2508px -2508px; }
.emoji-1f926-1f3ff { background-position: -2508px -2574px; }
.emoji-1f930-1f3fb { background-position: -2508px -3300px; }
.emoji-1f930-1f3fc { background-position: -2508px -3366px; }
.emoji-1f930-1f3fd { background-position: -2508px -3432px; }
.emoji-1f930-1f3fe { background-position: -2508px -3498px; }
.emoji-1f930-1f3ff { background-position: -2508px -3564px; }
.emoji-1f931-1f3fb { background-position: -2508px -3696px; }
.emoji-1f931-1f3fc { background-position: -2508px -3762px; }
.emoji-1f931-1f3fd { background-position: -2574px -0; }
.emoji-1f931-1f3fe { background-position: -2574px -66px; }
.emoji-1f931-1f3ff { background-position: -2574px -132px; }
.emoji-1f932-1f3fb { background-position: -2574px -264px; }
.emoji-1f932-1f3fc { background-position: -2574px -330px; }
.emoji-1f932-1f3fd { background-position: -2574px -396px; }
.emoji-1f932-1f3fe { background-position: -2574px -462px; }
.emoji-1f932-1f3ff { background-position: -2574px -528px; }
.emoji-1f933-1f3fb { background-position: -2574px -660px; }
.emoji-1f933-1f3fc { background-position: -2574px -726px; }
.emoji-1f933-1f3fd { background-position: -2574px -792px; }
.emoji-1f933-1f3fe { background-position: -2574px -858px; }
.emoji-1f933-1f3ff { background-position: -2574px -924px; }
.emoji-1f934-1f3fb { background-position: -2574px -1056px; }
.emoji-1f934-1f3fc { background-position: -2574px -1122px; }
.emoji-1f934-1f3fd { background-position: -2574px -1188px; }
.emoji-1f934-1f3fe { background-position: -2574px -1254px; }
.emoji-1f934-1f3ff { background-position: -2574px -1320px; }
.emoji-1f935-1f3fb-200d-2640-fe0f { background-position: -2574px -1452px; }
.emoji-1f935-1f3fc-200d-2640-fe0f { background-position: -2574px -1518px; }
.emoji-1f935-1f3fd-200d-2640-fe0f { background-position: -2574px -1584px; }
.emoji-1f935-1f3fe-200d-2640-fe0f { background-position: -2574px -1650px; }
.emoji-1f935-1f3ff-200d-2640-fe0f { background-position: -2574px -1716px; }
.emoji-1f935-1f3fb-200d-2642-fe0f { background-position: -2574px -1848px; }
.emoji-1f935-1f3fc-200d-2642-fe0f { background-position: -2574px -1914px; }
.emoji-1f935-1f3fd-200d-2642-fe0f { background-position: -2574px -1980px; }
.emoji-1f935-1f3fe-200d-2642-fe0f { background-position: -2574px -2046px; }
.emoji-1f935-1f3ff-200d-2642-fe0f { background-position: -2574px -2112px; }
.emoji-1f935-1f3fb { background-position: -2574px -2244px; }
.emoji-1f935-1f3fc { background-position: -2574px -2310px; }
.emoji-1f935-1f3fd { background-position: -2574px -2376px; }
.emoji-1f935-1f3fe { background-position: -2574px -2442px; }
.emoji-1f935-1f3ff { background-position: -2574px -2508px; }
.emoji-1f936-1f3fb { background-position: -2574px -2640px; }
.emoji-1f936-1f3fc { background-position: -2574px -2706px; }
.emoji-1f936-1f3fd { background-position: -2574px -2772px; }
.emoji-1f936-1f3fe { background-position: -2574px -2838px; }
.emoji-1f936-1f3ff { background-position: -2574px -2904px; }
.emoji-1f937-1f3fb-200d-2640-fe0f { background-position: -2574px -3036px; }
.emoji-1f937-1f3fc-200d-2640-fe0f { background-position: -2574px -3102px; }
.emoji-1f937-1f3fd-200d-2640-fe0f { background-position: -2574px -3168px; }
.emoji-1f937-1f3fe-200d-2640-fe0f { background-position: -2574px -3234px; }
.emoji-1f937-1f3ff-200d-2640-fe0f { background-position: -2574px -3300px; }
.emoji-1f937-1f3fb-200d-2642-fe0f { background-position: -2574px -3432px; }
.emoji-1f937-1f3fc-200d-2642-fe0f { background-position: -2574px -3498px; }
.emoji-1f937-1f3fd-200d-2642-fe0f { background-position: -2574px -3564px; }
.emoji-1f937-1f3fe-200d-2642-fe0f { background-position: -2574px -3630px; }
.emoji-1f937-1f3ff-200d-2642-fe0f { background-position: -2574px -3696px; }
.emoji-1f937-1f3fb { background-position: -2640px -0; }
.emoji-1f937-1f3fc { background-position: -2640px -66px; }
.emoji-1f937-1f3fd { background-position: -2640px -132px; }
.emoji-1f937-1f3fe { background-position: -2640px -198px; }
.emoji-1f937-1f3ff { background-position: -2640px -264px; }
.emoji-1f938-1f3fb-200d-2640-fe0f { background-position: -2640px -396px; }
.emoji-1f938-1f3fc-200d-2640-fe0f { background-position: -2640px -462px; }
.emoji-1f938-1f3fd-200d-2640-fe0f { background-position: -2640px -528px; }
.emoji-1f938-1f3fe-200d-2640-fe0f { background-position: -2640px -594px; }
.emoji-1f938-1f3ff-200d-2640-fe0f { background-position: -2640px -660px; }
.emoji-1f938-1f3fb-200d-2642-fe0f { background-position: -2640px -792px; }
.emoji-1f938-1f3fc-200d-2642-fe0f { background-position: -2640px -858px; }
.emoji-1f938-1f3fd-200d-2642-fe0f { background-position: -2640px -924px; }
.emoji-1f938-1f3fe-200d-2642-fe0f { background-position: -2640px -990px; }
.emoji-1f938-1f3ff-200d-2642-fe0f { background-position: -2640px -1056px; }
.emoji-1f938-1f3fb { background-position: -2640px -1188px; }
.emoji-1f938-1f3fc { background-position: -2640px -1254px; }
.emoji-1f938-1f3fd { background-position: -2640px -1320px; }
.emoji-1f938-1f3fe { background-position: -2640px -1386px; }
.emoji-1f938-1f3ff { background-position: -2640px -1452px; }
.emoji-1f939-1f3fb-200d-2640-fe0f { background-position: -2640px -1584px; }
.emoji-1f939-1f3fc-200d-2640-fe0f { background-position: -2640px -1650px; }
.emoji-1f939-1f3fd-200d-2640-fe0f { background-position: -2640px -1716px; }
.emoji-1f939-1f3fe-200d-2640-fe0f { background-position: -2640px -1782px; }
.emoji-1f939-1f3ff-200d-2640-fe0f { background-position: -2640px -1848px; }
.emoji-1f939-1f3fb-200d-2642-fe0f { background-position: -2640px -1980px; }
.emoji-1f939-1f3fc-200d-2642-fe0f { background-position: -2640px -2046px; }
.emoji-1f939-1f3fd-200d-2642-fe0f { background-position: -2640px -2112px; }
.emoji-1f939-1f3fe-200d-2642-fe0f { background-position: -2640px -2178px; }
.emoji-1f939-1f3ff-200d-2642-fe0f { background-position: -2640px -2244px; }
.emoji-1f939-1f3fb { background-position: -2640px -2376px; }
.emoji-1f939-1f3fc { background-position: -2640px -2442px; }
.emoji-1f939-1f3fd { background-position: -2640px -2508px; }
.emoji-1f939-1f3fe { background-position: -2640px -2574px; }
.emoji-1f939-1f3ff { background-position: -2640px -2640px; }
.emoji-1f93d-1f3fb-200d-2640-fe0f { background-position: -2640px -3036px; }
.emoji-1f93d-1f3fc-200d-2640-fe0f { background-position: -2640px -3102px; }
.emoji-1f93d-1f3fd-200d-2640-fe0f { background-position: -2640px -3168px; }
.emoji-1f93d-1f3fe-200d-2640-fe0f { background-position: -2640px -3234px; }
.emoji-1f93d-1f3ff-200d-2640-fe0f { background-position: -2640px -3300px; }
.emoji-1f93d-1f3fb-200d-2642-fe0f { background-position: -2640px -3432px; }
.emoji-1f93d-1f3fc-200d-2642-fe0f { background-position: -2640px -3498px; }
.emoji-1f93d-1f3fd-200d-2642-fe0f { background-position: -2640px -3564px; }
.emoji-1f93d-1f3fe-200d-2642-fe0f { background-position: -2640px -3630px; }
.emoji-1f93d-1f3ff-200d-2642-fe0f { background-position: -2640px -3696px; }
.emoji-1f93d-1f3fb { background-position: -2706px -0; }
.emoji-1f93d-1f3fc { background-position: -2706px -66px; }
.emoji-1f93d-1f3fd { background-position: -2706px -132px; }
.emoji-1f93d-1f3fe { background-position: -2706px -198px; }
.emoji-1f93d-1f3ff { background-position: -2706px -264px; }
.emoji-1f93e-1f3fb-200d-2640-fe0f { background-position: -2706px -396px; }
.emoji-1f93e-1f3fc-200d-2640-fe0f { background-position: -2706px -462px; }
.emoji-1f93e-1f3fd-200d-2640-fe0f { background-position: -2706px -528px; }
.emoji-1f93e-1f3fe-200d-2640-fe0f { background-position: -2706px -594px; }
.emoji-1f93e-1f3ff-200d-2640-fe0f { background-position: -2706px -660px; }
.emoji-1f93e-1f3fb-200d-2642-fe0f { background-position: -2706px -792px; }
.emoji-1f93e-1f3fc-200d-2642-fe0f { background-position: -2706px -858px; }
.emoji-1f93e-1f3fd-200d-2642-fe0f { background-position: -2706px -924px; }
.emoji-1f93e-1f3fe-200d-2642-fe0f { background-position: -2706px -990px; }
.emoji-1f93e-1f3ff-200d-2642-fe0f { background-position: -2706px -1056px; }
.emoji-1f93e-1f3fb { background-position: -2706px -1188px; }
.emoji-1f93e-1f3fc { background-position: -2706px -1254px; }
.emoji-1f93e-1f3fd { background-position: -2706px -1320px; }
.emoji-1f93e-1f3fe { background-position: -2706px -1386px; }
.emoji-1f93e-1f3ff { background-position: -2706px -1452px; }
.emoji-1f977-1f3fb { background-position: -2772px -1386px; }
.emoji-1f977-1f3fc { background-position: -2772px -1452px; }
.emoji-1f977-1f3fd { background-position: -2772px -1518px; }
.emoji-1f977-1f3fe { background-position: -2772px -1584px; }
.emoji-1f977-1f3ff { background-position: -2772px -1650px; }
.emoji-1f9b5-1f3fb { background-position: -2838px -1650px; }
.emoji-1f9b5-1f3fc { background-position: -2838px -1716px; }
.emoji-1f9b5-1f3fd { background-position: -2838px -1782px; }
.emoji-1f9b5-1f3fe { background-position: -2838px -1848px; }
.emoji-1f9b5-1f3ff { background-position: -2838px -1914px; }
.emoji-1f9b6-1f3fb { background-position: -2838px -2046px; }
.emoji-1f9b6-1f3fc { background-position: -2838px -2112px; }
.emoji-1f9b6-1f3fd { background-position: -2838px -2178px; }
.emoji-1f9b6-1f3fe { background-position: -2838px -2244px; }
.emoji-1f9b6-1f3ff { background-position: -2838px -2310px; }
.emoji-1f9b8-1f3fb-200d-2640-fe0f { background-position: -2838px -2508px; }
.emoji-1f9b8-1f3fc-200d-2640-fe0f { background-position: -2838px -2574px; }
.emoji-1f9b8-1f3fd-200d-2640-fe0f { background-position: -2838px -2640px; }
.emoji-1f9b8-1f3fe-200d-2640-fe0f { background-position: -2838px -2706px; }
.emoji-1f9b8-1f3ff-200d-2640-fe0f { background-position: -2838px -2772px; }
.emoji-1f9b8-1f3fb-200d-2642-fe0f { background-position: -2838px -2904px; }
.emoji-1f9b8-1f3fc-200d-2642-fe0f { background-position: -2838px -2970px; }
.emoji-1f9b8-1f3fd-200d-2642-fe0f { background-position: -2838px -3036px; }
.emoji-1f9b8-1f3fe-200d-2642-fe0f { background-position: -2838px -3102px; }
.emoji-1f9b8-1f3ff-200d-2642-fe0f { background-position: -2838px -3168px; }
.emoji-1f9b8-1f3fb { background-position: -2838px -3300px; }
.emoji-1f9b8-1f3fc { background-position: -2838px -3366px; }
.emoji-1f9b8-1f3fd { background-position: -2838px -3432px; }
.emoji-1f9b8-1f3fe { background-position: -2838px -3498px; }
.emoji-1f9b8-1f3ff { background-position: -2838px -3564px; }
.emoji-1f9b9-1f3fb-200d-2640-fe0f { background-position: -2838px -3696px; }
.emoji-1f9b9-1f3fc-200d-2640-fe0f { background-position: -2838px -3762px; }
.emoji-1f9b9-1f3fd-200d-2640-fe0f { background-position: -2904px -0; }
.emoji-1f9b9-1f3fe-200d-2640-fe0f { background-position: -2904px -66px; }
.emoji-1f9b9-1f3ff-200d-2640-fe0f { background-position: -2904px -132px; }
.emoji-1f9b9-1f3fb-200d-2642-fe0f { background-position: -2904px -264px; }
.emoji-1f9b9-1f3fc-200d-2642-fe0f { background-position: -2904px -330px; }
.emoji-1f9b9-1f3fd-200d-2642-fe0f { background-position: -2904px -396px; }
.emoji-1f9b9-1f3fe-200d-2642-fe0f { background-position: -2904px -462px; }
.emoji-1f9b9-1f3ff-200d-2642-fe0f { background-position: -2904px -528px; }
.emoji-1f9b9-1f3fb { background-position: -2904px -660px; }
.emoji-1f9b9-1f3fc { background-position: -2904px -726px; }
.emoji-1f9b9-1f3fd { background-position: -2904px -792px; }
.emoji-1f9b9-1f3fe { background-position: -2904px -858px; }
.emoji-1f9b9-1f3ff { background-position: -2904px -924px; }
.emoji-1f9bb-1f3fb { background-position: -2904px -1122px; }
.emoji-1f9bb-1f3fc { background-position: -2904px -1188px; }
.emoji-1f9bb-1f3fd { background-position: -2904px -1254px; }
.emoji-1f9bb-1f3fe { background-position: -2904px -1320px; }
.emoji-1f9bb-1f3ff { background-position: -2904px -1386px; }
.emoji-1f9cd-1f3fb-200d-2640-fe0f { background-position: -2904px -2574px; }
.emoji-1f9cd-1f3fc-200d-2640-fe0f { background-position: -2904px -2640px; }
.emoji-1f9cd-1f3fd-200d-2640-fe0f { background-position: -2904px -2706px; }
.emoji-1f9cd-1f3fe-200d-2640-fe0f { background-position: -2904px -2772px; }
.emoji-1f9cd-1f3ff-200d-2640-fe0f { background-position: -2904px -2838px; }
.emoji-1f9cd-1f3fb-200d-2642-fe0f { background-position: -2904px -2970px; }
.emoji-1f9cd-1f3fc-200d-2642-fe0f { background-position: -2904px -3036px; }
.emoji-1f9cd-1f3fd-200d-2642-fe0f { background-position: -2904px -3102px; }
.emoji-1f9cd-1f3fe-200d-2642-fe0f { background-position: -2904px -3168px; }
.emoji-1f9cd-1f3ff-200d-2642-fe0f { background-position: -2904px -3234px; }
.emoji-1f9cd-1f3fb { background-position: -2904px -3366px; }
.emoji-1f9cd-1f3fc { background-position: -2904px -3432px; }
.emoji-1f9cd-1f3fd { background-position: -2904px -3498px; }
.emoji-1f9cd-1f3fe { background-position: -2904px -3564px; }
.emoji-1f9cd-1f3ff { background-position: -2904px -3630px; }
.emoji-1f9ce-1f3fb-200d-2640-fe0f { background-position: -2904px -3762px; }
.emoji-1f9ce-1f3fc-200d-2640-fe0f { background-position: -2970px -0; }
.emoji-1f9ce-1f3fd-200d-2640-fe0f { background-position: -2970px -66px; }
.emoji-1f9ce-1f3fe-200d-2640-fe0f { background-position: -2970px -132px; }
.emoji-1f9ce-1f3ff-200d-2640-fe0f { background-position: -2970px -198px; }
.emoji-1f9ce-1f3fb-200d-2642-fe0f { background-position: -2970px -330px; }
.emoji-1f9ce-1f3fc-200d-2642-fe0f { background-position: -2970px -396px; }
.emoji-1f9ce-1f3fd-200d-2642-fe0f { background-position: -2970px -462px; }
.emoji-1f9ce-1f3fe-200d-2642-fe0f { background-position: -2970px -528px; }
.emoji-1f9ce-1f3ff-200d-2642-fe0f { background-position: -2970px -594px; }
.emoji-1f9ce-1f3fb { background-position: -2970px -726px; }
.emoji-1f9ce-1f3fc { background-position: -2970px -792px; }
.emoji-1f9ce-1f3fd { background-position: -2970px -858px; }
.emoji-1f9ce-1f3fe { background-position: -2970px -924px; }
.emoji-1f9ce-1f3ff { background-position: -2970px -990px; }
.emoji-1f9cf-1f3fb-200d-2640-fe0f { background-position: -2970px -1122px; }
.emoji-1f9cf-1f3fc-200d-2640-fe0f { background-position: -2970px -1188px; }
.emoji-1f9cf-1f3fd-200d-2640-fe0f { background-position: -2970px -1254px; }
.emoji-1f9cf-1f3fe-200d-2640-fe0f { background-position: -2970px -1320px; }
.emoji-1f9cf-1f3ff-200d-2640-fe0f { background-position: -2970px -1386px; }
.emoji-1f9cf-1f3fb-200d-2642-fe0f { background-position: -2970px -1518px; }
.emoji-1f9cf-1f3fc-200d-2642-fe0f { background-position: -2970px -1584px; }
.emoji-1f9cf-1f3fd-200d-2642-fe0f { background-position: -2970px -1650px; }
.emoji-1f9cf-1f3fe-200d-2642-fe0f { background-position: -2970px -1716px; }
.emoji-1f9cf-1f3ff-200d-2642-fe0f { background-position: -2970px -1782px; }
.emoji-1f9cf-1f3fb { background-position: -2970px -1914px; }
.emoji-1f9cf-1f3fc { background-position: -2970px -1980px; }
.emoji-1f9cf-1f3fd { background-position: -2970px -2046px; }
.emoji-1f9cf-1f3fe { background-position: -2970px -2112px; }
.emoji-1f9cf-1f3ff { background-position: -2970px -2178px; }
.emoji-1f9d1-1f3fb-200d-1f33e { background-position: -2970px -2376px; }
.emoji-1f9d1-1f3fc-200d-1f33e { background-position: -2970px -2442px; }
.emoji-1f9d1-1f3fd-200d-1f33e { background-position: -2970px -2508px; }
.emoji-1f9d1-1f3fe-200d-1f33e { background-position: -2970px -2574px; }
.emoji-1f9d1-1f3ff-200d-1f33e { background-position: -2970px -2640px; }
.emoji-1f9d1-1f3fb-200d-1f373 { background-position: -2970px -2772px; }
.emoji-1f9d1-1f3fc-200d-1f373 { background-position: -2970px -2838px; }
.emoji-1f9d1-1f3fd-200d-1f373 { background-position: -2970px -2904px; }
.emoji-1f9d1-1f3fe-200d-1f373 { background-position: -2970px -2970px; }
.emoji-1f9d1-1f3ff-200d-1f373 { background-position: -2970px -3036px; }
.emoji-1f9d1-1f3fb-200d-1f37c { background-position: -2970px -3168px; }
.emoji-1f9d1-1f3fc-200d-1f37c { background-position: -2970px -3234px; }
.emoji-1f9d1-1f3fd-200d-1f37c { background-position: -2970px -3300px; }
.emoji-1f9d1-1f3fe-200d-1f37c { background-position: -2970px -3366px; }
.emoji-1f9d1-1f3ff-200d-1f37c { background-position: -2970px -3432px; }
.emoji-1f9d1-1f3fb-200d-1f384 { background-position: -2970px -3564px; }
.emoji-1f9d1-1f3fc-200d-1f384 { background-position: -2970px -3630px; }
.emoji-1f9d1-1f3fd-200d-1f384 { background-position: -2970px -3696px; }
.emoji-1f9d1-1f3fe-200d-1f384 { background-position: -2970px -3762px; }
.emoji-1f9d1-1f3ff-200d-1f384 { background-position: -3036px -0; }
.emoji-1f9d1-1f3fb-200d-1f393 { background-position: -3036px -132px; }
.emoji-1f9d1-1f3fc-200d-1f393 { background-position: -3036px -198px; }
.emoji-1f9d1-1f3fd-200d-1f393 { background-position: -3036px -264px; }
.emoji-1f9d1-1f3fe-200d-1f393 { background-position: -3036px -330px; }
.emoji-1f9d1-1f3ff-200d-1f393 { background-position: -3036px -396px; }
.emoji-1f9d1-1f3fb-200d-1f3a4 { background-position: -3036px -528px; }
.emoji-1f9d1-1f3fc-200d-1f3a4 { background-position: -3036px -594px; }
.emoji-1f9d1-1f3fd-200d-1f3a4 { background-position: -3036px -660px; }
.emoji-1f9d1-1f3fe-200d-1f3a4 { background-position: -3036px -726px; }
.emoji-1f9d1-1f3ff-200d-1f3a4 { background-position: -3036px -792px; }
.emoji-1f9d1-1f3fb-200d-1f3a8 { background-position: -3036px -924px; }
.emoji-1f9d1-1f3fc-200d-1f3a8 { background-position: -3036px -990px; }
.emoji-1f9d1-1f3fd-200d-1f3a8 { background-position: -3036px -1056px; }
.emoji-1f9d1-1f3fe-200d-1f3a8 { background-position: -3036px -1122px; }
.emoji-1f9d1-1f3ff-200d-1f3a8 { background-position: -3036px -1188px; }
.emoji-1f9d1-1f3fb-200d-1f3eb { background-position: -3036px -1320px; }
.emoji-1f9d1-1f3fc-200d-1f3eb { background-position: -3036px -1386px; }
.emoji-1f9d1-1f3fd-200d-1f3eb { background-position: -3036px -1452px; }
.emoji-1f9d1-1f3fe-200d-1f3eb { background-position: -3036px -1518px; }
.emoji-1f9d1-1f3ff-200d-1f3eb { background-position: -3036px -1584px; }
.emoji-1f9d1-1f3fb-200d-1f3ed { background-position: -3036px -1716px; }
.emoji-1f9d1-1f3fc-200d-1f3ed { background-position: -3036px -1782px; }
.emoji-1f9d1-1f3fd-200d-1f3ed { background-position: -3036px -1848px; }
.emoji-1f9d1-1f3fe-200d-1f3ed { background-position: -3036px -1914px; }
.emoji-1f9d1-1f3ff-200d-1f3ed { background-position: -3036px -1980px; }
.emoji-1f9d1-1f3fb-200d-1f4bb { background-position: -3036px -2112px; }
.emoji-1f9d1-1f3fc-200d-1f4bb { background-position: -3036px -2178px; }
.emoji-1f9d1-1f3fd-200d-1f4bb { background-position: -3036px -2244px; }
.emoji-1f9d1-1f3fe-200d-1f4bb { background-position: -3036px -2310px; }
.emoji-1f9d1-1f3ff-200d-1f4bb { background-position: -3036px -2376px; }
.emoji-1f9d1-1f3fb-200d-1f4bc { background-position: -3036px -2508px; }
.emoji-1f9d1-1f3fc-200d-1f4bc { background-position: -3036px -2574px; }
.emoji-1f9d1-1f3fd-200d-1f4bc { background-position: -3036px -2640px; }
.emoji-1f9d1-1f3fe-200d-1f4bc { background-position: -3036px -2706px; }
.emoji-1f9d1-1f3ff-200d-1f4bc { background-position: -3036px -2772px; }
.emoji-1f9d1-1f3fb-200d-1f527 { background-position: -3036px -2904px; }
.emoji-1f9d1-1f3fc-200d-1f527 { background-position: -3036px -2970px; }
.emoji-1f9d1-1f3fd-200d-1f527 { background-position: -3036px -3036px; }
.emoji-1f9d1-1f3fe-200d-1f527 { background-position: -3036px -3102px; }
.emoji-1f9d1-1f3ff-200d-1f527 { background-position: -3036px -3168px; }
.emoji-1f9d1-1f3fb-200d-1f52c { background-position: -3036px -3300px; }
.emoji-1f9d1-1f3fc-200d-1f52c { background-position: -3036px -3366px; }
.emoji-1f9d1-1f3fd-200d-1f52c { background-position: -3036px -3432px; }
.emoji-1f9d1-1f3fe-200d-1f52c { background-position: -3036px -3498px; }
.emoji-1f9d1-1f3ff-200d-1f52c { background-position: -3036px -3564px; }
.emoji-1f9d1-1f3fb-200d-1f680 { background-position: -3036px -3696px; }
.emoji-1f9d1-1f3fc-200d-1f680 { background-position: -3036px -3762px; }
.emoji-1f9d1-1f3fd-200d-1f680 { background-position: -3102px -0; }
.emoji-1f9d1-1f3fe-200d-1f680 { background-position: -3102px -66px; }
.emoji-1f9d1-1f3ff-200d-1f680 { background-position: -3102px -132px; }
.emoji-1f9d1-1f3fb-200d-1f692 { background-position: -3102px -264px; }
.emoji-1f9d1-1f3fc-200d-1f692 { background-position: -3102px -330px; }
.emoji-1f9d1-1f3fd-200d-1f692 { background-position: -3102px -396px; }
.emoji-1f9d1-1f3fe-200d-1f692 { background-position: -3102px -462px; }
.emoji-1f9d1-1f3ff-200d-1f692 { background-position: -3102px -528px; }
.emoji-1f9d1-1f3fb-200d-1f91d-200d-1f9d1-1f3fb { background-position: -3102px -660px; }
.emoji-1f9d1-1f3fb-200d-1f91d-200d-1f9d1-1f3fc { background-position: -3102px -726px; }
.emoji-1f9d1-1f3fb-200d-1f91d-200d-1f9d1-1f3fd { background-position: -3102px -792px; }
.emoji-1f9d1-1f3fb-200d-1f91d-200d-1f9d1-1f3fe { background-position: -3102px -858px; }
.emoji-1f9d1-1f3fb-200d-1f91d-200d-1f9d1-1f3ff { background-position: -3102px -924px; }
.emoji-1f9d1-1f3fc-200d-1f91d-200d-1f9d1-1f3fb { background-position: -3102px -990px; }
.emoji-1f9d1-1f3fc-200d-1f91d-200d-1f9d1-1f3fc { background-position: -3102px -1056px; }
.emoji-1f9d1-1f3fc-200d-1f91d-200d-1f9d1-1f3fd { background-position: -3102px -1122px; }
.emoji-1f9d1-1f3fc-200d-1f91d-200d-1f9d1-1f3fe { background-position: -3102px -1188px; }
.emoji-1f9d1-1f3fc-200d-1f91d-200d-1f9d1-1f3ff { background-position: -3102px -1254px; }
.emoji-1f9d1-1f3fd-200d-1f91d-200d-1f9d1-1f3fb { background-position: -3102px -1320px; }
.emoji-1f9d1-1f3fd-200d-1f91d-200d-1f9d1-1f3fc { background-position: -3102px -1386px; }
.emoji-1f9d1-1f3fd-200d-1f91d-200d-1f9d1-1f3fd { background-position: -3102px -1452px; }
.emoji-1f9d1-1f3fd-200d-1f91d-200d-1f9d1-1f3fe { background-position: -3102px -1518px; }
.emoji-1f9d1-1f3fd-200d-1f91d-200d-1f9d1-1f3ff { background-position: -3102px -1584px; }
.emoji-1f9d1-1f3fe-200d-1f91d-200d-1f9d1-1f3fb { background-position: -3102px -1650px; }
.emoji-1f9d1-1f3fe-200d-1f91d-200d-1f9d1-1f3fc { background-position: -3102px -1716px; }
.emoji-1f9d1-1f3fe-200d-1f91d-200d-1f9d1-1f3fd { background-position: -3102px -1782px; }
.emoji-1f9d1-1f3fe-200d-1f91d-200d-1f9d1-1f3fe { background-position: -3102px -1848px; }
.emoji-1f9d1-1f3fe-200d-1f91d-200d-1f9d1-1f3ff { background-position: -3102px -1914px; }
.emoji-1f9d1-1f3ff-200d-1f91d-200d-1f9d1-1f3fb { background-position: -3102px -1980px; }
.emoji-1f9d1-1f3ff-200d-1f91d-200d-1f9d1-1f3fc { background-position: -3102px -2046px; }
.emoji-1f9d1-1f3ff-200d-1f91d-200d-1f9d1-1f3fd { background-position: -3102px -2112px; }
.emoji-1f9d1-1f3ff-200d-1f91d-200d-1f9d1-1f3fe { background-position: -3102px -2178px; }
.emoji-1f9d1-1f3ff-200d-1f91d-200d-1f9d1-1f3ff { background-position: -3102px -2244px; }
.emoji-1f9d1-1f3fb-200d-1f9af { background-position: -3102px -2376px; }
.emoji-1f9d1-1f3fc-200d-1f9af { background-position: -3102px -2442px; }
.emoji-1f9d1-1f3fd-200d-1f9af { background-position: -3102px -2508px; }
.emoji-1f9d1-1f3fe-200d-1f9af { background-position: -3102px -2574px; }
.emoji-1f9d1-1f3ff-200d-1f9af { background-position: -3102px -2640px; }
.emoji-1f9d1-1f3fb-200d-1f9b0 { background-position: -3102px -2772px; }
.emoji-1f9d1-1f3fc-200d-1f9b0 { background-position: -3102px -2838px; }
.emoji-1f9d1-1f3fd-200d-1f9b0 { background-position: -3102px -2904px; }
.emoji-1f9d1-1f3fe-200d-1f9b0 { background-position: -3102px -2970px; }
.emoji-1f9d1-1f3ff-200d-1f9b0 { background-position: -3102px -3036px; }
.emoji-1f9d1-1f3fb-200d-1f9b1 { background-position: -3102px -3168px; }
.emoji-1f9d1-1f3fc-200d-1f9b1 { background-position: -3102px -3234px; }
.emoji-1f9d1-1f3fd-200d-1f9b1 { background-position: -3102px -3300px; }
.emoji-1f9d1-1f3fe-200d-1f9b1 { background-position: -3102px -3366px; }
.emoji-1f9d1-1f3ff-200d-1f9b1 { background-position: -3102px -3432px; }
.emoji-1f9d1-1f3fb-200d-1f9b2 { background-position: -3102px -3564px; }
.emoji-1f9d1-1f3fc-200d-1f9b2 { background-position: -3102px -3630px; }
.emoji-1f9d1-1f3fd-200d-1f9b2 { background-position: -3102px -3696px; }
.emoji-1f9d1-1f3fe-200d-1f9b2 { background-position: -3102px -3762px; }
.emoji-1f9d1-1f3ff-200d-1f9b2 { background-position: -3168px -0; }
.emoji-1f9d1-1f3fb-200d-1f9b3 { background-position: -3168px -132px; }
.emoji-1f9d1-1f3fc-200d-1f9b3 { background-position: -3168px -198px; }
.emoji-1f9d1-1f3fd-200d-1f9b3 { background-position: -3168px -264px; }
.emoji-1f9d1-1f3fe-200d-1f9b3 { background-position: -3168px -330px; }
.emoji-1f9d1-1f3ff-200d-1f9b3 { background-position: -3168px -396px; }
.emoji-1f9d1-1f3fb-200d-1f9bc { background-position: -3168px -528px; }
.emoji-1f9d1-1f3fc-200d-1f9bc { background-position: -3168px -594px; }
.emoji-1f9d1-1f3fd-200d-1f9bc { background-position: -3168px -660px; }
.emoji-1f9d1-1f3fe-200d-1f9bc { background-position: -3168px -726px; }
.emoji-1f9d1-1f3ff-200d-1f9bc { background-position: -3168px -792px; }
.emoji-1f9d1-1f3fb-200d-1f9bd { background-position: -3168px -924px; }
.emoji-1f9d1-1f3fc-200d-1f9bd { background-position: -3168px -990px; }
.emoji-1f9d1-1f3fd-200d-1f9bd { background-position: -3168px -1056px; }
.emoji-1f9d1-1f3fe-200d-1f9bd { background-position: -3168px -1122px; }
.emoji-1f9d1-1f3ff-200d-1f9bd { background-position: -3168px -1188px; }
.emoji-1f9d1-1f3fb-200d-2695-fe0f { background-position: -3168px -1320px; }
.emoji-1f9d1-1f3fc-200d-2695-fe0f { background-position: -3168px -1386px; }
.emoji-1f9d1-1f3fd-200d-2695-fe0f { background-position: -3168px -1452px; }
.emoji-1f9d1-1f3fe-200d-2695-fe0f { background-position: -3168px -1518px; }
.emoji-1f9d1-1f3ff-200d-2695-fe0f { background-position: -3168px -1584px; }
.emoji-1f9d1-1f3fb-200d-2696-fe0f { background-position: -3168px -1716px; }
.emoji-1f9d1-1f3fc-200d-2696-fe0f { background-position: -3168px -1782px; }
.emoji-1f9d1-1f3fd-200d-2696-fe0f { background-position: -3168px -1848px; }
.emoji-1f9d1-1f3fe-200d-2696-fe0f { background-position: -3168px -1914px; }
.emoji-1f9d1-1f3ff-200d-2696-fe0f { background-position: -3168px -1980px; }
.emoji-1f9d1-1f3fb-200d-2708-fe0f { background-position: -3168px -2112px; }
.emoji-1f9d1-1f3fc-200d-2708-fe0f { background-position: -3168px -2178px; }
.emoji-1f9d1-1f3fd-200d-2708-fe0f { background-position: -3168px -2244px; }
.emoji-1f9d1-1f3fe-200d-2708-fe0f { background-position: -3168px -2310px; }
.emoji-1f9d1-1f3ff-200d-2708-fe0f { background-position: -3168px -2376px; }
.emoji-1f9d1-1f3fb { background-position: -3168px -2508px; }
.emoji-1f9d1-1f3fc { background-position: -3168px -2574px; }
.emoji-1f9d1-1f3fd { background-position: -3168px -2640px; }
.emoji-1f9d1-1f3fe { background-position: -3168px -2706px; }
.emoji-1f9d1-1f3ff { background-position: -3168px -2772px; }
.emoji-1f9d2-1f3fb { background-position: -3168px -2904px; }
.emoji-1f9d2-1f3fc { background-position: -3168px -2970px; }
.emoji-1f9d2-1f3fd { background-position: -3168px -3036px; }
.emoji-1f9d2-1f3fe { background-position: -3168px -3102px; }
.emoji-1f9d2-1f3ff { background-position: -3168px -3168px; }
.emoji-1f9d3-1f3fb { background-position: -3168px -3300px; }
.emoji-1f9d3-1f3fc { background-position: -3168px -3366px; }
.emoji-1f9d3-1f3fd { background-position: -3168px -3432px; }
.emoji-1f9d3-1f3fe { background-position: -3168px -3498px; }
.emoji-1f9d3-1f3ff { background-position: -3168px -3564px; }
.emoji-1f9d4-1f3fb { background-position: -3168px -3696px; }
.emoji-1f9d4-1f3fc { background-position: -3168px -3762px; }
.emoji-1f9d4-1f3fd { background-position: -3234px -0; }
.emoji-1f9d4-1f3fe { background-position: -3234px -66px; }
.emoji-1f9d4-1f3ff { background-position: -3234px -132px; }
.emoji-1f9d5-1f3fb { background-position: -3234px -264px; }
.emoji-1f9d5-1f3fc { background-position: -3234px -330px; }
.emoji-1f9d5-1f3fd { background-position: -3234px -396px; }
.emoji-1f9d5-1f3fe { background-position: -3234px -462px; }
.emoji-1f9d5-1f3ff { background-position: -3234px -528px; }
.emoji-1f9d6-1f3fb-200d-2640-fe0f { background-position: -3234px -660px; }
.emoji-1f9d6-1f3fc-200d-2640-fe0f { background-position: -3234px -726px; }
.emoji-1f9d6-1f3fd-200d-2640-fe0f { background-position: -3234px -792px; }
.emoji-1f9d6-1f3fe-200d-2640-fe0f { background-position: -3234px -858px; }
.emoji-1f9d6-1f3ff-200d-2640-fe0f { background-position: -3234px -924px; }
.emoji-1f9d6-1f3fb-200d-2642-fe0f { background-position: -3234px -1056px; }
.emoji-1f9d6-1f3fc-200d-2642-fe0f { background-position: -3234px -1122px; }
.emoji-1f9d6-1f3fd-200d-2642-fe0f { background-position: -3234px -1188px; }
.emoji-1f9d6-1f3fe-200d-2642-fe0f { background-position: -3234px -1254px; }
.emoji-1f9d6-1f3ff-200d-2642-fe0f { background-position: -3234px -1320px; }
.emoji-1f9d6-1f3fb { background-position: -3234px -1452px; }
.emoji-1f9d6-1f3fc { background-position: -3234px -1518px; }
.emoji-1f9d6-1f3fd { background-position: -3234px -1584px; }
.emoji-1f9d6-1f3fe { background-position: -3234px -1650px; }
.emoji-1f9d6-1f3ff { background-position: -3234px -1716px; }
.emoji-1f9d7-1f3fb-200d-2640-fe0f { background-position: -3234px -1848px; }
.emoji-1f9d7-1f3fc-200d-2640-fe0f { background-position: -3234px -1914px; }
.emoji-1f9d7-1f3fd-200d-2640-fe0f { background-position: -3234px -1980px; }
.emoji-1f9d7-1f3fe-200d-2640-fe0f { background-position: -3234px -2046px; }
.emoji-1f9d7-1f3ff-200d-2640-fe0f { background-position: -3234px -2112px; }
.emoji-1f9d7-1f3fb-200d-2642-fe0f { background-position: -3234px -2244px; }
.emoji-1f9d7-1f3fc-200d-2642-fe0f { background-position: -3234px -2310px; }
.emoji-1f9d7-1f3fd-200d-2642-fe0f { background-position: -3234px -2376px; }
.emoji-1f9d7-1f3fe-200d-2642-fe0f { background-position: -3234px -2442px; }
.emoji-1f9d7-1f3ff-200d-2642-fe0f { background-position: -3234px -2508px; }
.emoji-1f9d7-1f3fb { background-position: -3234px -2640px; }
.emoji-1f9d7-1f3fc { background-position: -3234px -2706px; }
.emoji-1f9d7-1f3fd { background-position: -3234px -2772px; }
.emoji-1f9d7-1f3fe { background-position: -3234px -2838px; }
.emoji-1f9d7-1f3ff { background-position: -3234px -2904px; }
.emoji-1f9d8-1f3fb-200d-2640-fe0f { background-position: -3234px -3036px; }
.emoji-1f9d8-1f3fc-200d-2640-fe0f { background-position: -3234px -3102px; }
.emoji-1f9d8-1f3fd-200d-2640-fe0f { background-position: -3234px -3168px; }
.emoji-1f9d8-1f3fe-200d-2640-fe0f { background-position: -3234px -3234px; }
.emoji-1f9d8-1f3ff-200d-2640-fe0f { background-position: -3234px -3300px; }
.emoji-1f9d8-1f3fb-200d-2642-fe0f { background-position: -3234px -3432px; }
.emoji-1f9d8-1f3fc-200d-2642-fe0f { background-position: -3234px -3498px; }
.emoji-1f9d8-1f3fd-200d-2642-fe0f { background-position: -3234px -3564px; }
.emoji-1f9d8-1f3fe-200d-2642-fe0f { background-position: -3234px -3630px; }
.emoji-1f9d8-1f3ff-200d-2642-fe0f { background-position: -3234px -3696px; }
.emoji-1f9d8-1f3fb { background-position: -3300px -0; }
.emoji-1f9d8-1f3fc { background-position: -3300px -66px; }
.emoji-1f9d8-1f3fd { background-position: -3300px -132px; }
.emoji-1f9d8-1f3fe { background-position: -3300px -198px; }
.emoji-1f9d8-1f3ff { background-position: -3300px -264px; }
.emoji-1f9d9-1f3fb-200d-2640-fe0f { background-position: -3300px -396px; }
.emoji-1f9d9-1f3fc-200d-2640-fe0f { background-position: -3300px -462px; }
.emoji-1f9d9-1f3fd-200d-2640-fe0f { background-position: -3300px -528px; }
.emoji-1f9d9-1f3fe-200d-2640-fe0f { background-position: -3300px -594px; }
.emoji-1f9d9-1f3ff-200d-2640-fe0f { background-position: -3300px -660px; }
.emoji-1f9d9-1f3fb-200d-2642-fe0f { background-position: -3300px -792px; }
.emoji-1f9d9-1f3fc-200d-2642-fe0f { background-position: -3300px -858px; }
.emoji-1f9d9-1f3fd-200d-2642-fe0f { background-position: -3300px -924px; }
.emoji-1f9d9-1f3fe-200d-2642-fe0f { background-position: -3300px -990px; }
.emoji-1f9d9-1f3ff-200d-2642-fe0f { background-position: -3300px -1056px; }
.emoji-1f9d9-1f3fb { background-position: -3300px -1188px; }
.emoji-1f9d9-1f3fc { background-position: -3300px -1254px; }
.emoji-1f9d9-1f3fd { background-position: -3300px -1320px; }
.emoji-1f9d9-1f3fe { background-position: -3300px -1386px; }
.emoji-1f9d9-1f3ff { background-position: -3300px -1452px; }
.emoji-1f9da-1f3fb-200d-2640-fe0f { background-position: -3300px -1584px; }
.emoji-1f9da-1f3fc-200d-2640-fe0f { background-position: -3300px -1650px; }
.emoji-1f9da-1f3fd-200d-2640-fe0f { background-position: -3300px -1716px; }
.emoji-1f9da-1f3fe-200d-2640-fe0f { background-position: -3300px -1782px; }
.emoji-1f9da-1f3ff-200d-2640-fe0f { background-position: -3300px -1848px; }
.emoji-1f9da-1f3fb-200d-2642-fe0f { background-position: -3300px -1980px; }
.emoji-1f9da-1f3fc-200d-2642-fe0f { background-position: -3300px -2046px; }
.emoji-1f9da-1f3fd-200d-2642-fe0f { background-position: -3300px -2112px; }
.emoji-1f9da-1f3fe-200d-2642-fe0f { background-position: -3300px -2178px; }
.emoji-1f9da-1f3ff-200d-2642-fe0f { background-position: -3300px -2244px; }
.emoji-1f9da-1f3fb { background-position: -3300px -2376px; }
.emoji-1f9da-1f3fc { background-position: -3300px -2442px; }
.emoji-1f9da-1f3fd { background-position: -3300px -2508px; }
.emoji-1f9da-1f3fe { background-position: -3300px -2574px; }
.emoji-1f9da-1f3ff { background-position: -3300px -2640px; }
.emoji-1f9db-1f3fb-200d-2640-fe0f { background-position: -3300px -2772px; }
.emoji-1f9db-1f3fc-200d-2640-fe0f { background-position: -3300px -2838px; }
.emoji-1f9db-1f3fd-200d-2640-fe0f { background-position: -3300px -2904px; }
.emoji-1f9db-1f3fe-200d-2640-fe0f { background-position: -3300px -2970px; }
.emoji-1f9db-1f3ff-200d-2640-fe0f { background-position: -3300px -3036px; }
.emoji-1f9db-1f3fb-200d-2642-fe0f { background-position: -3300px -3168px; }
.emoji-1f9db-1f3fc-200d-2642-fe0f { background-position: -3300px -3234px; }
.emoji-1f9db-1f3fd-200d-2642-fe0f { background-position: -3300px -3300px; }
.emoji-1f9db-1f3fe-200d-2642-fe0f { background-position: -3300px -3366px; }
.emoji-1f9db-1f3ff-200d-2642-fe0f { background-position: -3300px -3432px; }
.emoji-1f9db-1f3fb { background-position: -3300px -3564px; }
.emoji-1f9db-1f3fc { background-position: -3300px -3630px; }
.emoji-1f9db-1f3fd { background-position: -3300px -3696px; }
.emoji-1f9db-1f3fe { background-position: -3300px -3762px; }
.emoji-1f9db-1f3ff { background-position: -3366px -0; }
.emoji-1f9dc-1f3fb-200d-2640-fe0f { background-position: -3366px -132px; }
.emoji-1f9dc-1f3fc-200d-2640-fe0f { background-position: -3366px -198px; }
.emoji-1f9dc-1f3fd-200d-2640-fe0f { background-position: -3366px -264px; }
.emoji-1f9dc-1f3fe-200d-2640-fe0f { background-position: -3366px -330px; }
.emoji-1f9dc-1f3ff-200d-2640-fe0f { background-position: -3366px -396px; }
.emoji-1f9dc-1f3fb-200d-2642-fe0f { background-position: -3366px -528px; }
.emoji-1f9dc-1f3fc-200d-2642-fe0f { background-position: -3366px -594px; }
.emoji-1f9dc-1f3fd-200d-2642-fe0f { background-position: -3366px -660px; }
.emoji-1f9dc-1f3fe-200d-2642-fe0f { background-position: -3366px -726px; }
.emoji-1f9dc-1f3ff-200d-2642-fe0f { background-position: -3366px -792px; }
.emoji-1f9dc-1f3fb { background-position: -3366px -924px; }
.emoji-1f9dc-1f3fc { background-position: -3366px -990px; }
.emoji-1f9dc-1f3fd { background-position: -3366px -1056px; }
.emoji-1f9dc-1f3fe { background-position: -3366px -1122px; }
.emoji-1f9dc-1f3ff { background-position: -3366px -1188px; }
.emoji-1f9dd-1f3fb-200d-2640-fe0f { background-position: -3366px -1320px; }
.emoji-1f9dd-1f3fc-200d-2640-fe0f { background-position: -3366px -1386px; }
.emoji-1f9dd-1f3fd-200d-2640-fe0f { background-position: -3366px -1452px; }
.emoji-1f9dd-1f3fe-200d-2640-fe0f { background-position: -3366px -1518px; }
.emoji-1f9dd-1f3ff-200d-2640-fe0f { background-position: -3366px -1584px; }
.emoji-1f9dd-1f3fb-200d-2642-fe0f { background-position: -3366px -1716px; }
.emoji-1f9dd-1f3fc-200d-2642-fe0f { background-position: -3366px -1782px; }
.emoji-1f9dd-1f3fd-200d-2642-fe0f { background-position: -3366px -1848px; }
.emoji-1f9dd-1f3fe-200d-2642-fe0f { background-position: -3366px -1914px; }
.emoji-1f9dd-1f3ff-200d-2642-fe0f { background-position: -3366px -1980px; }
.emoji-1f9dd-1f3fb { background-position: -3366px -2112px; }
.emoji-1f9dd-1f3fc { background-position: -3366px -2178px; }
.emoji-1f9dd-1f3fd { background-position: -3366px -2244px; }
.emoji-1f9dd-1f3fe { background-position: -3366px -2310px; }
.emoji-1f9dd-1f3ff { background-position: -3366px -2376px; }
.emoji-261d-1f3fb { background-position: -3564px -528px; }
.emoji-261d-1f3fc { background-position: -3564px -594px; }
.emoji-261d-1f3fd { background-position: -3564px -660px; }
.emoji-261d-1f3fe { background-position: -3564px -726px; }
.emoji-261d-1f3ff { background-position: -3564px -792px; }
.emoji-26f9-1f3fb-200d-2640-fe0f { background-position: -3630px -1650px; }
.emoji-26f9-1f3fc-200d-2640-fe0f { background-position: -3630px -1716px; }
.emoji-26f9-1f3fd-200d-2640-fe0f { background-position: -3630px -1782px; }
.emoji-26f9-1f3fe-200d-2640-fe0f { background-position: -3630px -1848px; }
.emoji-26f9-1f3ff-200d-2640-fe0f { background-position: -3630px -1914px; }
.emoji-26f9-1f3fb-200d-2642-fe0f { background-position: -3630px -2046px; }
.emoji-26f9-1f3fc-200d-2642-fe0f { background-position: -3630px -2112px; }
.emoji-26f9-1f3fd-200d-2642-fe0f { background-position: -3630px -2178px; }
.emoji-26f9-1f3fe-200d-2642-fe0f { background-position: -3630px -2244px; }
.emoji-26f9-1f3ff-200d-2642-fe0f { background-position: -3630px -2310px; }
.emoji-26f9-1f3fb { background-position: -3630px -2442px; }
.emoji-26f9-1f3fc { background-position: -3630px -2508px; }
.emoji-26f9-1f3fd { background-position: -3630px -2574px; }
.emoji-26f9-1f3fe { background-position: -3630px -2640px; }
.emoji-26f9-1f3ff { background-position: -3630px -2706px; }
.emoji-270a-1f3fb { background-position: -3630px -3234px; }
.emoji-270a-1f3fc { background-position: -3630px -3300px; }
.emoji-270a-1f3fd { background-position: -3630px -3366px; }
.emoji-270a-1f3fe { background-position: -3630px -3432px; }
.emoji-270a-1f3ff { background-position: -3630px -3498px; }
.emoji-270b-1f3fb { background-position: -3630px -3630px; }
.emoji-270b-1f3fc { background-position: -3630px -3696px; }
.emoji-270b-1f3fd { background-position: -3630px -3762px; }
.emoji-270b-1f3fe { background-position: -3696px -0; }
.emoji-270b-1f3ff { background-position: -3696px -66px; }
.emoji-270c-1f3fb { background-position: -3696px -198px; }
.emoji-270c-1f3fc { background-position: -3696px -264px; }
.emoji-270c-1f3fd { background-position: -3696px -330px; }
.emoji-270c-1f3fe { background-position: -3696px -396px; }
.emoji-270c-1f3ff { background-position: -3696px -462px; }
.emoji-270d-1f3fb { background-position: -3696px -594px; }
.emoji-270d-1f3fc { background-position: -3696px -660px; }
.emoji-270d-1f3fd { background-position: -3696px -726px; }
.emoji-270d-1f3fe { background-position: -3696px -792px; }
.emoji-270d-1f3ff { background-position: -3696px -858px; }
