.edit-post-time-limit-modal {
    label {
        margin-right: 7px;
        margin-left: 7px;
        font-weight: normal;
    }

    .edit-post-time-limit-modal__error {
        display: inline-block;
        width: 65%;
        color: red;
        text-align: left;
    }
}
