.team-btn {
    @include border-radius(8px);

    position: relative;
    font-weight: bold;
    letter-spacing: -0.5px;
    text-align: center;
    text-transform: capitalize;
    vertical-align: top;

    &.team-btn__add {
        font-size: 25px;
        line-height: 41px;
        vertical-align: middle;

        .TeamIcon {
            border-color: transparent;
            background-color: transparent;
            transform-origin: center bottom;
            transition: transform 0.1s, background-color 0.2s;

            .TeamIcon__initials {
                transition: transform 0.2s;
            }

            &:hover {
                border-color: transparent;
                background-color: rgba(var(--sidebar-text-rgb), 0.08);
                box-shadow: none;
            }

            &:active {
                background-color: rgba(var(--sidebar-text-rgb), 0.16);
                transform: scale(0.9);

                .TeamIcon__initials {
                    transform: scale(1.1);
                }
            }

            &::before {
                display: none;
            }

            .icon-plus {
                font-size: 22px;
            }
        }
    }
}
