@charset 'UTF-8';

body {
    &.error {
        .container-fluid {
            display: table;
            height: 90%;
        }

        .error__container {
            display: table-cell;
            max-width: 800px;
            padding: 5em 0;
            margin: 0 auto;
            color: #555;
            text-align: left;
            vertical-align: top;
        }

        .error__icon {
            color: #ccc;
            font-size: 4em;
        }

        h2 {
            margin: 0.8em 0 0.5em;
            font-size: 1.5em;
            font-weight: 600;
        }

        p {
            font-size: 1.2em;
        }

        .ErrorPage__container_max_free_users {
            display: flex;
            flex-direction: column;
            text-align: center;

            .ErrorPage__mattermostCloudImg {
                height: 100px;
                align-self: flex-start;
                margin: auto;

                img {
                    margin: 32px 40px;
                }
            }

            .ErrorPage__mainContentContainer {
                display: flex;
                height: calc(100vh - 100px);
                flex-direction: column;
                justify-content: center;

                .ErrorPage__mainContentItem {
                    align-self: stretch;
                    padding: 16px;

                    .ErrorPage__computerWithAlertImg {
                        width: 50vw;
                        min-width: 320px;
                        max-width: 520px;
                        min-height: 270px;
                        max-height: calc(100vh - 320px);
                        margin: 0 auto 32px auto;
                    }

                    article {
                        display: block;
                        width: 100%;
                        max-width: 500px;
                        margin: 0 auto;
                        text-align: center;

                        .ErrorPage__maxFreeUsersReachedTitle {
                            margin: auto;
                            color: #3d3c40;
                            font-size: 24px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 32px;
                        }

                        .ErrorPage__maxFreeUsersReachedDescription {
                            margin: 1% auto;
                            font-family: Open Sans;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: normal;
                            line-height: 20px;
                        }
                    }
                }
            }

            .background-svg {
                position: absolute;
                z-index: -1;
                top: 0;

                >div {
                    position: absolute;
                    top: 0;
                    left: 0;
                }
            }
        }
    }
}
