@charset 'UTF-8';

.icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
}

.icon--no-spacing::before {
    margin: 0;
}

.icon--small {
    width: 16px;
    height: 16px;
}

.icon--small-filled {
    width: 14px;
    height: 14px;
}

.icon--standard {
    width: 20px;
    height: 20px;
    font-size: 1.6rem;
}

.icon-10 {
    font-size: 12px;
}

.icon-12 {
    font-size: 14.4px;
}

.icon-16 {
    font-size: 18px;
}

.icon-20 {
    font-size: 24px;
}

.icon-28 {
    font-size: 31.2px;
}

.icon-32 {
    font-size: 36px;
}

.icon-40 {
    font-size: 48px;
}

.icon-52 {
    font-size: 60px;
}

.icon-64 {
    font-size: 72px;
}
