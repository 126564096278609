.channel-view {
    .badge {
        @include border-radius(100px);

        position: relative;
        display: flex;
        min-width: 18px;
        height: 18px;
        align-items: center;
        justify-content: center;
        padding: 0 5px;
        font-size: 11px;
        line-height: 18px;
    }

    .team-sidebar,
    .sidebar--left,
    #SidebarContainer {
        .active .badge,
        .badge {
            background: var(--mention-bg);
            color: var(--mention-color);
        }
    }

    .team-sidebar,
    #SidebarContainer {
        .badge {
            min-width: 20px;
            height: auto;
            padding: 0 6px;
            border-radius: 8px;
            font-size: 11px;
            -webkit-font-smoothing: subpixel-antialiased;
            -moz-osx-font-smoothing: grayscale;
            font-weight: 700;
            letter-spacing: 0;
            line-height: 16px;
            text-align: center;

            &.badge-max-number {
                padding: 0 4px 0 5px;
            }
        }
    }

    .team-sidebar {
        .badge {
            position: absolute;
            z-index: 5;
            top: -3px;
            right: -3px;
            display: inline-block;
            flex-shrink: 0;
            box-shadow: 0 0 0 3px var(--sidebar-teambar-bg);
        }
    }

    #SidebarContainer {
        .badge {
            display: inline-block;
            flex-shrink: 0;
            margin: 0 4px;
        }
    }

    .navbar-toggle {
        .badge {
            position: absolute;
            top: 10px;
            left: 8px;
        }
    }
}
