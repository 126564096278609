.channel-icon {
    position: relative;
    display: inline-block;
    width: 15px;
    margin: 0 8px 0 0;
    text-align: left;
    vertical-align: top;
}

.channel-icon__lock,
.channel-icon__globe {
    top: 1px;
}

.channel-icon__archive,
.channel-icon__unarchive {
    top: 2px;
    left: -2px;
}
