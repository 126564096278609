@charset 'UTF-8';

.remove-image {
    position: relative;
    display: inline-block;
}

.remove-image__img {
    display: flex;
    overflow: hidden;
    width: 128px;
    height: 128px;
    align-items: center;
    justify-content: center;
    border: 1px solid alpha-color($black, 0.15);
    background: $white;
    border-radius: 4px;

    img {
        max-width: 100%;
        max-height: 100%;
    }
}

.remove-image__btn {
    position: absolute;
    top: -8px;
    right: -9px;
    display: flex;
    width: 24px;
    height: 24px;
    align-items: center;
    justify-content: center;
    border: none;
    background: alpha-color($black, 0.8);
    border-radius: 40px;
    color: white;
    font-size: 20px;

    &:hover {
        background: $black;
    }

    span {
        margin-left: 1px;
        line-height: 0;
    }
}
