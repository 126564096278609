@charset 'UTF-8';

.team_statistics {
    .total-count {
        @include border-radius(3px);

        width: 100%;
        border: 1px solid $light-gray;
        margin: 1em 0;
        background: $white;
        text-align: center;

        .title {
            padding: 7px 10px;
            border-bottom: 1px solid $light-gray;
            font-size: 13px;
            font-weight: 400;
            text-align: left;

            span {
                display: inline-block;
                overflow: hidden;
                width: calc(100% - 20px);
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            .fa {
                margin: 0;
                color: #555;
                float: right;
                font-size: 16px;
            }
        }

        .content {
            overflow: auto;
            padding: 0.3em 0 0.35em;
            color: #555;
            font-size: 30px;
            font-weight: 600;
        }
    }

    .total-count--day {
        width: 760px;
        height: 275px;
        padding: 5px 10px 10px;
        border: 1px solid $light-gray;
        margin: 10px;
        background: $white;
        clear: both;

        > div {
            font-size: 18px;
            font-weight: 300;
        }
    }

    .recent-active-users {
        table {
            width: 100%;
            table-layout: fixed;
        }

        .content {
            overflow: auto;
            max-height: 400px;
            padding: 0;
        }

        tr {
            &:first-child {
                td {
                    border-top: none;
                }
            }

            td {
                @include clearfix;

                padding: 5px 5px 6px;
                border-top: 1px solid $light-gray;
                border-left: 1px solid $light-gray;
                font-size: 13px;
                font-weight: 400;
                text-overflow: ellipsis;
                white-space: nowrap;

                &:first-child {
                    border-left: none;
                }
            }
        }
    }

    .team-statistics__header-row {
        align-items: center;
        justify-content: space-between;
    }

    .team-statistics__team-filter {
        display: inline-block;
        width: 200px;
    }
}
